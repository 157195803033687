import React, { useState, createContext } from 'react'

export const AuthContext = createContext({
	auth:null,
	login: () => {},
})

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null)

	const login = (profile)=>{
		setAuth(profile)
	}

  return (
    <AuthContext.Provider value={{ auth,login  }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
