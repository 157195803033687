import React, { useEffect } from 'react';
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import CloseIcon from '@material-ui/icons/Close';
import Geocode from 'react-geocode'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Grid, Box, Typography, makeStyles, IconButton } from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  locationIcon: {
    position: 'absolute',
    right: 0,
    top: 20
  },
  mapHeader: {
    float: 'right',
    top: 0,
    position: 'absolute',
    width: '100%',
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px'
  },
  mapContainer: {
    height: '100%',
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 999,
    top: 0,
    border: '1px solid',
    boxShadow: '0px 0px 4px 0px',
    borderRadius: 4
  }
}));

const LocationSelector = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.defaultValue || null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [latLngObject, setLatLngObject] = React.useState({ lat: 28.643159, lng: 77.297028 })
  const [showMap, setShowMap] = React.useState(false)

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    const success = (pos) => {
       var crd = pos.coords;
      setLatLngObject({
        lat: crd.latitude,
        lng: crd.longitude
      });
      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
    }
    navigator.geolocation.getCurrentPosition(success, () => {
      setLatLngObject({ lat: 28.643159, lng: 77.297028 });
    }, {});
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
		const newAddress = {
			description: props.value
		}
		setValue(newAddress)
	}, [props.value])

  const handleChange = (newValue) => {
		const geocoder = new window.google.maps.Geocoder()
		setValue(newValue)
		geocoder.geocode({ placeId: newValue?.place_id }, (results, status) => {
			if (status === 'OK') {
				const latitude = results[0].geometry.location.lat()
				const longitude = results[0].geometry.location.lng()
				const locationCordinate = { latitude, longitude }
        setLatLngObject({
          lat: locationCordinate.latitude,
          lng: locationCordinate.longitude
        })
      }
		})
	}

  const onMarkerDrag = (event) => {
    const latLngObject = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    Geocode.fromLatLng(latLngObject.lat, latLngObject.lng).then(
			(response) => {
				const locationAddress = response.results[0].formatted_address
        setLatLngObject({
          ...latLngObject
        })
        if (props.handleSelect && locationAddress) {
          props.handleSelect(locationAddress)
        }
				setValue(locationAddress)
        setShowMap(false)
    })
  }

  React.useEffect(() => {
    Geocode.setApiKey('AIzaSyBKKNjRVZ6-dpLXsKp4ZEwXfw_E6_sPdCQ');
		Geocode.enableDebug()
  }, [])

  const MapComponent = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBKKNjRVZ6-dpLXsKp4ZEwXfw_E6_sPdCQ&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{display: (showMap ? 'block' : 'none')}} className={classes.mapContainer} />,
      mapElement: <div style={{ height: `100%`, top: '30px' }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <>
      <Box className={classes.mapHeader}>
        <Typography variant="caption">{typeof value === 'string' ? value : (value && value.description)}</Typography>
        <IconButton 
          onClick={() => setShowMap(false)}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <GoogleMap 
		defaultZoom={17}
        onClick={onMapClick}
        defaultCenter={{ ...latLngObject }}
      >
        <Marker 
          draggable={true}
          position={{ ...latLngObject }} 
          onDragEnd={onMarkerDrag}
        />
      </GoogleMap>
    </>
  ))
  const onMapClick = (event) => {
    const latLngObject = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    }
    Geocode.fromLatLng(latLngObject.lat, latLngObject.lng).then(
			(response) => {
				const locationAddress = response.results[0].formatted_address
        setLatLngObject({
          ...latLngObject
        })
        if (props.handleSelect && locationAddress) {
          props.handleSelect(locationAddress)
        }
				setValue(locationAddress)
        setShowMap(false)
    })
  }
  return (
    <>
      <MapComponent />
      <Autocomplete
        id="google-map-demo"
        getOptionLabel={(option) => (typeof option === 'string' ? option : (option.description || ''))}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          newValue && handleChange(newValue)
          if (props.handleSelect && newValue) {
            props.handleSelect(newValue.description)
          }
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.length > 2) {
            setInputValue(newInputValue);
          }
        }}
        renderInput={(params) => (
          <Box display="flex" ml={1} width="100%" position="relative">
            <TextField 
              {...params} 
              label="Map Address" 
              variant="standard" 
              fullWidth
			  InputLabelProps={{ style: { fontSize: 12, color: '#1B154E ', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
            />
            <Typography variant="caption" onClick={() => setShowMap(true)} className={classes.locationIcon}>
              <LocationOnIcon style={{cursor: 'pointer'}}/>
            </Typography>
          </Box>
        )}
        renderOption={(option) => {
          const matches = option && option.structured_formatting ? option.structured_formatting.main_text_matched_substrings : [];
          const parts = parse(
            option && option.structured_formatting ? option.structured_formatting.main_text : '',
            matches.map((match) => [match.offset, match.offset + match.length]),
          ) || [];

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option && option.structured_formatting && option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </>
  );
}

export default LocationSelector
