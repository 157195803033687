import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

const useStyles = makeStyles((theme) => ({
  exportResult: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    color: colors.primary,
    fontFamily: "Montserrat, SemiBold",
    textDecoration: "double",
  },
  exportButton: {
    textTransform: "none",
    fontWeight: "600",
    borderRadius: 4,
    background: "#FFD13B !important",
    marginTop: "10px",
  },
}));

export default function Excel({ csvData }) {
  // console.log("csvData :", csvData[0].comment);
  function getSheetData(csvData, header) {
    var fields = Object.keys(csvData[0]);
    console.log("fields :", fields);

    // const message = fields.

    var sheetData = csvData.map(function (row) {
      return [
        "status",
        "leadID",
        "leadDate",
        "leadSource",
        "appointmentDate",
        "appointmentTime",
        "year",
        "make",
        "model",
        "oilVariant",
        "totalPurchasePrice",
        "soldPrice",
        "comment",
        "address",
        "appointmentId",
        "assignedTo",
        "carRegistrationNO",
        "followUpDate",
        "userId",
        "mobile",
        "variant",
        "customAddress",
        "createdAt",
        "mobile",
        "email",
        "firstName",
      ].map((fieldName) => {
        return row[fieldName] ? row[fieldName] : "";
      });
    });

    sheetData.unshift(header);
    return sheetData;
  }

  async function saveAsExcel() {
    const header = [
      "Status",
      "Lead ID",
      "LeadDate",
      "Lead Source",
      "Appointment Date",
      "Appointment Time",
      "Year",
      "Make",
      "Model",
      "Oil Variant",
      "Purchase Price",
      "Sold Price",
      "Comment",
      "Address",
      "Appointment Id",
      "Assigned To",
      "CarRegistration NO",
      "FollowUp Date",
      "User Id",
      "Mobile",
      "Variant",
      "Custom Address",
      "Created At",
      "Mobile",
      "Email",
      "First Name",
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(csvData, header);
      const totalColumns = sheetData[0].length;
      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      // sheet1.row(1).style("fontsize", 10);
      // sheet1.row(1).style({ bold:true, width: 100})

      const maxStringLength = sheet1.range("A1:A20").reduce((max, cell) => {
        const value = cell.value();
        if (value === undefined) return max;
        return Math.max(max, value.toString().length);
      }, 0);
      sheet1.column("A").width(maxStringLength);

      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, "Appointment.xlsx");
      });
    });
  }

  const classes = useStyles();
  return (
    <Button className={classes.exportButton} onClick={saveAsExcel}>
      Export
    </Button>
  );
}
