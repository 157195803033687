import React, { useState, useContext, useEffect } from "react";
import Loader from "../components/atoms/Loader";
import {
	CssBaseline,
	Grid,
	makeStyles,
} from "@material-ui/core";
import { AuthContext } from "../contexts/AuthProvider";
import LoginHooks from "../hooks/LoginHooks";
import { useHistory } from "react-router-dom";
import firebase from "../config/Firebase";
import BackgroundImg from "../assets/images/white-floor-car-parking.png";
import DustonIcon from "../assets/images/GroupLogo.png";
import { colors } from "../theme";

const useStyles = makeStyles((theme) => ({
	root: {
	  height: "100%",
	},
	paper: {
	  // margin: theme.spacing(8, 4),
	  display: "flex",
	  flexDirection: "column",
	  //alignItems: "center",
	},
	form: {
	  width: "100%", // Fix IE 11 issue.
	  marginTop: theme.spacing(1),
	},
	submit: {
	  margin: theme.spacing(3, 0, 2),
	},
	BackgroundImage: {
	  backgroundImage: `url(${BackgroundImg})`,
	  backgroundPosition: "center",
	  backgroundSize: "cover",
	  backgroundRepeat: "no-repeat",
	  height: "100vh",
	  width: "100%",
	  display: "flex",
	},
	dustonIcon: {
	  marginRight: 10,
	  zIndex: 9999,
	  alignItems: "center",
	  "@media(max-width: 768px)": {
		display: "flex",
		alignItems: "center",
		marginTop: 20,
		marginBottom: 20,
	  },
	  "@media(min-width: 1920px)": {
		marginRight: 80,
	  },
	  "@media(min-width: 2500px)": {
		marginRight: 100,
	  },
	},
	imgSize: {
	  width: "100%",
	  height: "auto",
	},
	paperTitle: {
	  color: colors.white,
	  fontSize: 30,
	  fontFamily: "Montserrat,Medium",
	},
	textArea: {
	  marginTop: -580,
	  marginLeft: -500,
	  zIndex: 999,
	},
	imgOpacity: {
	  backgroundColor: "#1B154E",
	  position: "absolute",
	  top: 0,
	  left: 0,
	  width: "100%",
	  height: "100%",
	  zIndex: 999,
	  opacity: 0.8,
	},
	loginSection: {
	  position: "absolute",
	  zIndex: "999",
	  top: "0",
	  bottom: "0",
	  display: "flex",
	  justifyContent: "center",
	  alignItems: "center",
	  "@media(max-width: 768px)": {
		flexDirection: "column",
		top: "11px",
	  },
	  "@media(max-width: 1920px)": {
		width: "100%",
	  },
	  "@media(max-width: 2500px)": {
		width: "100%",
	  },
	  "@media(max-width: 3000px)": {
		width: "100%",
	  },
	},
  }));

export default function SignInSide() {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const { login } = useContext(AuthContext);
	const history = useHistory();

	useEffect(() => {
		const _email = localStorage.getItem("email");
		const _uid = localStorage.getItem("uid");
		if (_email && _uid) {
			firebase
				.database()
				.ref(`/users/${_uid}`)
				.once("value")
				.then(function (data) {
					let _data = data.val();
					//set authcontext
					login({
						email: _data.email,
						role: _data.role,
						name: _data.name,
						uid: _uid,
					});
					setLoading(false);
					history.push("/dashboard");
				});
		} else {
			setLoading(false);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={12} sm={12} md={12}>
				{loading ? (
					<div className={classes.paper} style={{
						padding: '22% 50%',
						height: '100vh'
					}}>
						<Loader />
					</div>
				) : (
						<div className={classes.paper}>
							<div className={classes.imgOpacity}></div>
							<img
								height="100%"
								width="100%"
								className={classes.BackgroundImage}
								alt=""
							/>

							<div className={classes.loginSection}>
								<Grid item xs={6} sm={6} md={6} className={classes.dustonIcon}>
									<img className={classes.imgSize} src={DustonIcon} alt="" />
								</Grid>
								<LoginHooks />
							</div>
							{/* <Grid item xs={6} sm={6} md={6} className={classes.textArea}>
          <Typography className={classes.paperTitle}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </Typography>
        </Grid> */}
						</div>
					)}
			</Grid>
		</Grid>
	);
}
