import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: "wrap"
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		marginTop: 0,
		'& .MuiInput-underline.Mui-disabled:before':{
			borderBottomStyle: 'solid',
		}
	},
	resize: {
		fontSize: 12,
	},
}));

const CustomInput = (props) => {
	const classes = useStyles();
	const { data, defaultValue = '', onKeyDown } = props;
	const { type, label, field, disabled } = data;
	const [value, setValue] = React.useState(defaultValue || "");

	const handleChange = (value) => {
		const v = value.toLowerCase().split(' ').map(function (word) {
			return (word.toUpperCase());
		}).join(' ');
		setValue(v);
		props.onChange(field, v);
	};
	if (field !== "leadID")
		return (
			<div className={classes.root}>
				<div>
					<TextField
						display="flex"
						flexdirection="row"
						id="standard-full-width"
						label={label}
						fullWidth
						className={classes.textField}
						type={type}
						disabled={props.disabled || disabled}
						value={value}
						onChange={(e) => handleChange(e.target.value)}
						margin="normal"
						inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
						InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
						endAdornment={<InputAdornment position="end"></InputAdornment>}
						onKeyDown={onKeyDown}	
					/>
				</div>
			</div>
		);
	else return <></>;
};
export default CustomInput;
