import Login from './views/Login'
import Home from './views/Home';
import Appointment from './views/Appointment'
import CreateUser from './views/CreateUser'
import ManageAppointment from './components/molecules/ManageAppointment'
import Dashboard from './layout/Dashboard'
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import AuthProvider from './contexts/AuthProvider'
import themes from './themes/themeProvider'
import ThemeProviderCustom from './themes'
import './App.css';

function App() {
	return (
		<AuthProvider>
			<ThemeProviderCustom theme={themes}>
				<Router>
					<div>
						<Switch>
							<Route path="/dashboard">
								<Dashboard>
									<Home />
								</Dashboard>
							</Route>
							<Route path="/appointment">
								<Dashboard>
									<Appointment />
								</Dashboard>
							</Route>
							<Route path="/create">
								<Dashboard>
									<CreateUser />
								</Dashboard>
							</Route>
							<Route path="/manage-appointment">
								<Dashboard>
									<ManageAppointment />
								</Dashboard>
							</Route>
							<Route path="/">
								<Login />
							</Route>
						</Switch>
					</div>
				</Router>
			</ThemeProviderCustom>
		</AuthProvider>
	);
}

export default App;
