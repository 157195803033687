import { withStyles, Dialog } from "@material-ui/core";

const CDialog = withStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      borderRadius: 1,
      border: "1px solid #1B154E",
      margin: 15,
	  top:-50,
      //marginTop: -70,
      width: 268,
      height: 400,
    },
    "& .MuiDialogActions-root": {
      padding: 20,
      float: "left",
    },
    "& .MuiDialogTitle-root": {
      padding: "10px 20px",
      float: "left",
    },
    "& .MuiDialog-container": {
      float: "right",
    },
  },
}))(Dialog);

export default CDialog;
