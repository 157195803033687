import React from 'react'
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import Event from "@material-ui/icons/Event";

const useStyles = makeStyles((theme) => ({
	root: {
		'& MuiSvgIcon-root': {
			height: 200,
		},
		'& MuiInputBase-root': {
			fontSize: 14,
		},
		'& .MuiInputLabel-animated': {
			fontSize: 12,
			fontFamily: 'Montserrat, SemiBold',
			color: '#1B154E',
		},
		'& label + .MuiInput-formControl': {
			fontSize: 14,
			fontFamily: 'Montserrat, SemiBold',
			color: '#000000',
		},
		'& MuiInputAdornment-root .MuiInputAdornment-positionEnd': {
			marginRight: -13
		}
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		top: -14,
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'solid',
		},
	},
}));

const AppointmentDate = (props) => {
	const classes = useStyles();
	const { data, defaultValue, disabled } = props;
	const { label, field } = data;
	const [value, setValue] = React.useState(defaultValue || null);

	const handleChange = (value) => {
		setValue(value);
		let appDate = moment(value).format("YYYY-MM-DD")
		props.onChange(field, appDate)
	};

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
					format="dd/MM/yyyy"
					margin="normal"
					disablePast
					autoOk
					disabled={disabled}
					fullWidth
					id="date-picker-dialog"
					label={label}
					value={value}
					onChange={date => handleChange(date)}
					minDateMessage={false}
					className={classes.textField}
					maxDate={moment().add(29, "days")}
					InputProps={{
						endAdornment: (
							<InputAdornment>
								<Event />
							</InputAdornment>
						)
					}}
					inputProps={{ style: { fontSize: 14, color: '#000000', marginRight: 10, cursor: "pointer" } }} // font size of input text
					InputLabelProps={{
						style: {
							fontSize: 14,
							color: "rgb(28, 20, 81)",
							fontFamily: "Montserrat, SemiBold",
							cursor: "pointer",
						}
					}} // font size of input label
					endAdornment={{ style: { marginRight: -13 } }}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
}
export default AppointmentDate;
