const colors = {
    primary: '#1B154E',
    white: 'rgb(255, 255, 255)',
    black: '#000000',
    gray: '#F9F9F9',
    appSecondary: '#FFCF00',
    appTertiary: '#E4E4E4',
    textPrimary: '#030303',
    textTertiary: '#7B7B7B',
    borderColor: '#D4D4D4',
    aliceBlue: '#F6F7F9',
    sunGlow: '#FFD13B',
    midnightBlue: '#1B154E',
    // extra colors
    appPrimary: 'rgba(28, 20, 81, 1)',
    appPrimaryLite: 'rgba(28, 20, 81, 0.7)',
    appSecondaryLite: 'rgba(255, 207, 0, 0.7)',
    greenCompleted: '#00C853',
    rescheduled: '#F9A825',
    redCancled: '#FF1744',
    offWhite: 'rgba(255, 255, 255, 0.1)',
    disabledBlack: 'rgba(0, 0, 0, 0.2)',
    baseColorOpaque: 'rgba(0, 0, 0, .8)',
  };
  
  export default colors;
  