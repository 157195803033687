import React from 'react'
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	DatePicker
} from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TodayIcon from '@material-ui/icons/Today';
import Event from "@material-ui/icons/Event";

const useStyles = makeStyles((theme) => ({
	root: {
		'& MuiInputBase-root': {
			fontSize: 14,
		},
		'& .MuiIconButton-root': {
			marginRight: -13,
		},
		'& .MuiInputLabel-animated': {
			fontSize: 12,
			fontFamily: 'Montserrat, SemiBold',
			color: '#1B154E',
		},
		'& label + .MuiInput-formControl': {
			fontSize: 14,
			fontFamily: 'Montserrat, SemiBold',
			color: '#000000',
		},
	},
	'& .MuiInputLabel-animated': {
		fontSize: 12,
		fontFamily: 'Montserrat, SemiBold',
		color: '#1B154E',
	},
	'& label + .MuiInput-formControl': {
		fontSize: 14,
		fontFamily: 'Montserrat, SemiBold',
		color: '#000000',
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		top: -14,
		'& .MuiInput-underline.Mui-disabled:before':{
			borderBottomStyle: 'solid',
		}
	},
}));

const DatePickers = (props) => {
	const classes = useStyles();
	const { data, defaultValue = '', disabled } = props;
	const { label, field } = data;
	const [value, setValue] = React.useState(defaultValue ? defaultValue === " " ? null : defaultValue : null);

	const handleChange = (value) => {
		setValue(value);
		let appDate = moment(value).format("YYYY-MM-DD")
		props.onChange(field, appDate)
	};

	return (
		<>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
					format="dd/MM/yyyy"
					autoOk	
					margin="normal"
					fullWidth
					disabled={disabled}
					id="date-picker-dialog"
					label={label}
					defaultValue={defaultValue}
					value={value}
					onChange={date => handleChange(date)}
					InputProps={{
						endAdornment: (
							<InputAdornment>
								<Event />
							</InputAdornment>
						)
					}}
					className={classes.textField}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
					inputProps={{ style: { fontSize: 14, color: '#000000' ,cursor:"pointer",} }} // font size of input text
					InputLabelProps={{
						style: {
							fontSize: 14,
							color: "rgb(28, 20, 81)",
							fontFamily: "Montserrat, SemiBold"
						}
					}} // font size of input label
					endAdornment={<InputAdornment position="end"></InputAdornment>}
					keyboardIcon={<TodayIcon style={{ color: 'black', marginRight: -12 }} />}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
}
export default DatePickers;

