import React, { useContext } from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Box
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";
import { AuthContext } from "../../contexts/AuthProvider";

const StyledTableCell = withStyles((theme) => ({
	head: {
		background: "#1B154E 0% 0% no-repeat padding-box",
		color: colors.white,
		fontSize: 12,
		whiteSpace: "normal",
		wordWrap: "break-word",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		padding: 7,
		fontFamily: "Montserrat, SemiBold",
		textAlign: "left",
		font: "normal normal 600 13px/17px Montserrat",
		letterSpacing: 0.26,
		opacity: 1,
	},
	body: {
		padding: "5px 7px",
		fontFamily: "Montserrat, Medium",
		textAlign: "left",
		font: "normal normal medium 12px/16px Montserrat",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		letterSpacing: "0.12px",
		color: "#000000",
		opacity: 1,
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
	},
	table: {
		minWidth: 700,
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.background.default,
		},
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	caption: {
		textAlign: "left !important",
		captionSide: "top !important",
		fontSize: "18px !important",
		fontFamily: "Montserrat, Semibold !important",
		color: "#1B154E !important",
	},
	expand: {
		background: "#FFD13B",
		padding: '6px 24px 6px 9px',
		float: ' right',
		borderRadius: 2,
		cursor: 'pointer',
	},
	input: {
		width: 130,
		height: 40,
	},
	searchIcon: {
		bottom: 1,
		color: "#1B154E",
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		cursor: 'pointer',
		marginLeft: 5,
	},
	editIcon: {
		bottom: 1,
		color: "#fff",
		background: '#1B154E',
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		padding: 3,
		cursor: 'pointer',
	},
	noResult: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 25,
		marginBottom: 25,
		fontSize: 16,
		//color: colors.primary,
		fontFamily: "Montserrat, Regular",
	},
	expandList: {
		fontSize: 12,
		color: "#1B154E",
		fontFamily: "Montserrat, Regular",
	},
	tableTitle: {
		display: 'flex',
		alignItems: ' center',
		justifyContent: ' space-between',
		marginBottom: 10,
		marginTop: 10,

	},
	linkCss: {
		cursor: 'pointer',
		color: 'blue',
	},
	tableContainer: {
	}
}));

const ChallanTabel = ({ challanRecords, handleChallanEdit, handleRemoveChallan, disable }) => {

	const classes = useStyles();
	const { auth } = useContext(AuthContext);

	const handleChallanDownload = (id) => {
		const selectitem = challanRecords.find(item => item.id === id);
		const a = document.createElement('a');
		let url = '';
		let fileName = '';
		url = selectitem.challanFile;
		fileName = selectitem.challanFileName;
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	return (
		<Table className={classes.table} aria-label="caption table">
			<TableHead>
				<TableRow>
					<StyledTableCell><strong>S.No</strong></StyledTableCell>
					<StyledTableCell><strong>Delhi Traffic Challan</strong></StyledTableCell>
					<StyledTableCell><strong>Vahan & Parivahan Challan</strong></StyledTableCell>
					<StyledTableCell><strong>Challan Hold/Deduction</strong></StyledTableCell>
					{auth?.role === 'lex' ? null : <StyledTableCell><strong>Challan Hold Release date</strong></StyledTableCell>}
					<StyledTableCell><strong>Hypothecation</strong></StyledTableCell>
					<StyledTableCell><strong>Bank Name</strong></StyledTableCell>
					<StyledTableCell><strong>Black Listed Details</strong></StyledTableCell>
					<StyledTableCell><strong>NOC Hold Amt</strong></StyledTableCell>
					{auth?.role === 'lex' ? null : <StyledTableCell><strong>NOC Hold Release date</strong></StyledTableCell>}
					<StyledTableCell><strong>Files</strong></StyledTableCell>
					{disable ? <StyledTableCell><strong>Action</strong></StyledTableCell> : null}
				</TableRow>
			</TableHead>
			<TableBody>
				{challanRecords.map((challanRecord, index) => {
					return (
						<TableRow key={challanRecord.id}>
							<StyledTableCell>{index + 1}</StyledTableCell>
							<StyledTableCell>{challanRecord.delhiTrafficChallan}</StyledTableCell>
							<StyledTableCell>{challanRecord.vahanParivahanChallan}</StyledTableCell>
							<StyledTableCell>{challanRecord.challanHoldDeduction}</StyledTableCell>
							{auth?.role === 'lex' ? null : <StyledTableCell>{challanRecord.challanHoldReleaseDate}</StyledTableCell>}
							<StyledTableCell>{challanRecord.hypothecation}</StyledTableCell>
							<StyledTableCell>{challanRecord.bankName}</StyledTableCell>
							<StyledTableCell>{challanRecord.blackListedDetails}</StyledTableCell>
							<StyledTableCell>{challanRecord.nocHoldAmt}</StyledTableCell>
							{auth?.role === 'lex' ? null : <StyledTableCell>{challanRecord.nocHoldReleaseDate}</StyledTableCell>}
							<StyledTableCell
								className={classes.linkCss}
								onClick={() => handleChallanDownload(challanRecord.id)}
							>{challanRecord.challanFileName}
							</StyledTableCell>
							{disable ?
								<StyledTableCell minwidth>
									<Box display="flex">
									<EditIcon
										onClick={() => handleChallanEdit(challanRecord.id)}
										className={classes.editIcon}
									/>
									<DeleteOutlineIcon
										onClick={() => handleRemoveChallan(challanRecord.id)}
										className={classes.searchIcon}
									/>
									</Box>
								</StyledTableCell> : null}
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	)
}

export default ChallanTabel
