import React, { useContext } from "react";
import {
  ListItemText,
  ListItemIcon,
  ListItem,
} from "@material-ui/core";
import { AuthContext } from "../contexts/AuthProvider";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  logoutIcon:{
    minWidth:'auto',
  },
}));
function LogoutHooks() {
  const classes = useStyles();
  const { login } = useContext(AuthContext);
  const history = useHistory();

  const onLogoutSuccess = (res) => {
    login(null);
    localStorage.clear();
    history.push("/");
  };

//   const onFailure = () => {
//     console.log("Handle failure cases");
//   };
  const signOut = () => {
    onLogoutSuccess();
  };
  // const { signOut } = useGoogleLogout({
  //   clientId,
  //   onLogoutSuccess,
  //   onFailure,
  // });

  return (
    <ListItem button onClick={signOut}>
      <ListItemIcon className={classes.logoutIcon}>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText />
    </ListItem>
  );
}

export default LogoutHooks;
