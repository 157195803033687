/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import {
	// checkDateInRange,
	handleSnackBar,
	getDailyData,
	getWeeklyData,
	getMonthlyData,
	getDailyFollowUpData,
	getMenuItems,
	getSoldCars,
	checkDateInRangeFilter,
	getDailyInspectionData,
	getCustomFilteredAppointment,
	getMonthlyInspectionData,
} from "../../utils/methods";
import moment from 'moment';
import { getAppointmentFields, getLimitedRows } from "../../utils/methods";
import { AuthContext } from "../../contexts/AuthProvider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import firebase from "../../config/Firebase";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FilterAppointment from "./FilterAppointment";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import Toaster from "../atoms/Toaster";
import { colors } from "../../theme";
import {
	Typography,
	Table,
	TableBody,
	TablePagination,
	TableCell,
	TableHead,
	TableRow,
	Paper,
	Box,
	SvgIcon,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Loader from '../atoms/Loader';
import Filter from '../molecules/FilterData';
import { FILTERBY } from '../../Config';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const StyledTableCell = withStyles((theme) => ({
	head: {
		background: "#1B154E 0% 0% no-repeat padding-box",
		color: colors.white,
		fontSize: 13,
		whiteSpace: "normal",
		wordWrap: "break-word",
		minWidth: (props) => props.minwidth ? 'auto' : 103,
		padding: 7,
		fontFamily: "Montserrat, SemiBold",
		textAlign: "left",
		font: "normal normal 600 13px/17px Montserrat",
		letterSpacing: 0.26,
		opacity: 1,
		"& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
			color: "#fff"
		},
	},
	body: {
		padding: "5px 7px",
		fontFamily: "Montserrat, Medium",
		textAlign: "left",
		fontSize: 12,
		//font: "normal normal medium 12px/16px Montserrat",
		letterSpacing: "0.12px",
		color: "#000000",
		opacity: 1,
		minWidth: (props) => props.minwidth ? 'auto' : 103,
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
		float: 'right',
		'@media(max-width: 1260px)': {
			marginTop: 40
		},
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	filteButton: {
		width: "100%",
		marginTop: '0px',
		marginBottom: -18,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
		float: 'right',
		'@media(max-width: 768px)': {
			marginTop: 40
		},
	},
	table: {
		minWidth: 700,
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.background.default,
		},
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	caption: {
		textAlign: "left !important",
		captionSide: "top !important",
	},
	expand: {
		textAlign: "right !important",
		captionSide: "top !important",
		cursor: "pointer",
		float: 'right',
	},
	input: {
		width: 130,
		height: 40,
	},
	searchIcon: {
		bottom: 1,
		color: "#1B154E",
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		cursor: 'pointer',
		marginLeft: 5,
	},
	editIcon: {
		bottom: 1,
		color: "#fff",
		background: '#1B154E',
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		padding: 3,
		cursor: 'pointer',
	},
	noResult: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 25,
		marginBottom: 25,
		fontSize: 16,
		color: colors.primary,
		fontFamily: "Montserrat, Regular",
	},
	tableContainer: {
		minHeight: (props) => props.minHeight ? 270 : 'auto'
	}
}));

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, headCells, classes } =
		props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const cells = headCells.map((c) => {
		return { ...c, id: c.field };
	})
	return (
		<TableHead>
			<TableRow>
				{cells.map((headCell) => {
					if (!['leadDate', 'appointmentDate', 'status', 'followUpDate'].includes(headCell.id)) {
						return <StyledTableCell minwidth>{headCell.label}</StyledTableCell>
					}
					return <StyledTableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</StyledTableCell>
				}
				)}
				<StyledTableCell minwidth>Actions</StyledTableCell>
			</TableRow>
		</TableHead>
	);
}

export default function DataTable(props) {
	const [rows, setRows] = useState([]);
	const [appointments, setAppointments] = useState([]);
	const [appointmentData, setAppointmentData] = useState([])
	const [snackbarData, setSnackbarData] = useState({});
	const [rowsPerPage, setRowsPerPage] = useState(40);
	const [totalAppointments, setTotalAppointments] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const fixColumns = getAppointmentFields("collapsed") || []

	const { auth } = useContext(AuthContext);
	const classes = useStyles({ minHeight: true });
	const { exportList } = props;
	const history = useHistory();
	const location = useLocation();
	const [loading, setLoading] = useState(true);
	const [customFilterConfig, setCustomFilterConfig] = useState({});
	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('leadDate');
	useEffect(() => {
		async function getRowsPerPage() {
			const storedData = await localStorage.getItem("rowsPerPage");
			setRowsPerPage(storedData === null ? 40 : Number(storedData));
		}
		getRowsPerPage();
	}, []);

	useEffect(() => {
		if (location?.state?.edited) {
			setSnackbarData(
				handleSnackBar(
					"fire",
					location.state.edited === "edited"
						? "Edited Successfully"
						: "Saved Successfully",
					"success"
				)
			);
		}
		fetchAppointments();
	}, [location]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const limitedRows = getLimitedRows([...appointments], rowsPerPage)
		setRows(limitedRows);
		if (exportList) {
			exportList(appointments)
		}
	}, [appointments]); // eslint-disable-line react-hooks/exhaustive-deps

	const extractAppointmentsData = (data) => {
		let apps = [];
		for (let key in data) {
			data[key].appointmentId = key;
			apps.push(data[key]);
		}
		return apps || [];
	};

	const fetchAppointments = () => {
		firebase
			.database()
			.ref()
			.child("appointments")
			.get()
			.then(function (data) {
				if (data.exists()) {
					var totelFilteredAppointments = [];
					setTotalAppointments(sortByCreatedDate(extractAppointmentsData(data.val())));
					if (props.usedBy === "weekly") {
						totelFilteredAppointments = getWeeklyData(
							extractAppointmentsData(data.val()),
							"appointmentDate"
						)
					} else if (props.usedBy === "daily") {
						totelFilteredAppointments = getDailyData(
							extractAppointmentsData(data.val()),
							"appointmentDate"
						)
					} else if (props.usedBy === "monthly") {
						totelFilteredAppointments = (getMonthlyData(
							extractAppointmentsData(data.val()),
							"appointmentDate"
						))
					}
					else if (props.usedBy === "dailyFollowUp") {
						totelFilteredAppointments = getDailyFollowUpData(
							extractAppointmentsData(data.val()),
							"followUpDate"
						)
					}
					else if (props.usedBy === "monthSoldCar") {
						totelFilteredAppointments = getSoldCars(
							extractAppointmentsData(data.val()),
							"appointmentDate"
						)
					}
					else if (props.usedBy === "monthInspection") {
						totelFilteredAppointments = getMonthlyInspectionData(
							extractAppointmentsData(data.val()),
							"appointmentDate"
						)
					}
					else if (props.usedBy === "customFilter") {
						setCustomFilterConfig(props.filterConfig);
						totelFilteredAppointments = getCustomFilteredAppointment(extractAppointmentsData(data.val()), props.filterConfig);
					}
					else if (props.usedBy === "dailyInspection") {
						setCustomFilterConfig(props.filterConfig);
						totelFilteredAppointments = getDailyInspectionData(extractAppointmentsData(data.val()), props.filterConfig);
					}
					else {
						totelFilteredAppointments = extractAppointmentsData(data.val());
					}
					setAppointments(sortByCreatedDate(totelFilteredAppointments))
					setAppointmentData(sortByCreatedDate(totelFilteredAppointments))
				} else {
					console.log("No data available");
				}
				setLoading(false);
			})
			.catch(function (error) {
				setSnackbarData(handleSnackBar("fire", "Error in Fetching", "error"));
			});
	};

	const handleEdit = (row, disabled = false) => {
		history.push({
			pathname: "/manage-appointment",
			state: { row: row, mode: "edit", disabled: disabled },
		});
	};
	const getRowsAccordingToPage = (page) => {
		let count = page * rowsPerPage;
		let _arr = [...appointments];
		let finalArr = _arr.slice(count, count + rowsPerPage);
		setRows(finalArr);
	};
	const handleChangePage = (event, page) => {
		setCurrentPage(page);
		getRowsAccordingToPage(page);
	};

	const handleChangeRowsPerPage = (event) => {
		setCurrentPage(0);
		setRowsPerPage(event.target.value);
		setRows(getLimitedRows([...appointments], event.target.value));
		localStorage.setItem('rowsPerPage', event.target.value);
	};
	const handleApply = (form = {}) => {
		let filteredAppointment = [...appointmentData];
		// filter through lead id
		if (form?.leadId !== "") {
			filteredAppointment = filteredAppointment.filter((e) => Number(form.leadId) == Number(e.leadID));
		}
		// filter through car registration no
		if (form?.carRegistrationNumber !== "") {
			filteredAppointment = filteredAppointment.filter((e) => form.carRegistrationNumber == e.carRegistrationNO)
		}
		// filter through car brand
		if (form?.brand.length !== 0) {
			filteredAppointment = filteredAppointment.filter((e) => form.brand == e.make)
		}
		// filter thorugh date range 
		if (form.initial && form.final) {
			filteredAppointment = checkDateInRangeFilter(filteredAppointment, "appointmentDate", form.initial, form.final);
		}
		setAppointments(filteredAppointment);
	};

	const handelClear = () => {
		setCustomFilterConfig({})
		setAppointments(appointmentData)
	}

	const sortByCreatedDate = (rows) => {
		if (!props.from) {
			return rows.sort(function (x, y) {
				return new Date(x.appointmentDate) - new Date(y.appointmentDate);
			});
		}
		return rows.sort(function (a, b) {
			return new Date(b.createdAt) - new Date(a.createdAt);
		});
	}

	const getStatusLabel = (key) => {
		const items = getMenuItems('status');
		return items.find((item) => {
			return item.value === key;
		})
	}

	const getCaption = (customFilterConfig) => {
		let title = 'Filter By: ';
		if (!Object.keys(customFilterConfig).length) {
			title += 'None';
		} else {
			const filterBy = FILTERBY.find((filter) => filter.value === customFilterConfig.filterBy);
			title += filterBy.label;
		}
		return title;
	}

	const handleCustomFilter = (filterConfig) => {
		props.onCaptionChange(getCaption(filterConfig));
		setCustomFilterConfig(filterConfig);
		setAppointments(getCustomFilteredAppointment(totalAppointments, filterConfig));
	}

	const handleCustomReset = () => {
		props.onCaptionChange(getCaption({}));
		setCustomFilterConfig({});
		setAppointments([]);
	}

	const getColumns = () => {
		const filters = { ...customFilterConfig };
		if (props.usedBy !== 'customFilter') return [...fixColumns];
		let filteredColumns = [...getAppointmentFields("collapsed")] || [];
		if (customFilterConfig.filterBy === 'status') {
			return filteredColumns.slice(0, filteredColumns.length - 1);
		}
		if (props.customFilter && filteredColumns.length && Object.keys(filters).length && filters.filterBy) {
			let filterByColumn = getAppointmentFields().find((col) => {
				return col.field === filters.filterBy;
			})
			filteredColumns[filteredColumns.length - 1] = {
				field: filterByColumn.field,
				role: filterByColumn.role,
				label: filterByColumn.label,
				type: filterByColumn.type
			}
		}
		return [...filteredColumns];
	}

	function descendingComparator(a, b, orderBy) {
		// nulls sort after anything else
		if (!a[orderBy]) {
			return 1;
		}
		if (!b[orderBy]) {
			return -1;
		}
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return -1;
	}

	function getComparator(order, orderBy) {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	// This method is created for cross-browser compatibility, if you don't
	// need to support IE11, you can use Array.prototype.sort() directly
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const getSortedData = () => {
		const re = stableSort(appointments, getComparator(order, orderBy));
		let count = currentPage * rowsPerPage;
		let _arr = [...re];
		let finalArr = _arr.slice(count, count + rowsPerPage);
		return finalArr;
	}

	return (
		<>
			<Paper className={classes.filteButton}>
				<div className={classes.expand}>
						<>
							{props.customFilter ?
								<Filter
									usedBy={props.usedBy}
									filterConfig={props.filterConfig}
									handleApply={handleCustomFilter}
									handleClear={handleCustomReset} /> :
								<FilterAppointment
									usedBy={props.usedBy}
									handleApply={handleApply}
									handelClear={handelClear}
								/>}
						</>
				</div>

			</Paper>
			<Paper className={classes.root}>

				{loading ? <div className={classes.paper} style={{ padding: '20% 50%' }}>
					<Loader />
				</div> :
					<>

						<Box className={classes.tableContainer}>
							<Table className={classes.table} aria-label="caption table">
								<EnhancedTableHead
									classes={classes}
									order={order}
									orderBy={orderBy}
									rowCount={rows.length}
									headCells={getColumns()}
									onRequestSort={(e, p) => handleRequestSort(e, p)}
								/>
								{/* <TableHead>
									<TableRow>
										{getColumns().map((column, index) => {
											if (auth?.role === "lex") {
												if (column.role === "lex")
													return <StyledTableCell key={index}>{column.label}</StyledTableCell>;
											} else return <StyledTableCell key={index}>{column.label}</StyledTableCell>;
											return null;
										})}
										<StyledTableCell minwidth>Actions</StyledTableCell>
									</TableRow>
								</TableHead> */}
								<TableBody>
									{rows?.length > 0 &&
										getSortedData().map((row, index) => {
											if (row)
												return (
													<TableRow key={row.appointmentId} className={classes.row}>
														{getColumns().map((column, i) => {
															if (auth?.role === "lex") {
																if (column.role === "lex")
																	return (
																		<StyledTableCell
																			key={i}
																			className={classes.selectTableCell}
																		>
																			{
																				column.field === 'followUpDate' && row[column.field] ?
																					moment(row[column.field]).format('D MMMM YYYY') :
																					column.field === 'leadDate' && row[column.field] ?
																						moment(row[column.field]).format('D MMMM YYYY') :
																						column.field === 'appointmentDate' && row[column.field] ?
																							moment(row[column.field]).format('D MMMM YYYY') :
																							column.field === 'status' && row[column.field] ?
																								getStatusLabel(row[column.field]).label :
																								column.field && row[column.field] ?
																									row[column.field] :
																									"-"}
																		</StyledTableCell>
																	);
															} else
																return (
																	<StyledTableCell key={i} className={classes.selectTableCell}>
																		{
																			column.field === 'followUpDate' && row[column.field] ?
																				moment(row[column.field]).format('D MMMM YYYY') :
																				column.field === 'leadDate' && row[column.field] ?
																					moment(row[column.field]).format('D MMMM YYYY') :
																					column.field === 'appointmentDate' && row[column.field] ?
																						moment(row[column.field]).format('D MMMM YYYY') :
																						column.field === 'status' && row[column.field] ?
																							getStatusLabel(row[column.field]).label :
																							column.field && row[column.field] ?
																								row[column.field] :
																								"-"}
																	</StyledTableCell>
																);
															return null;
														})}
														<StyledTableCell className={""}>
															<SvgIcon
																titleAccess="Edit"
																aria-label="done"
																className={classes.editIcon}
																onClick={() => handleEdit(row)}
															>
																<EditIcon />
															</SvgIcon>
															<VisibilityIcon
																titleAccess="View"
																aria-label="done"
																className={classes.searchIcon}
																onClick={() => handleEdit(row, true)}
															/>
														</StyledTableCell>
													</TableRow>
												);
											return null;
										})}
								</TableBody>
							</Table>
							{rows?.length === 0 && (
								<Typography className={classes.noResult}>
									No Records To Display
								</Typography>
							)}
						</Box>
						<TablePagination
							rowsPerPageOptions={[10, 20, 30, 40]}
							component="div"
							count={appointments?.length}
							rowsPerPage={rowsPerPage}
							page={currentPage}
							backIconButtonProps={{
								"aria-label": "Previous Page",
							}}
							nextIconButtonProps={{
								"aria-label": "Next Page",
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
						/>
						<Toaster
							snackbarData={snackbarData}
							revertSnackBarData={() => {
								setSnackbarData(handleSnackBar("hide"));
							}}
						/>
					</>}
			</Paper>
		</>
	);
}

