import React, { useState, useEffect, useContext } from "react";
//import DataTable from "../components/atoms/DataTable";
import StandAloneDataTable from "../components/molecules/StandAloneDataTable";
import {
  getExportableData,
  handleSnackBar,
} from "../utils/methods";
import ExportCSV from "../components/atoms/ExportCSV";
import Toaster from "../components/atoms/Toaster";
import { AuthContext } from "../contexts/AuthProvider";
//import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { makeStyles, Paper } from "@material-ui/core";
import { colors } from "../theme";
import Excel from '../components/atoms/Excel'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 25px 30px #0000000A",
    opacity: 1,
    padding: 10,
    boxSizing: " border-box",
  },
  exportData: {
    background: "#FFD13B",
    color: colors.primary,
    fontFamily: "Montserrat, Medium",
    marginTop: 15,
    width: "10%",
  },
  tableTitle: {
    display: "flex",
    alignItems: " center",
    justifyContent: " space-between",
    marginBottom: 10,
    marginTop: 10,
    paddingLeft: 20,
  },
  caption: {
    textAlign: "left !important",
    captionSide: "top !important",
    fontSize: "18px !important",
    fontFamily: "Montserrat, Semibold !important",
    color: "#1B154E !important",
  },
  expand: {
    background: "#FFD13B",
    padding: "9px 21px 9px 9px",
    float: " right",
    borderRadius: 2,
    cursor: "pointer",
  },
  titleStyle: {
    marginLeft: 15,
	fontSize:18,
    marginBottom: -35,
    fontFamily: 'Montserrat, SemiBold',
    color: '#1B154E',
    marginTop: 10,
  },
}));

const Appointment = () => {
  const classes = useStyles();
  const [appointments, setAppointments] = useState([]);
  const [snackbarData, setSnackbarData] = useState({});
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const [caption, setCaption] = useState('');

  useEffect(() => {
    if (auth) {
      console.log({ auth });
    }
    setCaption(location?.state?.caption
      ? location.state.caption
      : "Total Appointments on the basis of creation");
    setSnackbarData(handleSnackBar("hide"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper className={classes.root}>
      <div>
        <div>
        <h3 className={classes.titleStyle}>
          {caption}
        </h3>
        </div>
        <StandAloneDataTable
          caption={caption}
          exportList={setAppointments}
          usedBy={location?.state?.usedBy}
          from={!location?.state?.caption ? "appointments": ""}
          customFilter={location?.state?.customFilter}
          filterConfig={location?.state?.filterConfig}
          onCaptionChange={(caption) => setCaption(caption)}
        />
      </div>
      <Toaster
        snackbarData={snackbarData}
        revertSnackBarData={() => {
          setSnackbarData(handleSnackBar("hide"));
        }}
      />
      <div>
        {/* <ExportCSV
          csvData={getExportableData(appointments)}
          fileName={"appointments.csv"}
        /> */}
        <Excel
          csvData={getExportableData(appointments)}
        />
      </div>
    </Paper>
  );
};

export default Appointment;
