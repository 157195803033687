export const ALL_APPOINTMENT_FIELDS = [
	{ field: 'leadID', label: 'Lead ID', role: 'lex', type: 'text', disabled: true },
	{ field: 'lead', label: 'Lead Id', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'carRegistrationNO', label: 'Car Registration No', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'isOther', label: 'Car Registration No', role: 'lex', type: 'text', disabled: true},
	{ field: 'createdAt', label: 'Created At', role: 'lex', type: 'date', disabled: true },
	{ field: 'make', label: 'Make', role: 'lex', type: 'dropDown', fieldType: 'details', makefield: 'makefield' },
	{ field: 'model', label: 'Model', role: 'lex', type: 'dropDown', fieldType: 'details', modelfield: 'modelfield' },
	{ field: 'year', label: 'Year', role: 'lex', type: 'dropDown', fieldType: 'details', yearfield: 'yearfield' },
	{ field: 'variant', label: 'Variant', role: 'lex', type: 'dropDown', fieldType: 'details', variantfield: 'variantfield' },
	{ field: 'leadDate', label: 'Lead Date', role: 'lex', type: 'date', fieldType: 'details', fieldValue: 'leadDate' },
	{ field: 'firstName', label: 'First Name', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'lastName', label: 'Last Name', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'mobile', label: 'Mobile', role: 'lex', type: 'number', fieldType: 'details', fieldValue: 'phoneNumber' },
	{ field: 'email', label: 'Email', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'appointmentDate', label: 'Appointment Date', role: 'lex', type: 'date', fieldType: 'details', fieldValue: 'appDate' },
	{ field: 'appointmentTime', label: 'Appointment Time', role: 'lex', type: 'time', fieldType: 'details' },
	{ field: 'address', label: 'Address', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'customAddress', label: 'Custom Address', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'rcOwner', label: 'RC Owner', role: 'lex', type: 'text', fieldType: 'details' },
	{ field: 'leadSource', label: 'Lead Source', role: 'lex', type: 'dropDown', fieldType: 'details' },
	{ field: 'status', label: 'Status', role: 'lex', type: 'dropDown', fieldType: 'details' },
	{ field: 'followUpDate', label: 'Follow Up Date', role: 'lex', type: 'date', fieldType: 'details' },
	{ field: 'assignedTo', label: 'Assigned To', role: 'lex', type: 'dropDown', fieldType: 'details' },
	{ field: 'sellerPriceExpectation', label: 'Seller Price Expectation', role: 'lex', type: 'number', fieldType: 'details', fieldValue: "sellerPriceExc" },
	{ field: 'offeredPrice', label: 'Offered Price', role: 'lex', type: 'number', fieldType: 'details' },
	{ field: 'actualPrice', label: 'Actual Price', role: 'lex', type: 'number', fieldType: 'details' },
	{ field: 'comments', label: 'Comments', role: 'lex', type: 'comment', fieldType: 'details' },
	{ field: 'delhiTrafficChallan', label: 'Delhi Traffic Challan', role: 'lex', type: 'text', fieldType: 'TrafficChallan' },
	{ field: 'vahanParivahanChallan', label: 'Vahan & Parivahan Challan', role: 'lex', type: 'text', fieldType: 'TrafficChallan' },
	{ field: 'challanHoldDeduction', label: 'Challan Hold / Deduction', role: 'lex', type: 'boolean', firstOption: 'Hold', secondOption: 'Deduction', fieldType: 'TrafficChallan' },
	{ field: 'challanHoldReleaseDate', label: 'Challan Hold Release date', role: 'stm', type: 'date', fieldType: 'TrafficChallan' },

	//{field: 'nocHoldAmt', label: 'NOC Hold Amt', role: 'lex', type: 'number', fieldType: 'carSellDetails'},
	//{field: 'nocHoldReleaseDate', label: 'NOC Hold Release date', role: 'stm', type: 'date', fieldType: 'carSellDetails'},
	{ field: 'purchaseDate', label: 'Purchased Date', role: 'lex', type: 'date', fieldType: 'carPurchaseDetails' },
	{ field: 'modeOfPayment', label: 'Mode Of Payment', role: 'stm', type: 'dropDown', fieldType: 'carPurchaseDetails' },
	{ field: 'purchasePrice', label: 'Purchased Price', role: 'stm', type: 'number', fieldType: 'carPurchaseDetails' },
	{ field: 'saleDate', label: 'Sale Date', role: 'stm', type: 'date', fieldType: 'carSellDetails' },
	{ field: 'buyerName', label: 'Buyer Name', role: 'stm', type: 'text', fieldType: 'carSellDetails' },
	{ field: 'buyerAddress', label: 'Buyer Address', role: 'stm', type: 'text', fieldType: 'carSellDetails' },
	{ field: 'buyerBusinessName', label: 'Buyer Business Name', role: 'stm', type: 'text', fieldType: 'carSellDetails' },
	{ field: 'buyerContactNo', label: 'Buyer Contact No', role: 'stm', type: 'number', fieldType: 'carSellDetails', fieldValue: 'phoneNumber' },
	{ field: 'buyerBusinessAddress', label: 'Buyer Business Address', role: 'stm', type: 'text', fieldType: 'carSellDetails', buyerBusiness: 'buyerBusinessAddress' },
	{ field: 'soldBy', label: 'Sold by', role: 'stm', type: 'text', fieldType: 'carSellDetails' },
	{ field: 'soldPrice', label: 'Sold Price', role: 'stm', type: 'number', fieldType: 'carSellDetails' },
	//{field: 'salePrice', label: 'Sale Price', role: 'stm', type: 'number',fieldType: 'carSellDetails'},
	{ field: 'modeOfPayment', label: 'Mode Of Payment Received', role: 'stm', type: 'dropDown', fieldType: 'carSellDetails' },
	{ field: 'profit', label: 'Profit', role: 'stm', type: 'number', fieldType: 'carSellDetails' },
	{ field: 'holdingPeriod', label: 'Holding Period', role: 'stm', type: 'hold', fieldType: 'carSellDetails', holding: 'holdingPeriod' },
	{ field: 'delhiTrafficChallan', label: 'Delhi Traffic Challan', role: 'stm', type: 'text', fieldType: 'challan' },
	{ field: 'vahanParivahanChallan', label: 'Vahan & Parivahan Challan', role: 'stm', type: 'text', fieldType: 'challan' },
	{ field: 'challanHoldDeduction', label: 'Challan Hold/Deduction"', role: 'stm', type: 'boolean', firstOption: 'Hold', secondOption: 'Deduction', fieldType: 'challan' },
	{ field: 'challanHoldReleaseDate', label: 'Challan Hold Release date', role: 'stm', type: 'date', fieldType: 'challan' },
	{ field: 'blackListedDetails', label: 'Black Listed Details', role: 'lex', type: 'dropDown', fieldType: 'challan' },
	{ field: 'hypothecation', label: 'Hypothecation', role: 'lex', type: 'dropDown', fieldType: 'challan' },
	{ field: 'nocHoldAmt', label: 'NOC Hold Amt', role: 'stm', type: 'number' },
	{ field: 'nocHoldReleaseDate', label: 'NOC Hold Release date', role: 'stm', type: 'date' },
	{ field: 'challan',label: 'Challan', role: 'lex', type: 'text', fieldType: 'TrafficChallan'  },
	{ fiels: 'parivahanChallan' },
	{ fields: 'isOther', role: 'lex', type: 'text', disabled: true  }

];
export const SOME_APPOINTMENT_FIELDS = [
	{ field: 'leadDate', label: 'Lead Date', role: 'lex', type: 'date'},
	{ field: 'leadID', label: 'Lead ID', role: 'lex', type: 'text', disabled: true },
	{ field: 'appointmentDate', label: 'Appointment Date', role: 'lex', type: 'date' },
	{ field: 'make', label: 'Make', role: 'lex', type: 'dropDown' },
	{ field: 'model', label: 'Model', role: 'lex', type: 'dropDown' },
	{ field: 'firstName', label: 'First Name', role: 'lex', type: 'text' },
	{ field: 'mobile', label: 'Mobile', role: 'lex', type: 'text' },
	{ field: 'sellerPriceExpectation', label: 'Seller Price Expectation', role: 'lex', type: 'number' },
	{ field: 'offeredPrice', label: 'Offered Price', role: 'lex', type: 'number' },
	{ field: 'status', label: 'Status', role: 'lex', type: 'dropDown' },
	{ field: 'followUpDate', label: 'Follow Up Date', role: 'lex', type: 'date' },
];

export const APPOINTMENT_MAKE = [
	{ value: 'chevrolet', label: 'CHEVROLET' },
	{ value: 'mahindra', label: 'MAHINDRA' },
	{ value: 'tata', label: 'TATA' }
];

export const APPOINTMENT_VARIENT = [
	{ value: 'petrol', label: 'PETROL' },
	{ value: 'diesel', label: 'DESIEL' },
];

export const APPOINTMENT_MODEL = [
	{ value: 'cruze', label: 'CRUZE', make: 'chevrolet' },
	{ value: 'thar', label: 'THAR', make: 'MAHINDRA' },
	{ value: 'altroz', label: 'ALTROZ', make: 'TATA' }
];

export const MODE_OF_PAYMENT = [
	{ value: 'cash', label: 'CASH' },
	{ value: 'cheque', label: 'CHEQUE' },
	{ value: 'upi', label: 'UPI' },
	{ value: 'credit card', label: 'CREDIT CARD' },
	{ value: 'debit card', label: 'DEBIT CARD' },
	{ value: 'imps', label: 'IMPS' },
	{ value: 'neft/rtgs', label: 'NEFT/RTGS' },

];

export const CAR_SELL_STATUS = [
	{ value: 'yes', label: 'Yes' },
	{ value: 'no', label: 'No' },
];

export const APPOINTMENT_LEAD_SOURCE = [
	{ value: 'email', label: 'Email' },
	{ value: 'telephone', label: 'Telephone' },
	{ value: 'customer_care', label: 'Customer Care' },
	{ value: 'application', label: 'Application' },
	{ value: 'google', label: 'Google' },
	{ value: 'facebook', label: 'Facebook' },
	{ value: 'instagram', label: 'Instagram' }
];

export const APPOINTMENT_STATUS = [
	{ value: 'new_lead', label: 'New Lead' },
	{ value: 'inspection_scheduled', label: 'Inspection Scheduled' },
	{ value: 'cancel', label: 'Cancel' },
	{ value: 'inspection_rescheduled', label: 'Inspection Rescheduled' },
	{ value: 'inspected', label: 'Inspected' },
	{ value: 'price_quoted', label: 'Price Quoted' },
	{ value: 'under_negotiation', label: 'Under Negotiation' },
	{ value: 'success', label: 'Success' },
	{ value: 'document_collected', label: 'Document Collected' },
	{ value: 'token_done', label: 'Token Done' },
	{ value: 'full_payment_done', label: 'Full Payment Done' },
	{ value: 'holdback_confirmation', label: 'Holdback Confirmation' },
	{ value: 'car_picked_up', label: 'Car Picked Up' },
	{ value: 'transaction_complete', label: 'Transaction Complete' },
	{ value: 'holdback_release', label: 'Holdback Release' },
	{ value: 'reassurance_of_price', label: 'Reassurance Of Price' },
	{ value: 'lost', label: 'Lost' },
];

export const USER_ROLES = [
	{ value: 'admin', label: 'Admin' },
	{ value: 'stm', label: 'STM' },
	{ value: 'lex', label: 'LEX' },
	{ value: 'car_inspector', label: 'Car Inspector' }
]
export const YEAR = [
	{ value: 2017, label: 2017 },
	{ value: 2018, label: 2018 },
	{ value: 2019, label: 2019 },
]

// export const APPOINTMENT_TIME = [
// 	{ value: '08:00-08:30 am', label: '08:00-08:30 AM' },
// 	{ value: '08:30-09:00 am', label: '08:30-09:00 AM' },
// 	{ value: '09:00-09:30 am', label: '09:00-09:30 AM' },
// 	{ value: '09:30-10:00 am', label: '09:30-10:00 AM' },
// 	{ value: '10:00-10:30 am', label: '10:00-10:30 AM' },
// 	{ value: '10:30-11:00 am', label: '10:30-11:00 AM' },
// 	{ value: '11:00-11:30 am', label: '11:00-11:30 AM' },
// 	{ value: '11:30-12:00 am', label: '11:30-12:00 AM' },
// 	{ value: '12:00-12:30 pm', label: '12:00-12:30 PM' },
// 	{ value: '12:30-01:00 pm', label: '12:30-01:00 PM' },
// 	{ value: '01:00-01:30 pm', label: '01:00-01:30 PM' },
// 	{ value: '01:30-02:00 pm', label: '01:30-02:00 PM' },
// 	{ value: '02:00-02:30 pm', label: '02:00-02:30 PM' },
// 	{ value: '02:30-03:00 pm', label: '02:30-03:00 PM' },
// 	{ value: '03:00-03:30 pm', label: '03:00-03:30 PM' },
// 	{ value: '03:30-04:00 pm', label: '03:30-04:00 PM' },
// 	{ value: '04:00-04:30 pm', label: '04:00-04:30 PM' },
// 	{ value: '04:30-05:00 pm', label: '04:30-05:00 PM' },
// 	{ value: '05:00-05:30 pm', label: '05:00-05:30 PM' },
// 	{ value: '05:30-06:00 pm', label: '05:30-06:00 PM' }
// ] 

export const FILTERBY = [
	{ value: "leadDate", label: "Lead Date" },
	{ value: "leadSource", label: "Lead Source" },
	{ value: "followUpDate", label: "Follow Up Date" },
	{ value: "saleDate", label: "Sale Date" },
	{ value: "assignedTo", label: "Assigned To" },
	{ value: "status", label: "Status" },
	{ value: "soldBy", label: "Sold By" }
]
