import React from 'react'
import { CSVLink } from 'react-csv'
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

    const useStyles = makeStyles((theme) => ({
        exportResult: {
          display: "flex",
          alignItems: "center",
          fontSize: 12,
          color: colors.primary,
          fontFamily: "Montserrat, SemiBold",
          textDecoration: 'double',
        },
        exportButton: {
			textTransform:'none',
			fontWeight:'600',
			borderRadius:4,
            background: '#FFD13B !important',
            marginTop: '10px'
        }
      }));

const ExportCSV = ({csvData, fileName}) => {

	function convertToCSV(arr) {
		const array = [Object.keys(arr[0])].concat(arr)
	  		return array.map(it => {
		  return Object.values(it).toString()
		}).join('\n')
	  }
	  
    const csvDataArray = csvData.map(csvObj => {
        const csvDataKeys = Object.keys(csvObj)
        const filterObject = {}
        csvDataKeys.forEach(csvKey => {
            return filterObject[csvKey] = typeof csvObj[csvKey] === 'object' ? 
			csvObj[csvKey] && csvObj[csvKey].length && convertToCSV(csvObj[csvKey]) : csvObj[csvKey]
        })
        return filterObject
    })

    const classes = useStyles();
    return (
        <Button className={classes.exportButton}>
            <CSVLink className={classes.exportResult} data={csvDataArray} 
			filename={fileName}>Export</CSVLink>
        </Button>
    )
}
export default ExportCSV;