import React, { useContext, useState } from "react";
import firebase from "../config/Firebase";
//import auth from "../config/Firebase";
// refresh token
//import { refreshTokenSetup } from "../utils/refreshToken";
import { AuthContext } from "../contexts/AuthProvider";
import { useHistory } from "react-router-dom";
//import { DateFinder } from "../utils/dateFinder";
import LogoutHooks from "./LogoutHooks";
import {
	TextField,
	Button,
	Typography,
	Paper,
	makeStyles,
	Grid,
	IconButton,
	Box,
	InputAdornment,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
//import { ColoredButton } from '../components/atoms/buttons/';
import AppLogo from "../assets/images/App-logo.png";
import { colors } from "../theme";
//import { ColoredButton } from "../"
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: colors.aliceBlue,
		padding: "12px 4px",
	},
	paper: {
		padding: theme.spacing(2),
		color: colors.sunGlow,
		backgroundColor: colors.primary,
		fontSize: 14,
		background: colors.primary,
		opacity: 1,
	},
	title: {
		fontSize: 14,
		height: 80,
		width: 260,
		color: colors.primary,
	},
	pos: {
		marginBottom: 12,
	},
	test: {
		height: 500,
		width: 450,
		textAlign: "center",
		display: "inline-block",
		"@media(max-width: 768px)": {
			width: "100%",
		},
		"@media(min-width: 1920px)": {
			height: 500,
			width: 500,
		},
		"@media(min-width: 2500px)": {
			height: 500,
			width: 500,
		},
		"@media(min-width: 3000px)": {
			height: 500,
			width: 600,
		},
	},
	textSize: {
		fontSize: 16,
		marginLeft: 88,
		marginRight: 89,
		color: colors.midnightBlue,
	},
	logo: {
		marginTop: 30,
		// marginLeft: 150,
		"@media(max-width: 768px)": {
			// margin:0,
		},
	},
	boxSize: {
		width: "100%",
		"@media(max-width: 800px) and (min-width: 700px)": {
			width: "65% !important",
		},
	},
	loginButton: {
		width: "100%",
		background: colors.midnightBlue,
		color: colors.white,
		"&:hover": {
			backgroundColor: colors.midnightBlue,
		},
		"@media(max-width: 800px) and (min-width: 700px)": {
			width: "65% !important",
		},
	},
	policyText: {
		fontSize: 13,
		marginLeft: 58,
		marginRight: 58,
		marginBottom: 16,
		color: colors.midnightBlue,
		fontFamily: "Montserrat, Regular",
	},
	loginError: {
		color: "#FF1744",
	},
	logoSetions: {
		display: "flex",
		alignItems: " center",
		justifyContent: "center",
		maxWidth: "inherit",
	},
}));

function LoginHooks() {
	const classes = useStyles();
	const { login } = useContext(AuthContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const [loginError, setLoginError] = useState();

	const signIn = () => {
		setLoading(true);
		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((res) => {
				firebase
					.database()
					.ref(`/users/${res.user.uid}`)
					.once("value")
					.then(function (data) {
						let _data = data.val();
						if (_data.role === "car_inspector") {
							setLoginError("You are not authorized to login!");
							setLoading(false);
							return;
						}
						if (!_data.isActive) {
							setLoginError("You are suspended");
							setLoading(false);
							return;
						}
						localStorage.setItem("email", res.user.email || null);
						localStorage.setItem("uid", res.user.uid || null);
						localStorage.setItem("name", _data.name || null);
						//set authcontext
						login({
							email: _data.email,
							role: _data.role,
							name: _data.name,
							uid: res.user.uid,
						});
						setLoading(false);
						history.push("/dashboard");
					});
			})
			.catch((error) => {
				setLoading(false);
				<LogoutHooks />;
				setLoginError(error.message || "");
				console.log("error: ", error);
			});
	};
	setTimeout(() => {
		setLoginError("");
	}, 20000);

	return (
		<Paper className={classes.test}>
			<Typography component="h1" variant="h5">
				<Grid xs={1} item className={classes.logoSetions}>
					<img
						className={classes.logo}
						src={AppLogo}
						alt="Carboli App Logo"
						title="test"
					/>
				</Grid>
			</Typography>
			{/* <Typography className={classes.textSize}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </Typography> */}
			<br />
			<br />
			<Box pl={4} pr={4}>
				<TextField
					className={classes.boxSize}
					required
					id="standard-adornment-password"
					variant="outlined"
					label="Email"
					type={"email"}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					endadornment={<InputAdornment position="end"></InputAdornment>}
				/>
				<br />
				<br />
				<TextField
					className={classes.boxSize}
					id="standard-adornment-password"
					variant="outlined"
					label="Password"
					type={showPassword ? "text" : "password"}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					endadornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
							>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
				/>
				{loginError && (
					<Typography className={classes.loginError}>{loginError}</Typography>
				)}
				<br />
				<br />
				<Button
					variant="outlined"
					color="primary"
					onClick={signIn}
					className={classes.loginButton}
				>
					{loading ? <CircularProgress size={22} color="white" /> : "Login"}
				</Button>
				<br />
				<br />
				<Typography className={classes.policyText}>
					By clicking on next, you acknowledge that you have read and accepted
					the Terms of Service and the Privacy Policy.
        </Typography>
			</Box>
		</Paper>
	);
}

export default LoginHooks;
