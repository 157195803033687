import React, { useState, useEffect, useContext } from "react";
import {
	getAppointmentFields,
	handleSnackBar,
	getLimitedRows,
	getMenuItems
} from "../../utils/methods";
import moment from 'moment';
import { AuthContext } from "../../contexts/AuthProvider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import Toaster from "../atoms/Toaster";
import { colors } from "../../theme";
import SvgIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Filter from '../molecules/FilterData';

const StyledTableCell = withStyles((theme) => ({
	head: {
		background: "#1B154E 0% 0% no-repeat padding-box",
		color: colors.white,
		fontSize: 13,
		whiteSpace: "normal",
		wordWrap: "break-word",
		minWidth: (props) => props.minwidth ? 'auto' : 103,
		padding: 7,
		fontFamily: "Montserrat, SemiBold",
		textAlign: "left",
		font: "normal normal 600 13px/17px Montserrat",
		letterSpacing: 0.26,
		opacity: 1,
	},
	body: {
		padding: "5px 7px",
		fontFamily: "Montserrat, Medium",
		textAlign: "left",
		fontSize: 12,
		//font: "normal normal medium 12px/16px Montserrat",
		minWidth: (props) => props.minwidth ? 'auto' : 103,
		letterSpacing: "0.12px",
		color: "#000000",
		opacity: 1,
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
	},
	table: {
		minWidth: 700,
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.background.default,
		},
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	caption: {
		textAlign: "left !important",
		captionSide: "top !important",
		fontSize: "18px !important",
		fontFamily: "Montserrat, Semibold !important",
		color: "#1B154E !important",
	},
	expand: {
		background: "#FFD13B",
		padding: '6px 12px',
		float: ' right',
		borderRadius: 2,
		fontWeight: 600,
		cursor: 'pointer',
		textTransform: 'none'
	},
	input: {
		width: 130,
		height: 40,
	},
	searchIcon: {
		bottom: 1,
		color: "#1B154E",
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		cursor: 'pointer',
		marginLeft: 5,
	},
	editIcon: {
		bottom: 1,
		color: "#fff",
		background: '#1B154E',
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		padding: 3,
		cursor: 'pointer',
	},
	noResult: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 25,
		marginBottom: 25,
		fontSize: 16,
		color: colors.primary,
		fontFamily: "Montserrat, Regular",
	},
	expandList: {
		fontSize: 12,
		color: "#1B154E",
		fontWeight: '600',
		fontFamily: "Montserrat, Regular",
	},
	tableTitle: {
		display: 'flex',
		alignItems: ' center',
		justifyContent: ' space-between',
		marginBottom: 10,
		marginTop: 10,

	},
	filterBy: {
		'@media (max-width:425px)': {
			marginTop: '0px !important'
		}
	},
	tableContainer: {
		minHeight: (props) => props.minHeight ? 270 : 'auto'
	}
}));

export default function DataTable(props) {
	const [rows, setRows] = useState([]);
	const [snackbarData, setSnackbarData] = useState({});
	const { auth } = useContext(AuthContext);
	const classes = useStyles();
	const { caption } = props;
	const history = useHistory();
	const location = useLocation();
	const [customFilterConfig, setCustomFilterConfig] = useState({});
	const fixColumns = getAppointmentFields("collapsed") || []
	React.useEffect(() => {
		if (location?.state?.edited) {
			setSnackbarData(
				handleSnackBar(
					"fire",
					location.state.edited === "edited"
						? "Edited Successfully"
						: "Saved Successfully",
					"success"
				)
			);
		}
	}, [location]);
	useEffect(() => {
		if (props.from === "dashboard") setRows(getLimitedRows(props.tableData));
	}, [props]);

	const handleEdit = (row, disabled = false) => {
		history.push({
			pathname: "/manage-appointment",
			state: { row: row, mode: "edit", disabled: disabled },
		});
	};
	const handleExpand = () => {
		history.push({
			pathname: "/appointment",
			state: { caption: caption, usedBy: props.usedBy, customFilter: props.customFilter, filterConfig: props.filterConfig },
		});
	};

	const getStatusLabel = (key) => {
		const items = getMenuItems('status');
		return items.find((item) => {
			return item.value === key;
		})
	}

	const getColumns = () => {
		if (props.usedBy !== 'customFilter') return [...fixColumns];

		let filteredColumns = [...getAppointmentFields("collapsed")] || [];
		if (customFilterConfig.filterBy === 'status') {
			return filteredColumns.slice(0, filteredColumns.length - 1);
		}

		if (props.customFilter && filteredColumns.length && Object.keys(customFilterConfig).length && customFilterConfig.filterBy) {
			let filterByColumn = getAppointmentFields().find((col) => {
				return col.field === customFilterConfig.filterBy;
			})
			filteredColumns[filteredColumns.length - 1] = {
				field: filterByColumn.field,
				role: filterByColumn.role,
				label: filterByColumn.label,
				type: filterByColumn.type
			}
		}
		return [...filteredColumns];
	}

	const handleCustomFilter = (filter) => {
		setCustomFilterConfig(filter);
		props.handleCustomFilter(filter);
	}

	const handleCustomReset = () => {
		setCustomFilterConfig({});
		props.handleCustomReset();
	}


	return (
		<Paper className={classes.root}>
			<div className={classes.tableTitle}>
				{caption && (
					<div
						onClick={() => console.log("caption")}
						className={classes.caption}
					>
						{caption}
					</div>
				)}

				{props.customFilter ?
					<div style={{ display: 'flex', marginTop: 12 }}>
						<div style={{ marginRight: 5, marginTop: 2 }}>
							<Filter handleApply={(filter) => handleCustomFilter(filter)}
								handleClear={() => handleCustomReset()} />
						</div>

						{rows.length ? <div onClick={() => handleExpand()} className={classes.expand} style={{ height: '40px !important' }}>
							<Typography className={classes.expandList}>Expand List</Typography>
						</div> : <div style={{ height: '40px !important' }}></div>}

					</div> :
					rows.length ? (
						<div onClick={() => handleExpand()} className={classes.expand}>
							<Typography className={classes.expandList}>Expand List</Typography>
						</div>
					) : <></>}
			</div>
			<Box className={classes.tableContainer}>
				<Table className={classes.table} aria-label="caption table">

					<TableHead>
						<TableRow>
							{getColumns()?.map((column, index) => {
								if (auth?.role === "lex") {
									if (column.role === "lex")
										return (
											<StyledTableCell key={index} align="left">
												<strong>{column.label}</strong>
											</StyledTableCell>
										);
								} else
									return (
										<StyledTableCell key={index} align="left">
											<strong>{column.label}</strong>
										</StyledTableCell>
									);
								return null;
							})}
							<StyledTableCell align="left" minwidth>Actions</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.length > 0 &&
							rows.map((row, index) => {
								if (row)
									return (
										<TableRow key={index} className={classes.row}>
											{getColumns().map((column, i) => {
												if (auth?.role === "lex") {
													if (column.role === "lex")
														return (
															<StyledTableCell
																key={i}
																className={classes.selectTableCell}
															>
																{
																	column.field === 'followUpDate' && row[column.field] ?
																		moment(row[column.field]).format('D MMMM YYYY') :
																		column.field === 'leadDate' && row[column.field] ?
																		moment(row[column.field]).format('D MMMM YYYY') :
																		column.field === 'appointmentDate' && row[column.field] ?
																			moment(row[column.field]).format('D MMMM YYYY') :
																			column.field === 'status' && row[column.field] ?
																				getStatusLabel(row[column.field]).label :
																				column.field && row[column.field] ?
																					row[column.field] :
																					"-"}
															</StyledTableCell>
														);
												} else
													return (
														<StyledTableCell key={i} className={classes.selectTableCell}>
															{column.field === 'followUpDate' && row[column.field] ?
																moment(row[column.field]).format('D MMMM YYYY') :
																column.field === 'leadDate' && row[column.field] ?
																moment(row[column.field]).format('D MMMM YYYY') :
																column.field === 'appointmentDate' && row[column.field] ?
																moment(row[column.field]).format('D MMMM YYYY') :
																column.field === 'status' && row[column.field] ?
																	getStatusLabel(row[column.field]).label :
																	column.field && row[column.field] ?
																		row[column.field] :
																		"-"}
														</StyledTableCell>
													);
												return null;
											})}
											<StyledTableCell className={""} minwidth>
												<SvgIcon
													titleAccess="Edit"
													aria-label="done"
													className={classes.editIcon}
													onClick={() => handleEdit(row)}
												>
													<EditIcon />
												</SvgIcon>

												<VisibilityIcon
													titleAccess="View"
													aria-label="done"
													className={classes.searchIcon}
													onClick={() => handleEdit(row, true)}
												/>

											</StyledTableCell>
										</TableRow>
									);
								return null;
							})}
					</TableBody>
				</Table>
			</Box>
			{rows?.length === 0 && (
				<Typography className={classes.noResult}>
					No Records To Display
				</Typography>
			)}
			<Toaster
				snackbarData={snackbarData}
				revertSnackBarData={() => {
					setSnackbarData(handleSnackBar("hide"));
				}}
			/>
		</Paper>
	);
}
