import React, { useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
const Toaster = (props) => {
    const [data, setData] = useState({})
    useEffect(()=>{
        setData(props.snackbarData)
    },[props.snackbarData])
    const handleSnackBar = (action, text='', _type='success') => {
        setData({
            isVisible: action==='hide' ? false : true,
            data: text,
            type: _type
        })
        props.revertSnackBarData();
    }
    return <>
        {data.isVisible &&
         <Snackbar open={data.isVisible} autoHideDuration={2000} onClose={()=>handleSnackBar('hide')}>
            <Alert onClose={()=>handleSnackBar('hide')} severity={data.type}>
                {data.data}
            </Alert>
        </Snackbar>
        }   
    </>
}

export default Toaster;