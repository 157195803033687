import React from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: "7px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
  count: {
    color: theme.palette.primary.main,
  },
  paperStyle: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxShadow: "0px 25px 30px #0000000A",
    borderRadius: 0,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    //borderRadius: 2,
    paddingLeft: 20,
    paddingRight: 20,
    opacity: 1,
    "& div": {
      "& img": {
        width: 44,
      },
    },
  },
  commonBoxStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: '100%',
	cursor:'pointer',
    "& h6": {
      fontSize: 14,
      marginBottom: 5,
    },
    "& h5": {
      fontSize: 18,
      marginBottom: 5,
      color: theme.palette.primary.main,
    },
    "& span": {
      fontSize: 11,
      "& span": {
        color: theme.palette.success.light,
      },
    },
  },
  lessTablePer: {
    color: `${theme.palette.primary.dark}!important`,
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
  },
  title: {
    color: "#1B154E",
    fontSize: 18,
    marginBottom: 20,
    fontFamily: "Montserrat, Semibold",
  },
  headTitle: {
    color: "#1B154E !important",
    fontSize: 28,
    fontFamily: "Montserrat, Bold",
    marginLeft: 10,
  },
  iconSize: {
    marginTop: 10,
  },
  dividerLine: {
    width: '100%',
  },
  commonBoxImg:{
    display: 'flex',
    alignItems: 'center'
  }
}));
const HeadingPaper = (props) => {
  const {
    heading,
    headingData,
    subHeading,
    subHeadingData,
    imageIcon,
    isLoading,
    style = '',
	onClick
  } = props;
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm className={style}>
        <Paper square className={`${classes.paper} ${classes.paperStyle}`}>
          <Box className={classes.commonBoxStyle}
		  onClick={onClick}
		  >
            <Grid item xs={12} sm>
              <Typography className={classes.title}>{heading}</Typography>
            </Grid>
            <Divider className={classes.dividerLine} />
           <div className={classes.commonBoxImg}>
           <Typography className={classes.iconSize}>
              {imageIcon && <img src={imageIcon} alt="order icon" />}
            </Typography>
            <Typography className={classes.headTitle}>{headingData}</Typography>
            <Typography variant="caption">
              {subHeading || ""}
              <span>&nbsp;</span>
              {subHeadingData ? (
                <span>{subHeadingData}</span>
              ) : (
                <span>&nbsp;</span>
              )}
            </Typography>
           </div>
          </Box>
          {isLoading && <CircularProgress className={classes.progress} />}
        </Paper>
      </Grid>
    </>
  );
};

export default HeadingPaper;
