import React, { useState, useEffect, useContext } from "react";
import {
	handleSnackBar,
	getDailyData,
	getWeeklyData,
	getMonthlyData,
	getDailyFollowUpData,
	// getMenuItems,
	getSoldCars,
	getMonthlyInspectionData,
	getDailyInspectionData,
	// checkDateInRange,
	// checkDateInRangeFilter,
	getCustomFilteredAppointment
} from "../utils/methods";
import DataTable from "../components/atoms/DataTable";
import Toaster from "../components/atoms/Toaster";
import {
	makeStyles,
	Button,
	Typography,
	Grid,
	Divider,
} from "@material-ui/core";
//import ExportCSV from "../components/atoms/ExportCSV";
import firebase from "../config/Firebase";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../contexts/AuthProvider";
import { colors } from "../theme";
import GroupIcon from "../assets/images/Group.png";
import ThisWeekIcon from "../assets/images/Thisweek.png";
import ThisMonthIcon from "../assets/images/Thismonth.png";
import CarIcon from "../assets/images/Car.png";
import HeadingPaper from "../components/headingPaper";
import Loader from '../components/atoms/Loader';
import { FILTERBY } from '../Config';

const useStyles = makeStyles((theme) => ({
	// root: {
	//   flexGrow: 1,
	//   width: "100%",
	//   backgroundColor: colors.aliceBlue,
	//   padding: "8px 4px",
	// },
	root: {
		width: "100%",
		backgroundColor: colors.aliceBlue
	},
	paper: {
		padding: theme.spacing(2),
		color: colors.sunGlow,
		background: "#1B154E 0% 0% no-repeat padding-box",
		opacity: 1,
		fontSize: 30,
		fontFamily: "Montserrat",
		top: 91,
		left: 267,
		height: 85,
		width: '100%',
		display: 'flex',
		alignItems: ' center',
		justifyContent: 'space-between',
	},
	addButton: {
		background: "#FFD13B",
		fontWeight: '600',
		borderRadius: 4,
		color: colors.primary,
		fontFamily: "Montserrat, Regular",
		textTransform: 'none',
		fontSize: 12,
		"&:hover": {
			backgroundColor: "#FFD13B",
		},
	},
	pos: {
		marginBottom: 12,
	},
	noResult: {
		marginLeft: 490,
		marginTop: 40,
		color: colors.primary,
		fontSize: 16,
		fontFamily: "Montserrat, Medium",
	},
	dividerLIne: {
		width: '100%',
		background: '#707070',
		height: 2,
		opacity: 0.32,
	},
	headingContainer: {
		// [theme.breakpoints.up('md')]: {
		//   paddingLeft: '30px !important'
		// }
	},
	loader: {
		width: '100%',
		height: '70vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	homeContainer: {
		backgroundColor: colors.gray
	}
}));

const Home = () => {
	const [appointments, setAppointments] = useState([]);
	const [snackbarData, setSnackbarData] = useState({});
	const history = useHistory();
	const [apptData, setApptObj] = useState({
		todayAppt: 0,
		weeklyAppt: 0,
		monhtlyAppt: 0,
		soldCar: 0,
		monthlyInspection: 0
	});
	const [loading, setLoading] = useState(true);
	const [customTableData, setCustomTableData] = useState([]);
	const [customFilterConfig, setCustomFilterConfig] = useState({});

	const classes = useStyles();
	const { auth } = useContext(AuthContext);
	useEffect(() => {
		fetchAppointments();
		setSnackbarData(handleSnackBar("hide"));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (appointments.length > 0) {
			setApptObj({
				todayAppt: getDailyData(appointments, "appointmentDate")?.length || 0,
				weeklyAppt: getWeeklyData(appointments, "appointmentDate")?.length || 0,
				monhtlyAppt:
					getMonthlyData(appointments, "appointmentDate")?.length || 0,
				soldCar: getSoldCars(appointments)?.length || 0,
				monthlyInspection:
					getMonthlyInspectionData(appointments, "appointmentDate")?.length || 0,
			});
		}
	}, [appointments]);
	const { monhtlyAppt, soldCar, weeklyAppt, todayAppt, monthlyInspection } = apptData;
	const extractAppointmentsData = (data) => {
		let apps = [];
		for (let key in data) {
			data[key].appointmentId = key;
			apps.push(data[key]);
		}
		return apps || [];
	};

	const fetchAppointments = () => {
		firebase
			.database()
			.ref()
			.child("appointments")
			.get()
			.then(function (data) {
				if (data.exists()) {
					setAppointments(extractAppointmentsData(data.val()));
				} else {
					console.log("No data available");
				}
				setLoading(false);
			})
			.catch(function (error) {
				console.error(error);
				setSnackbarData(handleSnackBar("fire", "Error in Fetching", "error"));
			});
	};

	const getDescendingData = (rows) => {
		return rows.sort(function (x, y) {
			return new Date(x.appointmentDate) - new Date(y.appointmentDate);
		});
	}

	const getStstusOrder = (rows) => {
		const arrangement = ['new_lead', 'inspection_scheduled', 'cancel', 'inspection_rescheduled', 'inspected', 'price_quoted',
			'under_negotiation', 'success', 'document_collected', 'token_done', 'full_payment_done','holdback_confirmation', 'car_picked_up', 'transaction_complete', undefined, ""];
		let data = [];
		arrangement.forEach((arr) => {
			const statusData = rows.filter((row)=>(row.status===arr))
			data = [...data, ...statusData];			
		});
		return data;
	}

	const handleCustomFilter = (filterConfig) => {
		setCustomFilterConfig(filterConfig);
		setCustomTableData(getCustomFilteredAppointment(appointments, filterConfig));
	}

	const handleCustomReset = () => {
		setCustomFilterConfig({});
		setCustomTableData([]);
	}

	const getCaption = (customFilterConfig) => {
		let title = 'Filter By: ';
		if (!Object.keys(customFilterConfig).length) {
			title += 'None';
		} else {
			const filterBy = FILTERBY.find((filter) => filter.value === customFilterConfig.filterBy);
			title += filterBy.label;
		}
		return title;
	}

	const onClick = (caption, usedBy) => {
		history.push({
			pathname: "/appointment",
			state: { caption: caption, usedBy: usedBy },
		});
	}

	return (
		<div className={classes.root}>
			<Grid container spacing={1} className={classes.homeContainer}>
				<Grid item xs={12} sm={12}>
					<Typography className={classes.paper}>
						{" "}
              Welcome {auth?.name}
						{/* <ExportCSV csvData={[apptData]} fileName={"appts.csv"} /> */}
						<Button
							className={classes.addButton}
							onClick={() =>
								history.push({
									pathname: "/manage-appointment",
									state: { mode: "new" },
								})
							}
						>
							Create New Appointment
            </Button>
					</Typography>
				</Grid>

				{loading ?
					<div className={classes.loader} >
						<Loader />
					</div> :
					<>
						<HeadingPaper
							heading="Today's Appointments"
							imageIcon={GroupIcon}
							headingData={todayAppt || 0}
							onClick={() => { onClick("Today's Appointments", "daily", "dashboard") }}
						/>
						<HeadingPaper
							heading="This Week's Appointment"
							imageIcon={ThisWeekIcon}
							headingData={weeklyAppt}
							style={classes.headingContainer}
							onClick={() => { onClick("This Week's Appointment", "weekly", "dashboard") }}
						/>
						<HeadingPaper
							heading="This Month's Appointment"
							imageIcon={ThisMonthIcon}
							headingData={monhtlyAppt}
							style={classes.headingContainer}
							onClick={() => { onClick("This Month's Appointment", "monthly", "dashboard") }}
						/>
						<HeadingPaper
							heading="This Month's Sold Car"
							imageIcon={CarIcon}
							headingData={soldCar}
							style={classes.headingContainer}
							onClick={() => { onClick("This Month's Sold Car", "monthSoldCar", "dashboard") }}
						/>
						<HeadingPaper
							heading="This Month's Inspection"
							imageIcon={ThisMonthIcon}
							headingData={monthlyInspection}
							style={classes.headingContainer}
							onClick={() => { onClick("This Month's Inspection", "monthInspection", "dashboard") }}
						/>

						<>
							<Grid item xs={12} sm={12}>
								<div className={classes.dividerLIne}></div>
							</Grid>
							<Grid item xs={12} sm={12}>
								<DataTable
									tableData={getStstusOrder(getDescendingData(getDailyData(appointments, "appointmentDate")))}
									caption={"Today's Appointments"}
									usedBy={"daily"}
									from={"dashboard"}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<div className={classes.dividerLIne}></div>
							</Grid>
							<Grid item xs={12} sm={12}>
								<DataTable
									tableData={getStstusOrder(getDescendingData(getDailyInspectionData(appointments, "appointmentDate")))}
									caption={"Today's Inspection"}
									usedBy={"dailyInspection"}
									from={"dashboard"}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<div className={classes.dividerLIne}></div>
							</Grid>


							<Grid item xs={12} sm={12}>
								<DataTable
									tableData={getStstusOrder(getDescendingData(getWeeklyData(appointments, "appointmentDate")))}
									caption={"This Week's Appointment"}
									usedBy={"weekly"}
									from={"dashboard"}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<div className={classes.dividerLIne}></div>
							</Grid>

							<Grid item xs={12} sm={12}>
								<DataTable
									tableData={getStstusOrder(getDescendingData(getMonthlyData(appointments, "appointmentDate")))}
									caption={"This Month's Appointment"}
									usedBy={"monthly"}
									from={"dashboard"}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<div className={classes.dividerLIne}></div>
							</Grid>

							<Grid item xs={12} sm={12}>
								<DataTable
									tableData={getStstusOrder(getDailyFollowUpData(appointments, "followUpDate"))}
									caption={"Today's FollowUp"}
									usedBy={"dailyFollowUp"}
									from={"dashboard"}
								/>
							</Grid>
							{
								auth?.role === "lex" ? null :
									<Grid item xs={12} sm={12}>
										<DataTable
											tableData={customTableData}
											caption={getCaption(customFilterConfig)}
											usedBy="customFilter"
											from={"dashboard"}
											customFilter={true}
											filterConfig={customFilterConfig}
											handleCustomFilter={handleCustomFilter}
											handleCustomReset={handleCustomReset}
										/>
									</Grid>
							}
							<Divider />
							<Toaster
								snackbarData={snackbarData}
								revertSnackBarData={() => {
									setSnackbarData(handleSnackBar("hide"));
								}}
							/>
						</>
					</>}
			</Grid>
		</div>
	);
};

export default Home;
