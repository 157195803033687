import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		flexWrap: "wrap",
		'& .MuiSelect-selectMenu': {
			fontFamily: 'Montserrat, Regular',
			color: '#000000',
		},
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		'& .MuiInput-underline.Mui-disabled:before':{
			borderBottomStyle: 'solid',
		}
	},
}));

const CustomSelect = (props) => {
	const classes = useStyles();
	const { data, defaultValue, disabled, brandList=[] } = props;
	const { label, field } = data;
	const [value, setValue] = useState(defaultValue || '');
	
	const brandArray = brandList && Object.keys(brandList)

	const handleChange = (value) => {
		setValue(value);
		props.onChange(field, value);
		props.onClick(value);
	};

	return (
		<div className={classes.root}>
			<TextField
				select
				labelid="demo-simple-select-label"
				id="demo-simple-select"
				value={value}
				label={label}
				fullWidth
				disabled={disabled}
				displayEmpty
				className={classes.textField}
				InputLabelProps={{
					style: {
						fontSize: 14,
						color: "rgb(28, 20, 81)",
						fontFamily: "Montserrat, SemiBold"
					},
				}} 
				onChange={(event) => handleChange(event.target.value)}
			>
				{brandArray?.map((e) => {
					return <MenuItem value={brandList[e].name}>{brandList[e].name}</MenuItem>;
				})}
			</TextField>
		</div>
	);
};
export default CustomSelect;
