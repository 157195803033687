import { createMuiTheme } from '@material-ui/core/styles'
import { blue, grey } from "@material-ui/core/colors"


const light = createMuiTheme({
	palette: {
		primary: {
			light: '#FFCF00',
			main: '#FFCF00',
			dark: '#FFCF00'
		},
		secondary: {
			light: blue[300],
			main: blue[500],
			dark: blue[900]
		},
		background: {
			paper: "#fff",
			default: "#FBF7F4"
		},
		text: {
			primary: '#1B154E',
			secondary: grey[500],
			blue: blue[500],
			light: '#fff'
		}
	},
	typography: {
		fontFamily: "Montserrat, Montserrat"
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				fontWeight: 600,
				textTransform: "none",
			},
			containedPrimary: {
				borderColor: "#17142F",
				'&:hover': {
					backgroundColor: "#3f3a58",
				}
			},
			containedSecondary: {
				borderColor: "#F2797C",
				'&:hover': {
					backgroundColor: "#ffaaab",
				}
			},
		}
	},
	
})

const themes = {
	light
}

export default themes
