import React, { useState, useEffect, createContext } from 'react'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import themes from './themeProvider'

const store = {
	currentTheme: 'light',
	updateTheme: (_properties) => {console.log('inside store')},
	getThemes: {}
}

export const ThemeContext = createContext(store)

/** Provides the Locale in the context of each child component */
const ThemeProviderCustom = (props) => {
	const [currentTheme, setCurrentTheme] = useState('light')

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const currentThemeSettings = window.localStorage.getItem(
				'theme'
			)
			if (currentThemeSettings) {
				setCurrentTheme(currentThemeSettings)
			}
		}
	}, [])

	const updateTheme = (properties) => {
		const {
			currentTheme
		} = properties

		if (typeof window !== 'undefined') {
			window.localStorage.setItem('theme', currentTheme)
		}
		setCurrentTheme(currentTheme)
	}

	return (
		<ThemeProvider theme={themes[currentTheme]}>
			<CssBaseline />
			<ThemeContext.Provider value={{ currentTheme, updateTheme }}>
				{props.children}
			</ThemeContext.Provider>
		</ThemeProvider>
	)
}

export default ThemeProviderCustom
