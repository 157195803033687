import React, { useState, useEffect } from "react";
import { APPOINTMENT_TIME } from "../../utils/methods";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, TextField } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    "& .MuiSelect-selectMenu": {
      fontFamily: "Montserrat, Regular",
      color: "#000000",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minHeight: 50,
	'& .MuiInput-underline.Mui-disabled:before':{
		borderBottomStyle: 'solid',
	}
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();

  const { data, defaultValue, disabled, appointmentDate } = props;
  const { label, field } = data;
  const [value, setValue] = useState(defaultValue || "");
  const [menuItems, setMenuIteams] = React.useState([]);
  const handleChange = (value) => {
    setValue(value);
    props.onChange(field, value);
  };
  useEffect(() => {
    APPOINTMENT_TIME().then((time) => {
      setMenuIteams(time);
    });
  }, []);
  const getSlots = () => {
    return menuItems?.map((e) => {
      let time_1 = e.value.split("-");
      let appointmentTime = moment(time_1[1], ["h:mm A"]).format("HH:mm"); //Appointmet

      let currntTime = moment().add(2, "hours").format("HH:mm"); // cuurent time

  if(appointmentDate===moment().format("YYYY-MM-DD")){
  if (appointmentTime > currntTime) {
    return <MenuItem value={e.value}>
    <div style={{ display: 'flex', width: '100%' }}>
      <div className={classes.timeSlots}>{e.label.slice(0, e.label.length - 2)}</div>
      <div className={classes.time}>{e.label.slice(e.label.length - 2, e.label.length)}</div>
    </div>
  </MenuItem>
    }
}else{
  return <MenuItem value={e.value}>
        <div style={{ display: 'flex', width: '100%' }}>
          <div className={classes.timeSlots}>{e.label.slice(0, e.label.length - 2)}</div>
          <div className={classes.time}>{e.label.slice(e.label.length - 2, e.label.length)}</div>
        </div>
      </MenuItem>
}
    })
  }

  const getLabel = () => {
    const slots = getSlots();
    if(slots && !slots[slots.length-1]){
      props.onChange(field, '');
      return 'No Slot Available!'
    }
    return label;
  }
  return (
    <div className={classes.root}>
      <TextField
        select
        labelid="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={getLabel()}
        fullWidth
        disabled={disabled}
        displayEmpty
        className={classes.textField}
        InputLabelProps={{
          style: {
            fontSize: 14,
            color: "rgb(28, 20, 81)",
            fontFamily: "Montserrat, SemiBold",
          },
        }} // font size of input label
        onChange={(event) => handleChange(event.target.value)}
      >
        {getSlots()}
      </TextField>
    </div>
  );
};
export default CustomSelect;
