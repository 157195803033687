/* eslint-disable array-callback-return */
import {
	ALL_APPOINTMENT_FIELDS,
	SOME_APPOINTMENT_FIELDS,
	YEAR,
	APPOINTMENT_MAKE,
	USER_ROLES,
	APPOINTMENT_MODEL,
	APPOINTMENT_STATUS,
	APPOINTMENT_LEAD_SOURCE,
	MODE_OF_PAYMENT,
	CAR_SELL_STATUS,
	APPOINTMENT_VARIENT
} from "../Config";
import moment from "moment";
import firebase from "../config/Firebase";
//import { getAllByTestId } from "@testing-library/dom";

export const getMenuItems = (usedBy) => {
	if (usedBy === "make") return APPOINTMENT_MAKE;
	if (usedBy === "model") return APPOINTMENT_MODEL;
	if (usedBy === "variant") return APPOINTMENT_VARIENT;
	if (usedBy === "status") return APPOINTMENT_STATUS;
	if (usedBy === "leadSource") return APPOINTMENT_LEAD_SOURCE;
	if (usedBy === "role") return USER_ROLES;
	if (usedBy === "year") return YEAR;
	if (usedBy === "modeOfPayment") return MODE_OF_PAYMENT;
	if (usedBy === "blackListedDetails") return CAR_SELL_STATUS;
	if (usedBy === "hypothecation") return CAR_SELL_STATUS;
	if (usedBy === "filteassignedTorType") return fetchUsers;
};
export const getAppointmentFields = (type = "expanded") => {
	if (type === "collapsed") return SOME_APPOINTMENT_FIELDS;
	return ALL_APPOINTMENT_FIELDS;
};
export const getDataTableColumns = (data) => {
	let _data = [];
	if (data) {
		data.forEach((e) => {
			if (e) _data.push(e);
		});
	}
	// { field: 'id', headerName: 'ID', width: 70 },
	let columns = [];
	let keys = findKeysFromLengthiestObjectInTheList(_data) || [];
	keys.forEach((key) => {
		columns.push({
			field: key,
			headerName: capitalizeFirstLetterOfPascalCaseWords(key),
		});
	});
	return columns;
};
export const findKeysFromLengthiestObjectInTheList = (list) => {
	let max = {
		length: 0,
		data: [],
	};
	list &&
		list.map((e, index) => {
			if (Object.keys(e).length > max.length) {
				max.length = Object.keys(e).length;
				max.data = [...Object.keys(e)];
			}
			return null;
		});
	return max.data;
};
export const checkDateInRange = (data, initial, final) => {
	let _initial = moment(initial);
	let _final = moment(final);
	let arr = [];
	data.forEach(e => {
		if (e.appointmentDate) {
			if (moment(e.appointmentDate).isBetween(_initial, _final)) {
				arr.push(e);
			} else {
				if (typeof (e.createdAt) === 'string') if (moment(parseInt(e.createdAt)).isBetween(_initial, _final)) arr.push(e)
				if (typeof (e.createdAt) === 'number') if (moment(e.createdAt).isBetween(_initial, _final)) arr.push(e);
			}
		}
	})
	return arr;
}

export const checkDateInRangeFilter = (data, key, initial, final) => {
	if (!initial && !final) {
		let newArr = [];
		data.forEach(e => {
			if (e[key] !== undefined && e[key] !== '') {
				newArr.push(e);
				return;
			}
		})
		return newArr;
	}
	let _initial = initial && initial.length ? moment(initial).subtract(1, "days") : '';
	let _final = final && final.length ? moment(final).add(1, "days") : '';
	let arr = [];
	data.forEach(e => {
		if (e[key]) {
			if (_initial && _final && moment(e[key]).isBetween(_initial, _final)) {
				arr.push(e);
				return;
			} else if (_initial && !_final && moment(e[key]).isAfter(_initial)) {
				arr.push(e);
			} else if (!_initial && _final && moment(e[key]).isBefore(_final)) {
				arr.push(e);
			}
		}
	})
	return arr;
}


export const getSoldCars = (data) => {
	return data.filter((record) => {
		if (moment().month() === moment(record.saleDate).month()) {
			return record.saleDate;
		}
	})
}

export const getDailyData = (data = [], field = "appointmentDate") => {
	let now = moment();
	//let then = moment().add(1, "days");
	let _then = moment().subtract(1, "days");
	let arr = [];
	data.forEach((e) => {
		if (e[field]) {
			if (field === "appointmentDate") {
				//get the future value
				if (moment(e[field]).isSame(moment(), 'day')) arr.push(e);
			} else {
				//for 1 month from todays date in past
				if (typeof e[field] === "string")
					if (moment(parseInt(e[field])).isBetween(_then, now)) arr.push(e);
				if (typeof e[field] === "number")
					if (moment(e[field]).isBetween(_then, now)) arr.push(e);
			}
		}
	});
	return arr;
};
export const getDailyInspectionData = (data = [], field = "appointmentDate", fields = "status") => {
	let now = moment();
	//let then = moment().add(1, "days");
	let _then = moment().subtract(1, "days");
	let arr = [];
	data.forEach((e) => {
		if (e[field]) {
			if (field === "appointmentDate") {
				//get the future value
				if (moment(e[field]).isSame(moment(), 'day')) {
					if (['inspected', 'price_quoted', 'under_negotiation', 'success', 'lost', 'inspection_rescheduled'].includes(e[fields]))
						arr.push(e);
				}
			} else {
				//for 1 month from todays date in past
				if (typeof e[field] === "string")
					if (moment(parseInt(e[field])).isBetween(_then, now)) arr.push(e);
				if (typeof e[field] === "number")
					if (moment(e[field]).isBetween(_then, now)) arr.push(e);
			}
		}
	});
	return arr;
};

export const getWeeklyData = (data = [], field = "appointmentDate") => {
	let now = moment();
	// let _now = moment().subtract(1, "days");
	let then = moment().add(7, "days");
	let _then = moment().subtract(7, "days");
	let arr = [];
	data.forEach((e) => {
		if (e[field]) {
			if (field === "appointmentDate") {
				//get the future value
				if (moment(e[field]).isSame(moment(), 'day') || moment(e[field]).isBetween(now, then, undefined, '[)')) arr.push(e);
			} else {
				//for 1 month from todays date in past
				if (typeof e[field] === "string")
					if (moment(parseInt(e[field])).isBetween(_then, now)) arr.push(e);
				if (typeof e[field] === "number")
					if (moment(e[field]).isBetween(_then, now)) arr.push(e);
			}
		}
	});
	return arr;
};
export const getMonthlyData = (data = [], field = "appointmentDate") => {
	let now = moment();
	// let _now = moment().subtract(1, "days");
	let then = moment().add(1, "month");
	let _then = moment().subtract(1, "month");
	let arr = [];
	data.forEach((e) => {
		if (e[field]) {
			if (field === "appointmentDate") {
				//for 1 month from todays date
				if (moment(e[field]).isSame(moment(), 'day') || moment(e[field]).isBetween(now, then, undefined, '[)')) arr.push(e);
			} else {
				if (typeof e[field] === "string")
					if (moment(parseInt(e[field])).isBetween(_then, now)) arr.push(e);
				if (typeof e[field] === "number")
					if (moment(e[field]).isBetween(_then, now)) arr.push(e);
			}
		}
	});
	return arr;
};
export const getMonthlyInspectionData = (data = [], field = "appointmentDate", fields = "status") => {
	let now = moment();
	// let then = moment().add(1, "month");
	let _then = moment().subtract(1, "month");
	let arr = [];
	data.forEach((e) => {
		if (e[field]) {
			if (field === "appointmentDate") {
				//for 1 month from todays date
				if (moment(e[field]).isSame(moment(), 'day') || moment(e[field]).isBetween(_then, now, undefined, '[)')) {
					if (['inspected', 'price_quoted', 'under_negotiation', 'document_collected', 'token_done'].includes(e[fields]))
						arr.push(e);
				}
			} else {
				if (typeof e[field] === "string")
					if (moment(parseInt(e[field])).isBetween(_then, now)) arr.push(e);
				if (typeof e[field] === "number")
					if (moment(e[field]).isBetween(_then, now)) arr.push(e);
			}
		}
	});
	return arr;
};

export const getDailyFollowUpData = (data = [], field = "followUpDate") => {
	let now = moment();
	//let then = moment().add(1, "days");
	let _then = moment().subtract(1, "days");
	let arr = [];
	data.forEach((e) => {
		if (e[field]) {
			if (field === "followUpDate") {
				//get the future value
				if (moment(e[field]).isSame(moment(), 'day')) arr.push(e);
			} else {
				//for 1 month from todays date in past
				if (typeof e[field] === "string")
					if (moment(parseInt(e[field])).isBetween(_then, now)) arr.push(e);
				if (typeof e[field] === "number")
					if (moment(e[field]).isBetween(_then, now)) arr.push(e);
			}
		}
	});
	return arr;
};
export const capitalizeFirstLetterOfPascalCaseWords = (str) => {
	let _str = str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
	return capitalizeFirstLetter(_str);
};
export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
export const dateFormatter = (date, type = "mdy", seperator = "/") => {
	let _date = new Date(date).toLocaleString().split(",")[0];
	let details = _date.split("/");
	if (type === "dmy")
		return `${details[1]}${seperator}${details[0]}${seperator}${details[2]}`;
	return _date;
};
export const getFormValues = (from = "", data = {}) => {
	let fields = [];
	var obj = {};
	//var leadId = null;
	if (from === "appointments") fields = ALL_APPOINTMENT_FIELDS;
	if (Object.keys(data).length === 0) {
		fields.forEach((e) => {
			obj[e.field] = null;
		});
	} else {
		for (let key in data) {
			fields.forEach((e) => {
				if (e.field === key) {
					obj[key] = data[key];
				}
			});
		}
		obj["userId"] = data["userId"];
		obj["appointmentId"] = data["appointmentId"];
	}
	return obj;
};

export const getLimitedRows = (data = [], rows = 5) => {
	if (data.length >= rows) {
		data.splice(rows);
		return data;
	} else return data;
};

export const getLeadId = async () => {
	let _date = new Date().toLocaleString().split(",")[0];
	let details = _date.split("/");
	var ans = null;
	const dates = `${details[2]}${details[0].length === 1 ? `0${details[0]}` : details[0]
		}${details[1].length === 1 ? `0${details[1]}` : details[1]}`

	await firebase
		.database()
		.ref()
		.child("appointments")
		.orderByChild("leadID")
		.startAt(
			parseInt(dates)
		)
		.get()
		.then(function (data) {
			if (data.exists()) {
				ans = filterLatestLeadId(data.val(), dates);
			}
		});
	if (ans) return ans;
	else
		return parseInt(`${details[2]}${details[0].length === 1 ? `0${details[0]}` : details[0]
			}${details[1].length === 1 ? `0${details[1]}` : details[1]}0001`);
};

export const filterLatestLeadId = (data, time) => {
	let arr = [];
	for (let key in data) {
		data[key].id = key;
		arr.push(data[key]);
	}
	let latestDateData = arr.filter(p => String(p.leadID).startsWith(time));
	const newId = leadIdNew(latestDateData);
	return newId;
}

export const leadIdNew = (data) => {
	if (data.length === 0) {
		return false;
	}
	let latestId = 0;
	data.forEach((e) => {
		if (parseInt(e.leadID) > latestId)
			latestId = parseInt(e.leadID);
	})
	return latestId + 1;
}

export const getLatestLeadId = (data) => {
	let latestId = 0;
	for (let key in data) {
		if (parseInt(data[key].leadID) > latestId)
			latestId = parseInt(data[key].leadID);
	}
	return latestId + 1;
};

export const getExportableData = (data) => {
	if (data[0]) return data;
	else return [];
};

export const handleSnackBar = (action, text = "", _type = "success") => {
	return {
		isVisible: action === "hide" ? false : true,
		data: text,
		type: _type,
	};
};

export const getCarData = async (query = 'cars') => {
	let Brands = {};
	return await firebase
		.database()
		.ref()
		.child(query)
		.get()
		.then(function (data) {
			if (data.exists()) {
				Brands = data.val();
				return Brands;
			}
		})
		.catch(function (error) {
			console.error(error);
		});
}

export const getVariantData = async () => {
	let variants = [];
	return await firebase
		.database()
		.ref()
		.child("carVariants")
		.get()
		.then(function (data) {
			if (data.exists()) {
				variants = extractVariantsData(data.val());
				return variants;
			}
		})
		.catch(function (error) {
			console.error('==error===', error);
		});
}
export const extractVariantsData = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].variantId = key;
		apps.push(data[key]);
	}
	return apps || [];
};
export const APPOINTMENT_TIME = async () => {
	let timeSlots = [];
	return await firebase
		.database()
		.ref()
		.child("appointmentTimeSlots")
		.get()
		.then(function (data) {
			if (data.exists()) {
				timeSlots = data.val();
				return timeSlots;
			}
		})
		.catch(function (error) {
			console.error('==error===', error);
		});
}

export const getCustomFilteredAppointment = (appointments, filterConfig) => {
	// When no filter is applied
	if (!Object.keys(filterConfig).length) return [];

	let filteredAppointments = [...appointments];
	// date filteration for without specific entity
	if (['leadSource', 'assignedTo', 'status', 'soldBy'].includes(filterConfig.filterBy) && filterConfig.startDate && filterConfig.endDate) {
		filteredAppointments = checkDateInRange(filteredAppointments, filterConfig.startDate, filterConfig.endDate);
	}
	// date filteration for specific entity
	if (['leadDate', 'followUpDate', 'saleDate'].includes(filterConfig.filterBy)) {
		filteredAppointments = checkDateInRangeFilter(filteredAppointments, filterConfig.filterBy, filterConfig.startDate, filterConfig.endDate);
	}

	if (['status', 'leadSource', 'assignedTo'].includes(filterConfig.filterBy) && filterConfig.filterType) {
		if (filterConfig.filterType === "inspected") {
			filteredAppointments = checkPostStatusData(filteredAppointments, filterConfig.filterBy)
		} else {
			filteredAppointments = filteredAppointments.filter((appoint) => appoint[filterConfig.filterBy] === filterConfig.filterType);
		}
	}
	return filteredAppointments;
}

export const checkPostStatusData = (data, filterBy) => {
	let arr = [];
	data.forEach((e) => {
		if (['inspected', 'price_quoted', 'under_negotiation', 'document_collected', 'token_done'].includes(e[filterBy]))
			arr.push(e);
	});
	return arr;
}

export const fetchUsers = async () => {
	let users = [];
	return await firebase
		.database()
		.ref()
		.child("users")
		.get()
		.then(function (data) {
			if (data.exists()) {
				users = extractUsers(data.val());
				return users;
			}
			else {
				console.log("No data available");
			}
		})
		.catch(function (error) {
			console.error(error);
		});
}

export const extractUsers = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].newsId = key;
		apps.push(data[key]);
	}
	let user = apps.filter((filter) => filter.role === "car_inspector")
	return user || [];
};

export const getWhatsappUsers = async (mobileNo) => {
	let users = [];
	let mobile = "";
	if (mobileNo) {
		mobile = mobileNo;
	} else {
		mobile = "";
	}
	return await firebase.database()
		.ref("whatsAppUpdate")
		.orderByChild("mobile")
		.equalTo(mobile)
		.once('value')
		.then((snapshot) => {
			users = extractWhatsappUsers(snapshot.val());
			return users[0];
		})
		.catch(function (error) {
			console.error(error);
		});
}

export const extractWhatsappUsers = (data) => {
	let apps = [];
	for (let key in data) {
		data[key].userId = key;
		apps.push(data[key]);
	}
	return apps;
};