import React, { useContext, useEffect, useState } from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TablePagination,
	Paper,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";
import { AuthContext } from "../../contexts/AuthProvider";
import { getLimitedRows } from "../../utils/methods";

const StyledTableCell = withStyles((theme) => ({
	head: {
		background: "#1B154E 0% 0% no-repeat padding-box",
		color: colors.white,
		fontSize: 12,
		whiteSpace: "normal",
		wordWrap: "break-word",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		padding: 7,
		paddingTop: '10px !important',
		paddingBottom: 10,
		fontFamily: "Montserrat, SemiBold",
		textAlign: "left",
		font: "normal normal 600 13px/17px Montserrat",
		letterSpacing: 0.26,
		opacity: 1,
	},
	body: {
		padding: "5px 7px",
		fontFamily: "Montserrat, Medium",
		textAlign: "left",
		font: "normal normal medium 12px/16px Montserrat",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		letterSpacing: "0.12px",
		color: "#000000",
		opacity: 1,
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
	},
	table: {
		minWidth: 700,
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: '#FBF7F4',
		},
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	// caption: {
	// 	textAlign: "left !important",
	// 	captionSide: "top !important",
	// 	fontSize: "18px !important",
	// 	fontFamily: "Montserrat, Semibold !important",
	// 	color: "#1B154E !important",
	// },
	expand: {
		background: "#FFD13B",
		padding: '9px 12px 9px 12px',
		float: ' right',
		borderRadius: 2,
		cursor: 'pointer',
		fontWeight: 600,
		fontFamily: "Montserrat, Semibold !important",
		marginBottom: 10,
	},
	input: {
		width: 130,
		height: 40,
	},
	searchIcon: {
		bottom: 1,
		color: "#1B154E",
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		cursor: 'pointer',
		marginLeft: 5,
	},
	editIcon: {
		bottom: 1,
		color: "#fff",
		background: '#1B154E',
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		padding: 3,
		cursor: 'pointer',
	},
	noResult: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 25,
		marginBottom: 25,
		fontSize: 16,
		//color: colors.primary,
		fontFamily: "Montserrat, Regular",
	},
	expandList: {
		fontSize: 12,
		color: "#1B154E",
		fontFamily: "Montserrat, Regular",
	},
	tableTitle: {
		display: 'flex',
		alignItems: ' center',
		justifyContent: ' space-between',
	},
	totalBar: {
		background: '#FFD13B',
		'& td': {
			paddingTop: 10,
			paddingBottom: 10,
			fontWeight: 'bold',
			color: '#1B154E'
		}
	},
	filteButton: {
		width: "100%",
		marginTop: '0px',
		marginBottom: -18,
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
		float: 'right',
		'@media(max-width: 768px)': {
			marginTop: 40
		},
	},
	priceBar: {
		padding: '12px 10px',
		background: '#1B154E',
		boxSizing: 'border-box',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& div': {
			display: 'flex',
			marginRight: '34%',
			alignItems: 'center',
		},
		'& span': {
			color: '#fff',
			textTransform: 'uppercase',
			fontSize: 13,
			letterSpacing: 3,
			fontWeight: 700,
			fontFamily: 'Montserrat, SemiBold',
		},
	},
	caption: {
		textAlign: "left !important",
		captionSide: "top !important",
		fontSize: "18px !important",
		fontFamily: "Montserrat, Semibold !important",
		color: "#1B154E !important",
		marginLeft: '5px',
		fontWeight: 600,
	},
	inactive: {
		cursor: 'pointer',
		color: 'green'
	},
	active: {
		cursor: 'pointer',
		color: 'red'
	},
	loader: {
		width: '100%',
		height: '70vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const UserList = ({ users, updateStatus, addUser }) => {

	const classes = useStyles();
	const { auth } = useContext(AuthContext);
	const [rows, setRows] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		async function getRowsPerPage() {
			const storedData = await localStorage.getItem("rowsPerPage");
			setRowsPerPage(storedData === null ? 10 : Number(storedData));
		}
		getRowsPerPage();
	}, []);

	useEffect(() => {
		const limitedRows = getLimitedRows([...users], rowsPerPage)
		setRows(limitedRows);
	}, [users]);

	const getRowsAccordingToPage = (page) => {
		let count = page * rowsPerPage;
		let _arr = [...users];
		let finalArr = _arr.slice(count, count + rowsPerPage);
		setRows(finalArr);
	};
	const handleChangePage = (event, page) => {
		setCurrentPage(page);
		getRowsAccordingToPage(page);
	};

	const handleChangeRowsPerPage = (event) => {
		setCurrentPage(0);
		setRowsPerPage(event.target.value);
		setRows(getLimitedRows([...users], event.target.value));
		localStorage.setItem('rowsPerPage', event.target.value);
	};

	const updateUser = (user) => {
		updateStatus(user);
	}

	return (
		<>
			<Paper className={classes.root}>
				<div className={classes.tableTitle}>
					<p className={classes.caption}
					>
						User List
					</p>
					<div onClick={addUser}>
						<p className={classes.expand}>Add User</p>
					</div>

				</div>
				<Table className={classes.table} aria-label="caption table">
					<TableHead>
						<TableRow>
							<StyledTableCell><strong>Name</strong></StyledTableCell>
							<StyledTableCell><strong>Email</strong></StyledTableCell>
							<StyledTableCell><strong>Role</strong></StyledTableCell>
							<StyledTableCell><strong>Status</strong></StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.map((user, index) => {
							return (
								<>
									<TableRow key={user.userId} className={classes.row}>
										<StyledTableCell>{user.name}</StyledTableCell>
										<StyledTableCell>{user.email}</StyledTableCell>
										<StyledTableCell>{user.role}</StyledTableCell>
										<StyledTableCell onClick={() => { updateUser(user) }}>
											{user.isActive ? <span className={classes.inactive}>
												inActive
											</span> : <span className={classes.active}>
													Active
												</span>}

										</StyledTableCell>
									</TableRow>
								</>
							)

						})}
					</TableBody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 20, 30]}
					component="div"
					count={users?.length}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					backIconButtonProps={{
						"aria-label": "Previous Page",
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page",
					}}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
				/>
			</Paper>

		</>

	)
}

export default UserList
