import React, { useEffect, useState } from "react";
import { makeStyles, Button, TextField, Grid, DialogContent, MenuItem, Box } from "@material-ui/core";
import FilterIcon from "../../assets/images/svgs/filter-icon";
import CloseIcon from '@material-ui/icons/Close';
import FDialog from "../atoms/Dialog";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';
import InputAdornment from "@material-ui/core/InputAdornment";
import Event from "@material-ui/icons/Event";
import moment from 'moment'
import { FILTERBY } from '../../Config'
import { getMenuItems } from "../../utils/methods";
import { fetchUsers } from "../../utils/methods";

const useStyles = makeStyles((theme) => ({
	buttonType: {
		background: "#FFD13B",
		color: "#1B154E",
		fontFamily: 'Montserrat, Medium',
		fontSize: 16,
		width: 100,
		borderRadius: 4,
		height: 30,
		fontWeight: '600'
	},
	textBox: {
		fontSize: 10,
		width: "100%",
		color: '#1B154E',
	},

	modalIcon: {
		cursor: "pointer",
		marginBottom: 5,
		//marginLeft: 275,
		"& svg": {
			fill: theme.palette.common.black,
			width: 18,
			height: 18,
			marginTop: 15,
		},
	},
	funnelIcon: {
		cursor: "pointer",
		marginBottom: 5,
		marginLeft: -10,
		"& svg": {
			fill: theme.palette.common.black,
			width: 18,
			height: 18,
		},
		boxType: {
			color: "#1B154E",
			fontFamily: 'Montserrat, Medium',
			fontSize: 16,
		}
	},
}));

const FilterData = (props) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [filterBy, setFilterBy] = useState(props.filterConfig && Object.keys(props.filterConfig).length ? props.filterConfig.filterBy : null);
	const [startDate, setStartDate] = useState(props.filterConfig && Object.keys(props.filterConfig).length ? props.filterConfig.startDate : null);
	const [endDate, setEndDate] = useState(props.filterConfig && Object.keys(props.filterConfig).length ? props.filterConfig.endDate : null);
	const [menu, setMenus] = useState()
	const [filterType, setFilterType] = useState(props.filterConfig && Object.keys(props.filterConfig).length ? props.filterConfig.filterType : null)
	const [aaaignItem, setAssignItem] = useState()

	useEffect(() => {
		setMenus(getMenuItems(filterBy))
		if (!(filterBy === 'leadSource' || filterBy === 'status' || filterBy === 'assignedTo' || filterBy === 'soldBy')) {
			setFilterType(null)
		}
	}, [filterBy])

	const handleReset = () => {
		props.handleClear();
		setFilterBy(null);
		setStartDate(null);
		setEndDate(null);

	};

	const handleApply = () => {
		let form = getForm()
		props.handleApply(form);
		handleClose();
	}

	const getForm = (props) => {
		let form = {};
		if (startDate !== null) form.startDate = startDate;
		if (endDate !== null) form.endDate = endDate;
		if (filterBy !== null) form.filterBy = filterBy;
		if (filterType !== null) form.filterType = filterType;
		return { ...form };
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	useEffect(() => {
		fetchUsers().then(assignTo => {
			if (assignTo) {
				setAssignItem(assignTo)
			}
		})
	}, []);

	return (
		<Grid>
			<div
				className={classes.funnelIcon}
				onClick={handleClickOpen}
				role="presentation"
			>
				<FilterIcon />
			</div>
			<FDialog
				open={open}
				onClose={handleClose}
				aria-labelledby="edit-apartment"
			>
				<Box
					display="flex"
					justifyContent="flex-end"
					className={classes.modalIcon}
					onClick={handleClose}
					role="presentation"
				>
					<Box mr={3} mb={-2}>
						<CloseIcon />
					</Box>
				</Box>
				<DialogContent className={classes.textBox}>
					<Box item xs sm>
						<TextField
							select
							autoFocus
							margin="dense"
							labelid="demo-simple-select-label"
							id="demo-simple-select"
							label={"Filter By"}
							value={filterBy}
							inputProps={{ style: { fontSize: 14 } }}
							InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
							onChange={(event) => {
								setFilterBy(event.target.value);
							}}
							fullWidth
						>
							{FILTERBY?.map((e, index) => {
								return <MenuItem key={index} value={e.value}>{e.label}</MenuItem>;
							})}
						</TextField>
					</Box>

					{['leadSource', 'status', 'soldBy'].includes(filterBy) &&
						<Box item xs sm>
							<TextField
								select
								autoFocus
								margin="dense"
								labelid="demo-simple-select-label"
								id="demo-simple-select"
								label={`Select ${FILTERBY.find((filter) => filter.value === filterBy).label}`}
								value={filterType}
								inputProps={{ style: { fontSize: 14 } }}
								InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
								onChange={(event) => {
									setFilterType(event.target.value);
								}}
								fullWidth
							>
								{menu?.map((e, index) => {
									return <MenuItem key={index} value={e.value}>{e.label}</MenuItem>;
								})}
							</TextField>
						</Box>
					}
					{['assignedTo'].includes(filterBy) &&
						<Box item xs sm>
							<TextField
								select
								autoFocus
								margin="dense"
								labelid="demo-simple-select-label"
								id="demo-simple-select"
								label={`Select ${FILTERBY.find((filter) => filter.value === filterBy).label}`}
								value={filterType}
								inputProps={{ style: { fontSize: 14 } }}
								InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
								onChange={(event) => {
									setFilterType(event.target.value);
								}}
								fullWidth
							>
								{aaaignItem?.map((e, index) => {
									return <MenuItem key={index} value={e.email}>{e.email}</MenuItem>;
								})}
							</TextField>
						</Box>
					}

					{filterBy && <>
						<Box mt={-2}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									format="dd/MM/yyyy"
									margin="normal"
									fullWidth
									autoOk
									//variant="inline"
									id="date-picker-dialog"
									label={"Start Date"}
									value={startDate || null}
									onChange={date => setStartDate(moment(date).format("YYYY-MM-DD"))}
									InputProps={{
										endAdornment: (
											<InputAdornment>
												<Event />
											</InputAdornment>
										)
									}}
									inputProps={{ style: { fontSize: 14 } }} // font size of input text
									InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
								/>
							</MuiPickersUtilsProvider>
						</Box>
						<Box className={classes.datePicker} mt={-2}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									format="dd/MM/yyyy"
									margin="normal"
									fullWidth
									autoOk
									id="date-picker-dialog"
									label={"End Date"}
									value={endDate || null}
									onChange={date => setEndDate(moment(date).format("YYYY-MM-DD"))}
									InputProps={{
										endAdornment: (
											<InputAdornment>
												<Event />
											</InputAdornment>
										)
									}}
									inputProps={{ style: { fontSize: 14 } }} // font size of input text
									InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
								/>
							</MuiPickersUtilsProvider>
						</Box>

					</>}



				</DialogContent>
				<Box display='flex' pb={3} pl={3} pr={3} justifyContent='space-between'>
					<Box>
						<Button onClick={handleReset} className={classes.buttonType}
							style={{ textTransform: 'none' }}
						>
							Reset
         				 </Button>
					</Box>
					<Box>
						<Button onClick={handleApply} className={classes.buttonType}
							style={{ textTransform: 'none' }}
						>
							Apply
          				</Button>
					</Box>
				</Box>
			</FDialog>
		</Grid>
	);
};

export default FilterData;
