import React from 'react'
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Event from "@material-ui/icons/Event";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
	root: {
		'& MuiInputBase-root': {
			fontSize: 14,
		},
		'& .MuiInputLabel-animated': {
			fontSize: 12,
			fontFamily: 'Montserrat, SemiBold',
			color: '#1B154E',
		},
		'& label + .MuiInput-formControl': {
			fontSize: 14,
			fontFamily: 'Montserrat, SemiBold',
			color: '#000000',
		},
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		top: -14,
		'& .MuiInput-underline.Mui-disabled:before':{
			borderBottomStyle: 'solid',
		}
	},
}));

const LeadDate = (props) => {
	const classes = useStyles();
	const { data, defaultValue, disabled} = props;
	const { label} = data;
	const [value] = React.useState(defaultValue ? moment(defaultValue).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY"));

	return (
		<>
			<TextField
				format="dd/MM/yyyy"
				margin="normal"
				autoOk	
				disabled={disabled}
				fullWidth
				id="date-picker-dialog"
				label={label}
				value={value}
				className={classes.textField}
				InputProps={{
					endAdornment: (
						<InputAdornment>
							<Event />
						</InputAdornment>
					)
				}}
				inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
				InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
				endAdornment={<InputAdornment position="end"></InputAdornment>}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>

		</>
	);
}
export default LeadDate;

