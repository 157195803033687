import { newLeadSms, inspectionScheduled, inspectionCancelled, inspectionRescheduled, inspectedSms, successSms, tokenDoneSms, fullPaymentSMS, holdbackConfirmationSMS, tranompleteSMS, holdbackRelease, lostSms, newRegisteredUser } from './constant';

export const sendMailToUsers = async (email, userName) => {
	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/sendMail?dest=${email}&userName=${userName}`;
	try {
		const requestOptions = {
			method: 'GET',
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
};

export const appointmentStatusMail = async (email, statusId, lName, time, date, payment, holdConfirmAmt, holdreleseAmt) => {
	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/appointmentStatusMail?dest=${email}&status=${statusId}&lastName=${lName}&time=${time}&date=${date}&payment=${payment}&
	holdConfirmAmt=${holdConfirmAmt}&holdreleseAmt=${holdreleseAmt}`;
	try {
		const requestOptions = {
			method: 'GET',
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}
export const registrationMail = async (email, lName) => {
	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/registrationMail?dest=${email}&lastName=${lName}`;
	try {
		const requestOptions = {
			method: 'GET',
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const createEvent = async (payload) => {
	const apiUrl =
		`https://api.interakt.ai/v1/public/track/events/`;
	try {
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Basic dTVHbUtMTG1YWTBpNXBXZm5Vemc4a0M0U1RsVkZucHVFUklrek9yLUE3NDo=',
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(payload),
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const mobileSMS = async (phone, statusId, data) => {
	let message = "";
	if (statusId === "new_lead") {
		message = newLeadSms(data.firstName ? data.firstName : "");
	}
	if (statusId === "inspection_scheduled") {
		message = inspectionScheduled(data.appointmentDate ? data.appointmentDate : "", data.appointmentTime ? data.appointmentTime : "", data.address ? data.address : "", data.make ? data.make : "Car", data.model ? data.model : "", data.firstName ? data.firstName : "");
	}
	if (statusId === "cancel") {
		message = inspectionCancelled(data.firstName ? data.firstName : "", data.make ? data.make : "Car", data.model ? data.model : "");
	}
	if (statusId === "inspection_rescheduled") {
		message = inspectionRescheduled(data.appointmentDate ? data.appointmentDate : "", data.appointmentTime ? data.appointmentTime : "", data.address ? data.address : "", data.make ? data.make : "Car", data.model ? data.model : "", data.firstName ? data.firstName : "");
	}
	if (statusId === "inspected") {
		message = inspectedSms(data.firstName ? data.firstName : "");
	}
	if (statusId === "success") {
		message = successSms(data.firstName ? data.firstName : "");
	}
	if (statusId === "token_done") {
		message = tokenDoneSms(data.firstName ? data.firstName : "");
	}
	if (statusId === "full_payment_done") {
		message = fullPaymentSMS(data.firstName ? data.firstName : "", data.make ? data.make : "Car", data.model ? data.model : "");
	}
	if (statusId === "holdback_confirmation") {
		message = holdbackConfirmationSMS(data.firstName ? data.firstName : "");
	}
	if (statusId === "transaction_complete") {
		message = tranompleteSMS(data.firstName ? data.firstName : "");
	}
	if (statusId === "holdback_release") {
		message = holdbackRelease(data.firstName ? data.firstName : "");
	}
	if (statusId === "lost") {
		message = lostSms(data.firstName ? data.firstName : "");
	}
	if (statusId === "newUserRegidtration") {
		message = newRegisteredUser();
	}

	const apiUrl = `https://us-central1-car-boli.cloudfunctions.net/sendTextMessage?phone=${phone}`;
	try {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ message: message })
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send sms', response);
		});
	} catch (e) {
		console.log(e);
	}
}

export const createUser = async (payload) => {
	const apiUrl =
		`https://api.interakt.ai/v1/public/track/users/`;
	try {
		const requestOptions = {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Basic dTVHbUtMTG1YWTBpNXBXZm5Vemc4a0M0U1RsVkZucHVFUklrek9yLUE3NDo=',
				'Content-Type': 'application/json'
			}),
			body: JSON.stringify(payload),
		};
		return await fetch(apiUrl, requestOptions).then((response) => {
			console.log('response send email', response);
		});
	} catch (e) {
		console.log(e);
	}
}

