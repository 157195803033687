import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

const StyledTableCell = withStyles((theme) => ({
	head: {
		background: "#1B154E 0% 0% no-repeat padding-box",
		color: colors.white,
		fontSize: 12,
		whiteSpace: "normal",
		wordWrap: "break-word",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		padding: 7,
		fontFamily: "Montserrat, SemiBold",
		textAlign: "left",
		font: "normal normal 600 13px/17px Montserrat",
		letterSpacing: 0.26,
		opacity: 1,
	},
	body: {
		padding: "5px 7px",
		fontFamily: "Montserrat, Medium",
		textAlign: "left",
		font: "normal normal medium 12px/16px Montserrat",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		letterSpacing: "0.12px",
		color: "#000000",
		opacity: 1,
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
	},
	table: {
		minWidth: 700,
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.background.default,
		},
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	caption: {
		textAlign: "left !important",
		captionSide: "top !important",
		fontSize: "18px !important",
		fontFamily: "Montserrat, Semibold !important",
		color: "#1B154E !important",
	},
	expand: {
		background: "#FFD13B",
		padding: '6px 24px 6px 9px',
		float: ' right',
		borderRadius: 2,
		cursor: 'pointer',
	},
	input: {
		width: 130,
		height: 40,
	},
	searchIcon: {
		bottom: 1,
		color: "#1B154E",
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		cursor: 'pointer',
		marginLeft: 5,
	},
	editIcon: {
		bottom: 1,
		color: "#fff",
		background: '#1B154E',
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		padding: 3,
		cursor: 'pointer',
	},
	noResult: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 25,
		marginBottom: 25,
		fontSize: 16,
		//color: colors.primary,
		fontFamily: "Montserrat, Regular",
	},
	expandList: {
		fontSize: 12,
		color: "#1B154E",
		fontFamily: "Montserrat, Regular",
	},
	tableTitle: {
		display: 'flex',
		alignItems: ' center',
		justifyContent: ' space-between',
		marginBottom: 10,
		marginTop: 10,

	},
	totalBar: {
		'& td': {
			paddingTop: 10,
			paddingBottom: 10,
			fontWeight:'bold',
			color:'#1B154E'
		}
	},
	priceBar: {
		padding: '12px 10px',
		background: '#1B154E',
		boxSizing: 'border-box',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& div': {
			display: 'flex',
			marginRight: '34%',
			alignItems: 'center',
		},
		'& span': {
			color: '#fff',
			textTransform: 'uppercase',
			fontSize: 13,
			letterSpacing: 3,
			fontWeight: 700,
			fontFamily: 'Montserrat, SemiBold',
		},
	},
	tableContainer: {
	}
}));

const PurchaseTabel = ({ purchaseRecords, handlePurchaseEdit, handleRemovePurchase, disable, totlePrice }) => {

	const classes = useStyles();

	return (
		<>
			<Table className={classes.table} aria-label="caption table">
				<TableHead>
					<TableRow>
						<StyledTableCell><strong>S.No</strong></StyledTableCell>
						<StyledTableCell><strong>Purchase Date</strong></StyledTableCell>
						<StyledTableCell><strong>Mode of Payment</strong></StyledTableCell>
						<StyledTableCell><strong>Purchase Price</strong></StyledTableCell>
						{disable ? <StyledTableCell><strong>Action</strong></StyledTableCell> : null}
					</TableRow>
				</TableHead>
				<TableBody>
					{purchaseRecords.map((purchaseRecords, index) => {
						return (
							<>
								<TableRow key={purchaseRecords.id}>
									<StyledTableCell>{index + 1}</StyledTableCell>
									<StyledTableCell>{purchaseRecords.purchaseDate}</StyledTableCell>
									<StyledTableCell>{purchaseRecords.modeOfPayment}</StyledTableCell>
									<StyledTableCell>{purchaseRecords.purchasePrice}</StyledTableCell>
									{disable ? <StyledTableCell>
										<EditIcon
											onClick={() => handlePurchaseEdit(purchaseRecords.id)}
											className={classes.editIcon}
										/>
										<DeleteOutlineIcon
											onClick={() => handleRemovePurchase(purchaseRecords.id)}
											className={classes.searchIcon}
										/>
									</StyledTableCell> : null}
								</TableRow>
							</>
						)

					})}
					<TableRow key={purchaseRecords.id} className={classes.totalBar}>
						<StyledTableCell>Total Purchase Price</StyledTableCell>
						<StyledTableCell />
						<StyledTableCell />
						<StyledTableCell>{totlePrice}</StyledTableCell>
						<StyledTableCell />
					</TableRow>

				</TableBody>
			</Table>
			{/* <div className={classes.totalBar}>
				<div className={classes.priceBar}>
					<span>Total Purchase Price</span>
					<div>
						<span>{totlePrice}</span>
					</div>
				</div>
			</div> */}
		</>

	)
}

export default PurchaseTabel
