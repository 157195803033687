import React, { useState, useEffect } from "react";
import {
	Button,
	Grid,
	TextField,
	makeStyles,
	IconButton,
	MenuItem,
	InputAdornment,
	Box,
} from "@material-ui/core";
import firebase from "../config/Firebase";
import { handleSnackBar, getMenuItems } from "../utils/methods";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AppLogo from "../assets/images/App-logo.png";
import Toaster from "../components/atoms/Toaster"
import { fetchUsers } from "../firebasedata/user";
import UserList from '../components/atoms/UserList';
import Loader from '../components/atoms/Loader';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		background: 'white',
		display: 'block',
		height: '95vh'
	},
	boxSize: {
		minWidth: 350,
		maxWidth: 600,
		borderRadius: 4,
		'@media(max-width: 500px)': {
			minWidth: 230
		},
	},
	fields: {
		padding: '3px 10px',
		marginTop: '2%'
	},
	tabel: {
		overflowX: 'auto'
	},
	loader: {
		width: '100%',
		height: '70vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const CreateUser = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [snackbarData, setSnackbarData] = useState({});
	const [displayName, setDisplayName] = useState("");
	const [role, setRole] = useState("lex");
	const [menuItems] = React.useState(getMenuItems("role") || []);
	const [showPassword, setShowPassword] = useState(false);
	const [userList, setUserList] = useState([]);
	const [showList, setShowList] = useState(true);
	const classes = useStyles();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getUserList();
	}, []);

	const getUserList = () => {
		setLoading(true);
		fetchUsers().then((user) => {
			if (user) {
				setLoading(false)
				const updateUser = user.filter((user) => (user.role !== 'admin'))
				setUserList(updateUser);
			}
		});
	}

	const onSubmit = () => {
		if (email !== "" && password.length > 5) {
			setLoading(true);
			firebase
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then((user) => {
					if (user) {
						firebase
							.database()
							.ref(`users/${user.user.uid}`)
							.set({
								...{ email: email, role: role, name: displayName, isActive: true },
							})
							.then((res) => {
								getUserList();
								setLoading(false);
								setSnackbarData(
									handleSnackBar("fire", "Created Successfully", "success")
								);
								clearForm();
								setShowList(true)
							})
							.catch((err) => { 
								setLoading(false);
							});
					}
				})
				.catch(function (error) {
					// Handle Errors here.
					setLoading(false);
					setSnackbarData(handleSnackBar("fire", error.message || "Error occured!", "error"));
					// ...
				});
		} else if (email === "")
			setSnackbarData(handleSnackBar("fire", "Enter Valid Email", "error"));
		else
			setSnackbarData(
				handleSnackBar(
					"fire",
					password === ""
						? "Enter Password"
						: "Password must contain minimum of 6 characters",
					"error"
				)
			);
	};
	const clearForm = () => {
		setEmail("");
		setPassword("");
		setDisplayName("");
		setRole("lex");
	};
	const updateStatus = (user) => {
		setLoading(true);
		let data = {
			email: user?.email,
			role: user?.role,
			name: user?.name,
			isActive: !(user?.isActive),
		};
		if (user?.userId) {
			firebase
				.database()
				.ref('users').child(user.userId)
				.set(data)
				.then((res) => {
					getUserList();
					setLoading(false);
					console.log('sucessfully');
				})
				.catch((err) => { 
					setLoading(false);
				 });
		}
	}

	const addUser = () => {
		setShowList(false);
	}

	return (
		<>{
			loading ?
				<div className={classes.loader} >
					<Loader />
				</div> :
				<>

					{
						showList ?
							<Box width="100%" p={2} mb={2} className={classes.tabel}>
								<UserList users={userList}
									updateStatus={updateStatus}
									addUser={addUser}
								/>
							</Box>
							:
							<>
								<Grid container className={classes.root}>
									<Grid item xs={12} className={classes.fields}>
										<img
											src={AppLogo}
											alt="Carboli App Logo"
										/>
									</Grid>
									<Grid item className={classes.fields}>
										<TextField
											className={classes.boxSize}
											required
											id="standard-adornment-password"
											variant="outlined"
											label="Email"
											type={"email"}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											endadornment={<InputAdornment position="end"></InputAdornment>}
										/>
									</Grid>
									<Grid item className={classes.fields}>
										<TextField
											className={classes.boxSize}
											id="standard-adornment-password"
											variant="outlined"
											label="Password"
											type={showPassword ? "text" : "password"}
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											endadornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={() => setShowPassword(!showPassword)}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
									</Grid>

									<Grid item className={classes.fields}>
										<TextField
											className={classes.boxSize}
											variant="outlined"
											label="Display Name"
											required
											id="standard-adornment-password"
											type={"text"}
											value={displayName}
											onChange={(e) => setDisplayName(e.target.value)}
											endadornment={<InputAdornment position="end"></InputAdornment>}
										/>
									</Grid>

									<Grid item className={classes.fields}>
										<TextField
											labelid="demo-simple-select-label"
											variant="outlined"
											label="Role"
											id="demo-simple-select"
											value={role}
											select
											className={classes.boxSize}
											onChange={(event) => setRole(event.target.value)}
										>
											{menuItems?.map((e, index) => {
												return <MenuItem key={index} value={e.value}>{e.label}</MenuItem>;
											})}
										</TextField>
									</Grid>
									<Grid item className={classes.fields}>
										<Box>
											<Button
												className={classes.boxSize}
												variant="outlined"
												color="primary"
												style={{ background: '#1B154E', color: 'white', marginTop: '2%' }}
												onClick={() => onSubmit()}
											>
												Create
            </Button>
										</Box>
										<Box>
											<Button
												className={classes.boxSize}
												variant="outlined"
												color="primary"
												style={{ background: '#1B154E', color: 'white', marginTop: '2%' }}
												onClick={() => { setShowList(true); }}
											>
												Cancel
            </Button>
										</Box>
									</Grid>
									{/* <Grid item className={classes.fields}>

							</Grid> */}
								</Grid>
							</>
					}
					<div>
						<Toaster
							snackbarData={snackbarData}
							revertSnackBarData={() => {
								setSnackbarData(handleSnackBar("hide"));
							}}
						/>
					</div>
				</>
		}
		</>

	);
};

export default CreateUser;
