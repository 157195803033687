import React, { useContext } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AuthContext } from '../contexts/AuthProvider';
import { useHistory } from 'react-router-dom';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from "@material-ui/core/styles";
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: 19
	}
}));
// const [isActive, setActive] = useState("false");
//   const ToggleClass = () => {
//     setActive(!isActive); 
//    };

export const MainListItems = () => {
	const classes = useStyles();

	const history = useHistory();
	const getcolor =(curr) =>{
		if(history.location.pathname===curr)
		{
			return "#1B154E"
		}
	}
	const { auth } = useContext(AuthContext);

	const handleNavigationClick = (link) => {
		history.push(`/${link}`);
	}

	return (
		<div>
			{auth !== null ?
				<>
					<ListItem button onClick={() => handleNavigationClick('dashboard') }  >
						<ListItemIcon>
						<DashboardIcon style={{color:getcolor('/dashboard')}}/>
						</ListItemIcon>
						<ListItemText><span className={classes.text}>DashBoard</span></ListItemText>
					</ListItem>
					<ListItem button onClick={() => handleNavigationClick('appointment')}  >
						<ListItemIcon >
							<AccessTimeIcon style={{color:getcolor('/appointment')}}/>
						</ListItemIcon>
						<ListItemText> <span className={classes.text}>Appointments</span></ListItemText>

					</ListItem>
					{auth.role === 'admin' && <ListItem button onClick={() => handleNavigationClick('create')}  >
						<ListItemIcon>
							<PersonIcon style={{color:getcolor('/create')}}/>
						</ListItemIcon>
						<ListItemText> <span className={classes.text}>Create User</span></ListItemText>

					</ListItem>}
				</>
				: null}
		</div>
	);
}

export const SecondaryListItems = () => {
	const { auth } = useContext(AuthContext);
	const history = useHistory();
	if (auth === null) {
		history.push('/')
	}
};