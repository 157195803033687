import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minHeight: 50,
    marginTop: 0,
	'& .MuiInput-underline.Mui-disabled:before':{
		borderBottomStyle: 'solid',
	}
  },
  resize: {
    fontSize: 12,
  },
}));

const CustomPhoneNumberInput = (props) => {
  const classes = useStyles();
  const { data, defaultValue, onKeyDown } = props;
  const { label, field, disabled } = data;
  const [value, setValue] = React.useState(defaultValue || "");

  const handleChange = (value) => {
    setValue(value);
    props.onChange(field, value);
  };
  if (field !== "leadID")
    return (
      <div className={classes.root}>
        <div>
          {/* <TextField
            display="flex"
            flexdirection="row"
            id="standard-full-width"
            fullWidth
            label={label}
            className={classes.textField}
            type={type}
			onWheel={() => document.activeElement.blur()}
            disabled={props.disabled || disabled}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            margin="normal"
            inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
            InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
            endAdornment={<InputAdornment position="end"></InputAdornment>}
          /> */}

          <NumberFormat
            customInput={TextField}
            display="flex"
            flexdirection="row"
            id="standard-full-width"
            fullWidth
            label={label}
            className={classes.textField}
            onWheel={() => document.activeElement.blur()}
            disabled={props.disabled || disabled}
            onChange={(e) => handleChange(e.target.value)}
            margin="normal"
            inputProps={{ style: { fontSize: 14, color: "#000000" } }} // font size of input text
            thousandSeparator={true}
            thousandsGroupStyle="lakh"
            value={value}
            InputLabelProps={{
              style: {
                fontSize: 14,
                color: "#1B154E",
                fontFamily: "Montserrat, SemiBold",
              },
            }}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
			onKeyDown={onKeyDown}
          />
        </div>
      </div>
    );
  else return <></>;
};
export default CustomPhoneNumberInput;
