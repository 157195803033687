import { createEvent } from './sendMail'

export const getWhatsappMessage = (data, regWhatsappNo) => {
	if (data.status === "inspection_rescheduled") {
		return createEvent(inspectionReschedule(data?.mobile, data?.firstName ? data?.firstName : "", data?.make ? data?.make : "Car", data?.model ? data?.model : "", data?.address ? data?.address : "", data?.appointmentTime ? data?.appointmentTime : "", data?.appointmentDate ? data?.appointmentDate : ""));
	}
	if (data.status === "cancel") {
		return createEvent(cancel(data?.mobile, data?.firstName ? data?.firstName : "", data?.make ? data?.make : "Car", data?.model ? data?.model : ""));
	}
	if (data.status === "full_payment_done") {
		return createEvent(fullPayment(data?.mobile, data?.firstName ? data?.firstName : "", data?.make ? data?.make : "Car", data?.model ? data?.model : ""));
	}
	if (data.status === "reassurance_of_price") {
		createEvent(reassurePrice(data?.mobile, data?.firstName ? data?.firstName : "", data?.make ? data?.make : "Car", data?.model ? data?.model : ""));
	}
	if (data.status === "inspection_scheduled") {
		return createEvent(inspectionSchedule(data?.mobile, data?.firstName ? data?.firstName : "", data?.make ? data?.make : "Car", data?.model ? data?.model : "", data?.address ? data?.address : "", data?.appointmentTime ? data?.appointmentTime : "", data?.appointmentDate ? data?.appointmentDate : ""));
	}
	if (data.status === "new_lead") {
		return createEvent(newLead(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "inspected") {
		return createEvent(inspected(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "success") {
		return createEvent(priceAccepcted(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "token_done") {
		return createEvent(tokenDone(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "transaction_complete") {
		return createEvent(transCompelete(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "holdback_confirmation") {
		return createEvent(holdConfirmation(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "holdback_release") {
		return createEvent(holdRelese(data?.mobile, data?.firstName ? data?.firstName : ""));
	}
	if (data.status === "lost") {
		return createEvent(priceNotAccept(data?.mobile, data?.firstName ? data?.firstName : ""))
	}
}

export const newRgistration = () => {
	let payload = {
		"userId": "mobile",
		"phoneNumber": "mobile",
		"countryCode": "+91",
		"event": "User Registration",
		"traits": {
			"status": "user_reg"
		}
	}
	return payload;
}


export const inspectionReschedule = (mobile, name, brand, model, location, time, date) => {
	let payload = {
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Inspection Reschedule",
		"traits": {
			"status": "ins_resch",
			"name": name,
			"car": `${brand} ${model}`,
			"location": location,
			"time": `${date} ${time}`,
			"contact": "+919795097250"
		}
	};
	return payload;
}

export const newLead = (mobile, name) => {
	let payload = {
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Lead Generation",
		"traits": {
			"status": "new_lead",
			"name": name
		}
	}
	return payload;
}


export const inspectionSchedule = (mobile, name, brand, model, location, time, date) => {
	let payload = {
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Inspection Scheduled",
		"traits": {
			"status": "ins_sch",
			"name": name,
			"car": `${brand} ${model}`,
			"location": location,
			"time": `${date} ${time}`,
			"contact": "+919795097250"
		}
	}
	return payload;

}

export const cancel = (mobile, name, brand, model) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Inspection Cancel",
		"traits": {
			"status": "ins_cancelled",
			"name": name,
			"contact": "+919795097250",
			"car": `${brand} ${model}`
		}
	}
	return payload;

}

export const inspected = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Inspected",
		"traits": {
			"status": "inspected",
			"name": name,
			"contact": "+919795097250"
		}
	}
	return payload;

}

export const priceAccepcted = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Price Accepted",
		"traits": {
			"status": "price_accepted",
			"name": name,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const tokenDone = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Token Done",
		"traits": {
			"status": "token_done",
			"name": name,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const fullPayment = (mobile, name, brand, model) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Full Payment",
		"traits": {
			"status": "full_payment",
			"name": name,
			"car": `${brand} ${model}`,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const holdConfirmation = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Holdback Confirmation",
		"traits": {
			"status": "holdback",
			"name": name,
			"amount": 700000,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const transCompelete = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Transaction Complete",
		"traits": {
			"status": "trans_complete",
			"name": name,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const holdRelese = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Holdback Release",
		"traits": {
			"status": "hold_release",
			"name": name,
			"amount": 80000,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const priceNotAccept = (mobile, name) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Price Not Accepted",
		"traits": {
			"status": "price_not_accept",
			"name": name,
			"contact": "+919795097250"
		}
	}
	return payload;
}

export const reassurePrice = (mobile, name, brand, model) => {
	let payload =
	{
		"userId": mobile,
		"phoneNumber": mobile,
		"countryCode": "+91",
		"event": "Reassurance Of Price",
		"traits": {
			"status": "reassurance_price",
			"name": name,
			"contact": "+919795097250",
			"car": `${brand} ${model}`
		}
	}
	return payload;
}



// sms temp 

export const inspectionScheduled = (date, time, address, make, model, fname) => {
	const message = `Hi ${fname},
The evaluation of your ${make} ${model}  has been confirmed 
Date & Time : ${date} & ${time}

Location : ${address}

Kindly keep the following documents ready for a seamless evaluation:

1. RC/Registration Certificate
2. Car Insurance
3. PUC or Pollution Certificate
4. Duplicate Key (If Available)
5. Warranty, service records, and invoices (where available)

You can also manage (book, amend or cancel) your appointments through our Mobile Application or the Website.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`;
	return message;
};

export const newLeadSms = (fname) => {
	const message = `Hi ${fname},
Greetings from Team Carboli! 
Thank you for reaching out to us. Our car consultant will contact you shortly to schedule a free inspection of your car at a date & time of your convenience. 

You may also use our Mobile Application or Website to book the inspection in just 6 easy steps by clicking the button below.

We look forward to selling your car instantly at the best price, from the comfort of your home.

Thank You,
Team CarBoli

https://carboli.com/

https://play.google.com/store/apps/details?id=com.carboli_application`;
	return message;
};

export const inspectionCancelled = (fname, make, model) => {
	const message = `Hi ${fname},

The evaluation of your ${make} ${model} has been cancelled.

To reschedule the evaluation to another time, click on the link below or contact us at 📞 9795097250 .

Thank You,
Team CarBoli

https://carboli.com/

https://play.google.com/store/apps/details?id=com.carboli_application`;
	return message;
};

export const inspectionRescheduled = (date, time, address, make, model, fname) => {
	const message = `Hi ${fname},

The evaluation of your ${make} ${model} has been rescheduled.

Date & Time : ${date} & ${time}
Location : ${address}

Kindly keep the following documents ready for a seamless evaluation:
1. RC/Registration Certificate
2. Car Insurance
3. PUC or Pollution Certificate
4. Duplicate Key (If Available)
5. warranty, service records, and invoices (where available)

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`;
	return message;
};

export const inspectedSms = (fname) => {
	const message = `Hi ${fname},

Congratulations, your car has successfully cleared our evaluation and is ready to be sold. 

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`;
	return message;
};

export const successSms = (fname) => {
	const message = `Hi ${fname},

We are glad to have you on board! 

We are just one step away from #DealPakki. Please refer to our email for all the details and share your acceptance to sell your car at the best price.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const tokenDoneSms = (fname) => {
	const message = `Hi ${fname},

We are almost through to #DealPakki! 

A token of INR 1000/- has been transferred into your bank account. The remaining amount will be transferred once the documents have been verified and the car has been picked up.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const fullPaymentSMS = (fname, make, model) => {
	const message = `Hi ${fname},

Congratulations! The full and final payment for your ${make} ${model} has been done.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const holdbackConfirmationSMS = (fname) => {
	const message = `Hi ${fname},

An amount of INR ** has been kept on hold and will be credited to your bank account on fulfillment on pending requirements. Please refer to your email for more details or contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const tranompleteSMS = (fname) => {
	const message = `Hi ${fname},

It was our pleasure to assist you in selling your car instantly at the best price, from the comfort of your home. The transaction has been marked complete. 

Please note that we might contact you again for assistance in RC Transfer.
For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const holdbackRelease = (fname) => {
	const message = `Hi ${fname},

The holdback amount of INR ** has been released and transferred to your bank account successfully.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const lostSms = (fname) => {
	const message = `Hi ${fname},

We regret that we were unable to match your expectation. 

At CarBoli, we always strive to serve you better. Rest assured; nothing makes us happier than saying #DealPakki. We are trying our best to buy your car #AtYourRate.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const reassuranceOfPrice = (fname, make, model) => {
	const message = `Hi ${fname},

Trust us, we will still get the best price for your ${make} ${model}.

For any queries, contact us at 📞 9795097250

Thank You,
Team CarBoli`
	return message;
}

export const newRegisteredUser = () => {
	const message = `Hi,

Welcome to CarBoli and thank you for registering with us. With CarBoli get the best deal for your car and the convenience of being at your home. We are eagerly waiting to serve you!!

Thanks,

Team CarBoli`
	return message;
}