import React, { useEffect, useState } from "react";
import { makeStyles, Button, TextField, Grid, DialogContent, MenuItem, Box } from "@material-ui/core";
import FilterIcon from "../../assets/images/svgs/filter-icon";
import CloseIcon from '@material-ui/icons/Close';
import CDialog from "../atoms/CDialog";
import { getCarData } from "../../utils/methods";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';
import InputAdornment from "@material-ui/core/InputAdornment";
import Event from "@material-ui/icons/Event";
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& .MuiDialog-container': {
			float: 'right',
		},
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		float: 'right',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	buttonType: {
		background: "#FFD13B",
		fontWeight: '600',
		borderRadius: 4,
		color: "#1B154E",
		fontFamily: 'Montserrat, Medium',
		fontSize: 16,
		width: 100,
		height: 30,
	},
	textBox: {
		fontSize: 10,
		width: "100%",
		color: '#1B154E',
	},

	modalIcon: {
		cursor: "pointer",
		marginBottom: 5,
		//marginLeft: 275,
		"& svg": {
			fill: theme.palette.common.black,
			width: 18,
			height: 18,
			marginTop: 15,
		},
		textField: {
			width: "100%",
			marginLeft: 20,
		},
		buttonStyle: {
			background: "#FFD13B",
		},
		newTest: {
			top: 95,
			left: 500,
			width: 323,
			height: 408,
		},
	},
	funnelIcon: {
		cursor: "pointer",
		marginBottom: 5,
		marginLeft: -10,
		"& svg": {
			fill: theme.palette.common.black,
			width: 18,
			height: 18,
		},
		textField: {
			width: 200,
			marginLeft: 20,
		},
		buttonStyle: {
			background: "#FFD13B",
		},
		newTest: {
			top: 95,
			left: 500,
			width: 323,
			height: 408,
		},
		boxType: {
			color: "#1B154E",
			fontFamily: 'Montserrat, Medium',
			fontSize: 16,
		}
	},
}));

const FilterAppointment = (props) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [initial, setInitial] = useState(null);
	const [final, setFinal] = useState(null);
	const [brands, setBrands] = useState([])
	const [carRegistrationNumber, setCarRegistrationNumber] = useState("");
	const [leadId, setLeadId] = useState("");
	const [brand, setBrand] = useState("" || []);

	useEffect(() => {
		getCarData().then(brand => {
			setBrands(brand)
		}).catch((err) => {
			console.log(err)
		})
	}, [])

	const handleReset = () => {
		props.handelClear()
		setInitial(null);
		setFinal(null);
		setCarRegistrationNumber("");
		setLeadId("");
		setBrand([]);
	};

	const handleApply = () => {
		let form = getForm();
		props.handleApply(form);
		handleClose()
	};

	const getForm = (props) => {
		let form = {};
		form.initial = initial ? initial : null;
		form.final = final ? final : null;
		form.carRegistrationNumber = carRegistrationNumber ? carRegistrationNumber : "";
		form.leadId = leadId ? leadId : "";
		form.brand = brand ? brand : "";
		return { ...form };
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const brandArray = brands && Object.keys(brands)

	return (
		<Grid className={classes.newTest}>
			<div
				className={classes.funnelIcon}
				onClick={handleClickOpen}
				role="presentation"
			>
				<FilterIcon />
			</div>
			<CDialog
				open={open}
				onClose={handleClose}
				aria-labelledby="edit-apartment"
			>
				<Box

					display="flex"
					justifyContent="flex-end"
					className={classes.modalIcon}
					onClick={handleClose}
					role="presentation"
				>
					<Box mr={3} mb={-2}>
						<CloseIcon />
					</Box>
				</Box>
				<DialogContent className={classes.textBox}>
					{(!["dailyFollowUp", "dailyInspection", "daily"].includes(props.usedBy)) &&
						<>
							<Grid item xs sm>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										format="dd/MM/yyyy"
										margin="normal"
										fullWidth
										autoOk
										//variant="inline"
										id="date-picker-dialog"
										label={"Choose Date Range"}
										value={initial || null}
										onChange={date => setInitial(moment(date).format("YYYY-MM-DD"))}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<Event />
												</InputAdornment>
											)
										}}
										inputProps={{ style: { fontSize: 14 } }} // font size of input text
										InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Box className={classes.datePicker} mt={-2}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<DatePicker
										format="dd/MM/yyyy"
										margin="normal"
										fullWidth
										autoOk
										id="date-picker-dialog"
										label={"To"}
										value={final || null}
										onChange={date => setFinal(moment(date).format("YYYY-MM-DD"))}
										InputProps={{
											endAdornment: (
												<InputAdornment>
													<Event />
												</InputAdornment>
											)
										}}
										inputProps={{ style: { fontSize: 14 } }} // font size of input text
										InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
									/>
								</MuiPickersUtilsProvider>
							</Box>
						</>
					}
					<Grid item xs sm>
						<TextField
							autoFocus
							margin="dense"
							id="registrationNumber"
							label={"Car Registration No."}
							type="text"
							value={carRegistrationNumber}
							placeholder={"Please dont use any seperator"}
							onChange={(e) => setCarRegistrationNumber(e.target.value)}
							inputProps={{ style: { fontSize: 14 } }} // font size of input text
							InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
							fullWidth
						/>
					</Grid>
					<Grid item xs sm>
						<TextField
							autoFocus
							margin="dense"
							id="leadId"
							label={"Lead Id"}
							type="text"
							value={leadId}
							placeholder={"Please dont use any seperator"}
							onChange={(e) => setLeadId(e.target.value)}
							inputProps={{ style: { fontSize: 14 } }} // font size of input text
							InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
							fullWidth
						/>
					</Grid>
					<Grid item xs sm>
						<TextField
							select
							autoFocus
							margin="dense"
							labelid="demo-simple-select-label"
							id="demo-simple-select"
							label={"Brand"}
							value={brand}
							inputProps={{ style: { fontSize: 14 } }}
							InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }}
							onChange={(event) => {
								setBrand(event.target.value);
							}}
							fullWidth
						>
							{brandArray?.map((e, index) => {
								return <MenuItem key={index} value={brands[e].name}>{brands[e].name}</MenuItem>;
							})}
						</TextField>
					</Grid>
				</DialogContent>
				<Box display='flex' pb={3} pl={3} pr={3} justifyContent='space-between'>
					<Box>
						<Button onClick={handleReset} className={classes.buttonType}
							style={{ textTransform: 'none' }}
						>
							Reset
         				 </Button>
					</Box>
					<Box>
						<Button onClick={handleApply} className={classes.buttonType}
							style={{ textTransform: 'none' }}
						>
							Apply
          				</Button>
					</Box>
				</Box>
			</CDialog>
		</Grid>
	);
};

export default FilterAppointment;
