/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from "react";
import {
	Typography, Button, makeStyles, Grid, Box, TextField, MenuItem, FormControl, Checkbox
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	DatePicker
} from '@material-ui/pickers';
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";
import TodayIcon from '@material-ui/icons/Today';
import AddIcon from '@material-ui/icons/Add';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Event from "@material-ui/icons/Event";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
	getFormValues,
	getAppointmentFields,
	getLeadId,
	handleSnackBar,
	getWhatsappUsers
} from "../../utils/methods";
import { appointmentStatusMail, registrationMail, mobileSMS, createEvent, createUser } from '../../utils/sendMail'
import { getVariantData } from '../../utils/methods'
import firebase from "../../config/Firebase";
import { AuthContext } from "../../contexts/AuthProvider";
import { getCarData } from "../../utils/methods";
import { MODE_OF_PAYMENT } from '../../Config';
import { getWhatsappMessage } from '../../utils/constant';

import AppointmentDate from "../atoms/AppointmentDate"
import Toaster from "../atoms/Toaster";
import CustomInput from "../atoms/CustomInput";
import CustomSelect from "../atoms/CustomSelect";
import RegistrationNumber from "../atoms/CustomRegistrationNo"
import CustomDatePicker from "../atoms/DatePicker";
import CustomTimePickers from "../atoms/CustomTimePicker";
import CustomSelectSwitch from "../atoms/CustomSelectSwitch";
import ChallanTabel from "../atoms/ChallanTabel";
import PurchaseTabel from "../atoms/purchaseTabel";
import CustomYearSelect from '../atoms/CustomYearSelect';
import CustomVariantSelect from '../atoms/CustomVariantSelect'
import CustomPhoneNumberInput from "../atoms/CustomPhoneNumberInput"
import CustomMakeSelect from "../atoms/CustomMakeSelect"
import CustomModelSelect from "../atoms/customModelSelect"
import LeadDate from "../atoms/LeadDate"
import HoldingPeriod from "../atoms/HoldingPeriod"
import CommentTable from '../atoms/CommentTabel';
import CustomNumberInput from '../atoms/CustomNumberInput';
import CustomLeadId from '../atoms/CustomLeadId'
import { fetchUsers } from "../../utils/methods";
import AssignToSelect from "../atoms/AssignToSelect";
import LocationSelector from '../atoms/LocationSelector';
import Loader from '../atoms/Loader';


const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		marginTop: 0,
		minWidth: 150,
		width: "100%",
		'& .MuiFormHelperText-root': {
			color: 'red'
		},
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'solid',
		}
	},
	backToLeads: {
		display: 'flex',
		background: 'white',
		flex: 1
	},
	button: {
		fontSize: 36,
		color: '#1B154E',
		marginLeft: 8,
		cursor: 'pointer',
		'& span': {
			paddingTop: 1,
			paddingLeft: 3
		},
	},
	table: {
		minWidth: 650,
	},
	selectTableCell: {
		width: 60,
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	caption: {
		textAlign: "center !important",
		captionSide: "top !important",
	},
	input: {
		width: 130,
		height: 40,
	},
	headTitle: {
		color: "#1B154E",
		marginLeft: 2,
		//marginBottom: 20,
		fontSize: 18,
		fontWeight: 600,
		fontFamily: 'Montserrat, SemiBold',
	},
	challanTitle: {
		color: "#1B154E",
		marginTop: 20,
		marginLeft: 17,
		marginBottom: 20,
		fontSize: 16,
		fontFamily: 'Montserrat, Medium',
	},
	addMoreBox: {
		flexWrap: "wrap",
		marginLeft: -4,
		marginBottom: 10
	},
	buttonSize: {
		background: "#1B154E",
		width: 230,
		padding: '10px 60px',
		fontFamily: "Montserrat, Regular",
		marginBottom: 40,
		color: "#ffffff",
		"&:hover": {
			backgroundColor: "#1B154E",
		},
		//background: '#1B154E 0% 0% no-repeat padding-box',
		border: '1px solid #1B154E',
		font: 'normal normal medium 26px/35px Montserrat',
	},
	CarDetails: {
		width: "100%",
		float: "left",
	},
	dividerLIne: {
		width: "100%",
		background: "#707070",
		height: 1,
		opacity: 0.5,
	},
	addMoreButton: {
		float: 'right',
		background: '#FFD13B',
		width: 105,
		height: 35,
		fontWeight: '500',
		right: 15,
		boxShadow: '0px 5px 25px #52575d05',
		borderRadius: 2,
		color: '#1B154E',
		fontFamily: "Montserrat, Regular",
		font: 'normal normal normal 16px/21px Montserrat',
		letterSpacing: 0,
		textAlign: 'left',
		//marginTop: -50,
		"&:hover": {
			backgroundColor: "#FFD13B",
		},
	},
	saveButton: {
		marginTop: 30
	},
	fields: {
		padding: '3px 10px',
		paddingRight: '24px'
	},
	commentBox: {
		paddingLeft: 8,
		paddingRight: 8,
		width: "100%",
		float: "left",
	},
	commentTabel: {
		overflowX: 'auto'
	},
	commentField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		minHeight: 50,
		marginTop: 0,
		width: "100%",
		'& .MuiFormHelperText-root': {
			color: 'red'
		},
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'solid',
		},
		'@media(max-width: 400px)': {
			width: "85%"
		},
	},
	addMoreCommentButton: {
		float: 'right',
		background: '#FFD13B',
		width: 105,
		height: 35,
		right: -18,
		fontWeight: 500,
		boxShadow: '0px 5px 25px #52575d05',
		borderRadius: 2,
		color: '#1B154E',
		fontFamily: "Montserrat, Regular",
		font: 'normal normal normal 16px/21px Montserrat',
		letterSpacing: 0,
		textAlign: 'left',
		"&:hover": {
			backgroundColor: "#FFD13B",
		},
		marginTop: 8
	},
	challanDetail: {
		float: 'left',
		width: '100%',
		paddingLeft: 12
	},
	formControl: {
		margin: theme.spacing(1),
		marginLeft: '0px',
		width: '100%',
		'@media (max-width: 767px)': {
			marginRight: 0,
		},
	},
	container: {
		display: 'flex',
		width: '100%',
		'& label': {
			color: '#fff',
			letterSpacing: 2,
			backgroundColor: '#1B154E',
		},
	},
	fileLabel: {
		width: '67%',
		marginTop: 5,
		marginRight: '2%',
		cursor: 'not-allowed',
		'& .MuiInput-underline.Mui-disabled:before': {
			borderBottomStyle: 'solid',
		}
	},
	addIcon: {
		marginTop: 20,
		'& .MuiInputAdornment-root': {
			cursor: 'pointer',
		},
		'& .MuiSvgIcon-root': {
			cursor: 'pointer',
			color: '#1B154E',
			"&:hover": {
				Color: "#FFD13B",
			},
		}
	},
	checkBox: {
		marginTop: -8,
	},
}));
const ManageAppointment = (props) => {
	const location = useLocation();
	const { auth } = useContext(AuthContext);
	const [form, setForm] = React.useState({});
	const [fields, setFields] = React.useState([]);
	const [loading, setLoading] = useState(false);
	// const [disabled, setDisabled] = React.useState(
	// 	location?.state?.disabled || false
	// );

	const [whatsChecked, setWhatsChecked] = useState(true);
	const [status, setStatus] = useState(false)
	const [commentId, setCommentId] = useState(-1)
	const [assignTo, setAssignTo] = useState()
	const [leadId, setLeadid] = useState("")
	const [challanId, setChallanId] = useState(-1)
	const [purchaseId, setPurchaseId] = useState(-1)
	const [brands, setBrands] = useState()
	const [snackbarData, setSnackbarData] = useState({});
	const [selectedBrandList, setSelectedBrandList] = useState()
	const [selectedYearList, setSelectedYearList] = useState()
	const [variantList, setVariantList] = useState()
	const [variant, setVariant] = useState([])
	const [records, setRecords] = useState([])
	const [challanRecords, setChallanRecords] = useState([])
	const [purchaseRecords, setPurchaseRecords] = useState([])
	const [edit, setEdit] = useState(false)
	const [diffDays, setDiffDays] = useState("")
	const [editChallan, setEditChallan] = useState(false)
	const [editPurchasePrice, setEditPurchasePrice] = useState(false)
	const [trafficChallans, setTrafficChallans] = useState({
		"delhiTrafficChallan": "",
		"vahanParivahanChallan": "",
		"challanHoldDeduction": "",
		"challanHoldReleaseDate": "",
		"hypothecation": "",
		"blackListedDetails": "",
		"nocHoldAmt": "",
		"nocHoldReleaseDate": "",
		"bankName": "",
		"challanFileName": "",
		"challanFile": "",
		"loanAmount": "",
		"nocHoldDeduction": ""
	})
	const [tempStatusValue, setTempStatusValue] = useState();
	// const [challanErrorMsg, setChallanErrorMsg] = useState();

	const [commentErrorMsg, setCommentError] = useState();
	const [profit, setProfit] = useState();
	const [fileContent, setFileContent] = useState();
	const [priceError, setPriceError] = useState('');
	const [comments, setComments] = useState({
		"comment": "",
	})
	const [purchaseDetail, setPurchaseDetail] = useState({
		"purchaseDate": "",
		"modeOfPayment": "",
		"purchasePrice": "",
	})
	const deduction = [
		{ value: 'yes', label: 'Yes' },
		{ value: 'no', label: 'No' },
	];
	const deductions = [
		{ value: 'hold', label: 'Hold' },
		{ value: 'deduction', label: 'Deduction' },
		{ value: 'release', label: 'Release' },

	];
	const nocdeductions = [
		{ value: 'hold', label: 'Hold' },
		{ value: 'release', label: 'Release' },
	];
	const [tempStatus, setTempStatus] = useState("")

	const [isFormEdit, setIsFormEdit] = useState(false);
	const [AppointmentId, setAppointmentId] = useState();
	const [, setPreviousStatus] = useState();
	const [, setRegWhatsappNo] = useState("");

	const ref = useRef();

	const usePrevious = (value) => {
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	};
	const prevCount = usePrevious(form["status"]);
	useEffect(() => {
		setPreviousStatus(prevCount);
	}, [form["status"]]);

	useEffect(() => {
		getVariantData().then(variantList => {
			if (variantList) {
				setVariant(variantList)
			}
		})
	}, [])

	useEffect(() => {
		getCarData().then(brand => {
			setBrands(brand)
		})
	}, [])

	useEffect(() => {
		setFields(getAppointmentFields());
		const data = location?.state?.row
			? getFormValues("appointments", location.state.row)
			: getFormValues("appointments")
		setTempStatus(data.status);
		setForm(data);

		getWhatsappUsers(data?.mobile).then(data => {
			if (data) {
				setRegWhatsappNo(data);
			}
		})

		const isChallanExists = location.state.row && location.state.row.challan && location.state.row.challan.length > 0
		isChallanExists && setChallanRecords(location.state.row.challan)

		const isPurchaseDetailsExists = location.state.row && location.state.row.purchaseDetails && location.state.row.purchaseDetails.length > 0
		isPurchaseDetailsExists && setPurchaseRecords(location.state.row.purchaseDetails)

		const isCommentExist = location.state.row && location.state.row.comment && location.state.row.comment.length > 0
		isCommentExist && setRecords(location.state.row.comment)

		const isBrandExist = location.state.row && location.state.row.make
		const isModelExist = location?.state?.row?.model

		const isBrand = isBrandExist && isBrandExist.toLowerCase()
		const isModel = brands && brands[isBrand] && brands[isBrand].cars
		isModel && setSelectedBrandList(isModel)
		const years = isModel && isModel[isModelExist.toLowerCase()] && isModel[isModelExist.toLowerCase()].years
		setSelectedYearList(years)
		// years && setForm({...form, year:isYearExist})
		const isVariantList = isModel && isModel[isModelExist.toLowerCase()]
		setVariantList(isVariantList)
	}, [brands]); // eslint-disable-line react-hooks/exhaustive-deps
	const classes = useStyles();
	const history = useHistory();

	const handleChange = (
		name,
		value,
		type = null,
		firstLabel = null,
		secondLabel = null
	) => {
		if (type) {
			if (value) setForm({ ...form, [name]: secondLabel });
			else setForm({ ...form, [name]: firstLabel });
		} else {
			if (value) {
				setForm({ ...form, [name]: value })
			};
		}
	};
	const handleLocationchange = (address) => {
		setForm({ ...form, address: address })
	}

	const handlePurchasePriceChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setPurchaseDetail({ ...purchaseDetail, [name]: value })
	}
	const handlePuechasePriceDate = (date) => {
		let ReleaseDate = moment(date).format("YYYY-MM-DD")
		setPurchaseDetail({ ...purchaseDetail, "purchaseDate": ReleaseDate });
	}

	const handelAddPurchaseDetails = () => {
		if (!formError(getPurchasePrice() + Number(purchaseDetail?.purchasePrice?.replace(/,/g, '')))) {
			return;
		}
		if (purchaseId === -1) {
			const newRec = {
				...purchaseDetail, id: Math.floor(Math.random() * 1000),
			}
			const userRecord = [...purchaseRecords, newRec]
			setPurchaseRecords(userRecord);
		}
		else {
			const updateRecord = { ...purchaseDetail }
			const updateData = purchaseRecords.map(item => (item.id === updateRecord.id ? updateRecord : item))
			setPurchaseRecords(updateData)
			setPurchaseId(-1)
		}
		setPurchaseDetail({
			"purchaseDate": "",
			"modeOfPayment": "",
			"purchasePrice": "",
		})
		setEditPurchasePrice(false)
	}

	const handlePurchaseDetailEdit = id => {
		const selectitem = purchaseRecords.find(item => item.id === id);
		setEditPurchasePrice(true)
		setPurchaseDetail(selectitem)
		const index = purchaseRecords.indexOf(selectitem)
		setPurchaseId(index)
	}

	const handleRemoveDetail = id => {
		const removedRecords = purchaseRecords.filter(record => record.id !== id);
		setPurchaseRecords(removedRecords)
	}

	// ------------------- Comment Section Start -------------------

	const handleCommentChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setComments({ ...comments, [name]: value })
	}
	const commentError = () => {
		if ((comments.comment == null || /^\s*$/.test(comments.comment))) {
			setCommentError('Please enter comment')
			return false;
		}
		setCommentError('');
		return true;
	}
	const handleAddComment = () => {
		if (!commentError()) {
			return;
		}
		if (commentId === -1) {
			const newRec = {
				...comments, id: Math.floor(Math.random() * 1000),
				time: moment().format("hh:mm A dddd MMM DD, YYYY"),
				role: auth?.role,
				name: auth?.name,
				status: form.status
			}
			const userRecord = [...records, newRec]
			setRecords(userRecord);
		}
		else {
			const updateRecord = {
				...comments,
				time: moment().format("hh:mm A dddd MMM DD, YYYY"),
				role: auth?.role,
				name: auth?.name,
				status: form.status
			}
			const updateData = records.map(item => (item.id === updateRecord.id ? updateRecord : item))
			setRecords(updateData)
			setCommentId(-1)
		}
		setComments({ comment: "" })
		setEdit(false)
	}
	const handleEdit = id => {
		const selectitem = records.find(item => item.id === id);
		setEdit(true)
		setComments(selectitem)
		const index = records.indexOf(selectitem)
		setCommentId(index)
	}
	const handleRemoveComment = id => {
		const removedRecords = records.filter(record => record.id !== id);
		setRecords(removedRecords)
	}

	// ------------------------- Comment Section End ---------------------

	// ------------------------- Challan Section Start ----------------------

	const fileToDataUri = (file) => new Promise((resolve) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			resolve(event.target.result);
		};
		reader.readAsDataURL(file);
	});

	const onFileUpload = (name, file) => {
		setTrafficChallans({ ...trafficChallans, challanFileName: file.name });
		fileToDataUri(file)
			.then((dataUri) => {
				setFileContent(dataUri);
			});
	}

	const handleInputChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setTrafficChallans({ ...trafficChallans, [name]: value });
	};
	// const challanError = () => {
	// 	if ((trafficChallans.delhiTrafficChallan == null || /^\s*$/.test(trafficChallans.delhiTrafficChallan))) {
	// 		setChallanErrorMsg('This Field is Required !')
	// 		return false;
	// 	}
	// 	setChallanErrorMsg('');
	// 	return true;
	// }
	const handleHoldReleaseDate = (date) => {
		let ReleaseDate = moment(date).format("YYYY-MM-DD")
		setTrafficChallans({ ...trafficChallans, "challanHoldReleaseDate": ReleaseDate });
	}
	const nocHoldReleaseDate = (date) => {
		let ReleaseDate = moment(date).format("YYYY-MM-DD")
		setTrafficChallans({ ...trafficChallans, "nocHoldReleaseDate": ReleaseDate });
	}
	const handleAddClick = () => {
		// if (!challanError()) {
		// 	return;
		// }
		if (challanId === -1) {
			const newRec = {
				...trafficChallans, id: Math.floor(Math.random() * 1000),
				challanFile: fileContent ? fileContent : ""
			}
			const userRecord = [...challanRecords, newRec]
			setChallanRecords(userRecord);
		}
		else {
			const updateRecord = { ...trafficChallans }
			const updateData = challanRecords.map(item => (item.id === updateRecord.id ? updateRecord : item))
			setChallanRecords(updateData)
			setChallanId(-1)
		}
		setTrafficChallans({
			"delhiTrafficChallan": "",
			"vahanParivahanChallan": "",
			"challanHoldDeduction": "",
			"challanHoldReleaseDate": "",
			"hypothecation": "",
			"blackListedDetails": "",
			"nocHoldAmt": "",
			"nocHoldReleaseDate": ""
		})
		setEditChallan(false)
	}
	const handleChallanEdit = id => {
		const selectitem = challanRecords.find(item => item.id === id);
		setEditChallan(true)
		setTrafficChallans(selectitem)
		const index = challanRecords.indexOf(selectitem)
		setChallanId(index)
	}
	const handleRemoveChallan = id => {
		const removedRecords = challanRecords.filter(record => record.id !== id);
		setChallanRecords(removedRecords)
	}

	//-------------------------- Challan Section End --------------------------

	const handelClick = (brand) => {
		let models = brands[brand.toLowerCase()].cars;
		setSelectedBrandList(models)
		setSelectedYearList()
		setVariantList()

	}
	const handelyears = (models) => {
		const years = selectedBrandList && selectedBrandList[models.toLowerCase()] && selectedBrandList[models.toLowerCase()].years
		setSelectedYearList(years)
		setVariantList(selectedBrandList[models.toLowerCase()])
	}

	useEffect(() => {
		if (form["status"] === "success") {
			setStatus(true)
		} else {
			setStatus(false)
		}
	}, [form["status"]]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const purchaseDate = purchaseRecords && purchaseRecords[purchaseRecords.length - 1]?.purchaseDate
		const saleDate = form.saleDate && form["saleDate"];
		const diffTime = purchaseDate && saleDate && (new Date(saleDate) - new Date(purchaseDate));
		if (diffTime > 0) {
			const Days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			setDiffDays(Days)
		} else {
			setDiffDays("")
		}
	}, [purchaseRecords, form["saleDate"]]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const purchasePrice = getPurchasePrice();
		const soldPrice = form.soldPrice && form["soldPrice"];
		const price = Number(soldPrice?.replace(/,/g, ''));
		let diffPrice = Number(price) - Number(purchasePrice);
		if (diffPrice > 0) {

			setProfit(diffPrice)
		} else {
			setProfit()
		}
	}, [purchaseRecords, form["soldPrice"]]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getLeadId().then(leadIds => {
			if (leadIds) {
				setLeadid(leadIds)
			}
		})
	}, []);

	//----------------assignTo----------
	useEffect(() => {
		fetchUsers().then(assignTo => {
			if (assignTo) {
				setAssignTo(assignTo)
			}
		})
	}, []);

	const formError = (purchasePrice) => {
		if (Number(!form.offeredPrice?.replace(/,/g, '')) || Number(purchasePrice) > Number(form.offeredPrice?.replace(/,/g, ''))) {
			setPriceError('Purchase Price cannot be greater than Offered Price')
			return false;
		}
		setPriceError('');
		return true;
	}

	const statusCommentErrors = () => {
		if (form.status && !records.length) {
			setCommentError('Please enter comment')
			return false;
		}
		setCommentError('');
		return true;
	}

	const getEmailsAnsSms = async (data, form, whatsapp) => {
		if (data.email && data.status !== 'price_quoted' && data.status !== 'under_negotiation' && data.status !== 'lost' &&
			data.status !== 'inspected' && data.status !== 'document_collected' &&
			data.status !== 'car_picked_up'
		) {
			if (tempStatusValue !== form.status && tempStatus !== form.status && location.state.mode === "edit") {
				await appointmentStatusMail(data.email, data.status, data.lastName ? data.lastName : "", data.appointmentTime ? data.appointmentTime : "", data.appointmentDate ? data.appointmentDate : "", getPurchasePrice(), getHoldBackAmount("hold"),
					getHoldBackAmount("release")
				)
			}
			if (tempStatusValue !== form.status && location.state.mode !== "edit") {
				await appointmentStatusMail(data.email, data.status, data.lastName ? data.lastName : "", data.appointmentTime ? data.appointmentTime : "", data.appointmentDate ? data.appointmentDate : "", getPurchasePrice(), getHoldBackAmount("hold"),
					getHoldBackAmount("release")
				)
			}
		}

		//Whatsapp----
		if (data?.mobile && whatsChecked) {
			if (tempStatusValue !== form.status && tempStatus !== form.status && location.state.mode === "edit") {
				getWhatsappMessage(data, whatsapp);
			}
			if (tempStatusValue !== form.status && location.state.mode !== "edit") {
				getWhatsappMessage(data, whatsapp);
			}
		}

		if (data?.mobile && !whatsChecked) {
			if (tempStatusValue !== form.status && tempStatus !== form.status && location.state.mode === "edit") {
				await mobileSMS(data.mobile, data.status, data);
			}
			if (tempStatusValue !== form.status && location.state.mode !== "edit") {
				await mobileSMS(data.mobile, data.status, data);
			}
		}
	}

	const handleSave = async () => {
		if (!statusCommentErrors()) {
			return;
		}
		setLoading(true);
		let data = { ...form };
		let url = "";
		var whatsapp = {};
		if (data?.mobile) {
			await getWhatsappUsers(data?.mobile).then(data => {
				if (data) {
					whatsapp = data;
				}
			})
		}
		let appointmentId = "";
		let whatsappData = {
			mobile: data.mobile,
			isEnable: whatsChecked,
		};
		if (Object.entries(whatsapp).length === 0 && data?.mobile) {
			if (whatsChecked) {
				createUser({
					"userId": data.mobile,
					"phoneNumber": data.mobile,
					"countryCode": "+91",
					"traits": {
						"phoneNumber": data.mobile,
					},
				});
			}
			firebase
				.database()
				.ref("whatsAppUpdate")
				.push(whatsappData)
				.then((res) => {
					console.log("sucessfully");
				})
				.catch((err) => {
					console.log(err);
				});
		}
		let editDataUrl = "whatsAppUpdate/" + whatsapp.userId;
		if (whatsapp?.mobile === data?.mobile) {
			if (data?.mobile) {
				firebase
					.database()
					.ref(editDataUrl)
					.set(whatsappData)
					.then((res) => {
						console.log("sucessfully");
					})
					.catch((err) => {
						console.log(err);
					});

			}
		}

		if (data) {
			if (data.appointmentId) {
				appointmentId = data.appointmentId;
				data.challan = challanRecords
				data.comment = records
				data.userId = auth.uid;
				data.purchaseDetails = purchaseRecords;
				data.updatedAt = moment().format();
				url = "appointments/" + appointmentId;
			} else {
				data.userId = auth.uid;
				data.leadID = leadId;
				data.challan = challanRecords
				data.purchaseDetails = purchaseRecords;
				data.leadDate = moment().format("YYYY-MM-DD")
				data.comment = records
				data.createdAt = moment().format();
				data.updatedAt = moment().format();
				url = "appointments/";
			}
			if (isFormEdit && location.state.mode !== "edit") {
				url = "appointments/" + AppointmentId;
			}
			delete data.appointmentId;
			if (location.state.mode === "edit" || isFormEdit) {
				firebase
					.database()
					.ref(url)
					.set({
						...data,
					})
					.then((res) => {
						setLoading(false);
						getEmailsAnsSms(data, form, whatsapp);
						setSnackbarData(
							handleSnackBar(
								"fire",
								"Saved Successfully",
								"success"
							)
						);
					})
					.catch((err) => { });
			}
			else {
				firebase
					.database()
					.ref(url)
					.push({
						...data,
					})
					.then((res) => {
						if (res) {
							setIsFormEdit(true);
							setAppointmentId(res?.path?.pieces_[1]);
							setLoading(false)
							setSnackbarData(
								handleSnackBar(
									"fire",
									"Saved Successfully",
									"success"
								)
							);
						}
					})
					.catch((err) => { });

				if (data.email && data.status !== 'price_quoted' && data.status !== 'under_negotiation' && data.status !== 'lost' &&
					data.status !== 'inspected' && data.status !== 'document_collected' &&
					data.status !== 'car_picked_up'
				) {
					await appointmentStatusMail(data.email, data.status, data.lastName ? data.lastName : "", data.appointmentTime ? data.appointmentTime : "", data.appointmentDate ? data.appointmentDate : "", getPurchasePrice(),
						getHoldBackAmount("hold"),
						getHoldBackAmount("release")
					)
				}
				if (data.email && data.mobile && !whatsapp?.mobile) {
					await registrationMail(data.email, data.lastName ? data.lastName : "")
				}
				if (data?.mobile && whatsChecked) {
					getWhatsappMessage(data, whatsapp);
				}

				if (data.mobile && !whatsapp?.mobile && whatsChecked) {
					createEvent({
						"userId": data.mobile,
						"phoneNumber": data.mobile,
						"countryCode": "+91",
						"event": "User Registration",
						"traits": {
							"status": "user_reg"
						}
					});
				}
				if (data.mobile && !whatsapp?.mobile && !whatsChecked) {
					await mobileSMS(data.mobile, "newUserRegidtration");
				}
				if (!whatsChecked && data.mobile) {
					await mobileSMS(data.mobile, data.status, data);
				}
			}
		}
		if (isFormEdit) {
			if (data.email && data.mobile && !whatsapp?.mobile) {
				await registrationMail(data.email, data.lastName ? data.lastName : "")
			}
			if (data.mobile && !whatsapp?.mobile && whatsChecked) {
				createEvent({
					"userId": data.mobile,
					"phoneNumber": data.mobile,
					"countryCode": "+91",
					"event": "User Registration",
					"traits": {
						"status": "user_reg"
					}
				});
			}
		}
		setTempStatusValue(data.status);
		if (data.status !== tempStatus) {
			setTempStatus(data.status);
		}
	};

	const getPurchasePrice = () => {
		let price = '';
		purchaseRecords.forEach((record) => {
			price = Number(price) + Number(record?.purchasePrice?.replace(/,/g, ''))
		})
		return Number(price);
	}

	const getHoldBackAmount = (key) => {
		let holdprice = '';
		challanRecords.forEach((record) => {
			if (record?.challanHoldDeduction === key) {
				holdprice = Number(holdprice) + Number(record?.nocHoldAmt?.replace(/,/g, ''))
			}
		})
		return Number(holdprice);
	}

	const checkSinner = (e) => {
		if (e.keyCode === 38 || e.keyCode === 40) {
			e.preventDefault();
		}
	}

	return (
		<>
			{loading ? <div className={classes.paper} style={{ padding: '20% 50%' }}>
				<Loader />
			</div> :
				<>
					<Box className={classes.backToLeads}>
						<ChevronLeftIcon className={classes.button} onClick={history.goBack} />
						<Typography className={classes.headTitle} onClick={history.goBack} style={{ cursor: 'pointer', marginTop: 5 }}>Back</Typography>
					</Box>
					<div className={classes.root}>
						<Accordion defaultExpanded={true}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								style={{ padding: '5px 16px' }}
							>
								<Box display="flex">
									<Box>
										<Typography className={classes.headTitle}>Car Details</Typography>
									</Box>

								</Box>
							</AccordionSummary>
							<Grid container className={classes.CarDetails}>
								{fields?.map((e) => {
									if (e.fieldType === "details") {
										if (auth?.role === "lex") {
											if (e.role === "lex") {
												if (e.type === "text")
													return e.field === 'address' ? (
														<Grid item xs={12} sm={12} lg={4} className={classes.fields}>
															<Box mb={0} mt={0}>
																<LocationSelector
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	value={form[e.field]}
																	handleSelect={handleLocationchange}
																/>
															</Box>
														</Grid>
													) :
														e.field === 'customAddress' ? (
															<Grid item xs={12} sm={12} lg={4} className={classes.fields}>
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														)
															: e.field === 'email' ? (
																<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															) : e.field === 'lead' ? (
																<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																	<CustomLeadId
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled}
																		defaultValue={location.state.mode === "edit" ? form['leadID'] : leadId}
																		onChange={handleChange}
																	/>
																</Grid>
															) : e.field === 'carRegistrationNO' ? (
																<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																	<RegistrationNumber
																		key={e.field}
																		data={e}
																		onKeyDown={(e) => checkSinner(e)}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															) : (
																			<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																				<CustomInput
																					key={e.field}
																					data={e}
																					disabled={location.state.disabled}
																					defaultValue={form[e.field]}
																					onChange={handleChange}
																				/>
																			</Grid>
																		)
												else if (e.fieldValue === "phoneNumber") {
													if (e.type === "number") {
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<CustomPhoneNumberInput
																	key={e.field}
																	data={e}
																	onKeyDown={(e) => checkSinner(e)}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													}
												}
												else if (e.fieldValue === "sellerPriceExc") {
													if (e.type === "number") {
														return (
															<Grid item xs={12} sm={6} lg={4} className={classes.fields}>
																<CustomNumberInput
																	key={e.field}
																	data={e}
																	onKeyDown={(e) => checkSinner(e)}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													}
												}
												else if (e.type === "number") {
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<CustomNumberInput
																key={e.field}
																data={e}
																onKeyDown={(e) => checkSinner(e)}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												}
												else if (e.type === "dropDown" && e.makefield === "makefield")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															{
																form && form.isOther ?
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/> :
																	<CustomMakeSelect
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																		brandList={brands}
																		onClick={handelClick}
																	/>
															}
														</Grid>
													);
												else if (e.type === "dropDown" && e.modelfield === "modelfield")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															{
																form && form.isOther ?
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/> :
																	<CustomModelSelect
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled}
																		brandList={selectedBrandList}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																		onClick={handelyears}
																	/>
															}
														</Grid>
													);
												else if (e.type === "dropDown" && e.yearfield === "yearfield")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															{
																form && form.isOther ?
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/> :
																	<CustomYearSelect
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled}
																		brandList={selectedYearList}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
															}
														</Grid>
													);
												else if (e.type === "dropDown" && e.variantfield === "variantfield")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															{
																form && form.isOther ?
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/> :
																	<CustomVariantSelect
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled}
																		oilVariantList={variantList}
																		variant={variant}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
															}
														</Grid>
													);
												else if (e.type === "dropDown") {
													return e.field === "assignedTo" ? (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<AssignToSelect
																data={e}
																key={e.field}
																menu={assignTo}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													) :
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<CustomSelect
																data={e}
																key={e.field}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
												}
												else if (e.fieldValue === "appDate") {
													if (e.type === "date") {
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<AppointmentDate
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													}
												}
												else if (e.fieldValue === "leadDate") {
													if (e.type === "date") {
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<LeadDate
																	createdAt={form["createdAt"]}
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													}
												}
												else if (e.type === "date")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<CustomDatePicker
																data={e}
																key={e.field}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												else if (e.type === "time")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<CustomTimePickers
																data={e}
																appointmentDate={form.appointmentDate}
																key={e.field}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												else if (e.type === "boolean")
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<CustomSelect
																data={e}
																key={e.field}
																disabled={location.state.disabled}
																defaultValue={
																	form[e.field] === e.secondOption ? true : false
																}
																onChange={handleChange}
															/>
														</Grid>
													);
												else if (e.type === "comment") {
													return (
														<Grid container spacing={3} className={classes.commentBox}>
															<Grid item xs={8} sm={6} lg={6}>
																<TextField
																	flexdirection="row"
																	disabled={location.state.disabled}
																	id="standard-full-width"
																	name="comment"
																	value={comments.comment}
																	label="Comment"
																	helperText={commentErrorMsg}
																	onChange={handleCommentChange}
																	className={classes.commentField}
																	margin="normal"
																	inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
																	InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
																	endAdornment={<InputAdornment position="end"></InputAdornment>}
																/>
															</Grid>
															<Grid item xs={4} sm={6} lg={6} className={classes.CarDetails}>
																{!location.state.disabled && (
																	<Button className={classes.addMoreCommentButton}
																		style={{ textTransform: 'none' }}
																		onClick={handleAddComment}
																	// disabled={!(comments.comment.length > 0 && !(comments.comment == null || /^\s*$/.test(comments.comment)))}
																	>
																		{edit ? 'Update' : 'Add'}
																	</Button>
																)}
															</Grid>
														</Grid>

													);
												}
												else return <h1>{e.field}</h1>;
											}
										}
										else {
											if (e.type === "text") {
												return e.field === 'address' ? (
													<Grid item xs={12} sm={12} lg={4}
														className={classes.fields}>
														<Box mb={0} mt={0}>
															<LocationSelector
																key={e.field}
																data={e}
																disabled={location.state.disabled}
																value={form[e.field]}
																handleSelect={handleLocationchange}
															/>
														</Box>
													</Grid>
												) :
													e.field === 'customAddress' ? (
														<Grid item xs={12} sm={12} lg={4} className={classes.fields}>
															<CustomInput
																key={e.field}
																data={e}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													)
														: e.field === 'email' ? (
															<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														) : e.field === 'lead' ? (
															<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																<CustomLeadId
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={location.state.mode === "edit" ? form['leadID'] : leadId}
																	onChange={handleChange}
																/>
															</Grid>
														) : e.field === 'carRegistrationNO' ? (
															<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																<RegistrationNumber
																	key={e.field}
																	data={e}
																	onKeyDown={(e) => checkSinner(e)}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														) : (
																		<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																			<CustomInput
																				key={e.field}
																				data={e}
																				disabled={location.state.disabled}
																				defaultValue={form[e.field]}
																				onChange={handleChange}
																			/>
																		</Grid>
																	)
											}
											else if (e.fieldValue === "phoneNumber") {
												if (e.type === "number") {
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<CustomPhoneNumberInput
																key={e.field}
																onKeyDown={(e) => checkSinner(e)}
																data={e}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												}
											}
											else if (e.fieldValue === "sellerPriceExc") {
												if (e.type === "number") {
													return (
														<Grid item xs={12} sm={6} lg={4} className={classes.fields}>
															<CustomNumberInput
																key={e.field}
																data={e}
																onKeyDown={(e) => checkSinner(e)}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												}
											}
											else if (e.type === "number") {
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														<CustomNumberInput
															key={e.field}
															data={e}
															onKeyDown={(e) => checkSinner(e)}
															disabled={location.state.disabled}
															defaultValue={form[e.field]}
															onChange={handleChange}
														/>
													</Grid>
												);
											}
											else if (e.type === "dropDown" && e.makefield === "makefield")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>

														{
															form && form.isOther ?
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/> :
																<CustomMakeSelect
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																	brandList={brands}
																	onClick={handelClick}
																/>
														}
													</Grid>
												);
											else if (e.type === "dropDown" && e.modelfield === "modelfield")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														{
															form && form.isOther ?
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/> :
																<CustomModelSelect
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled}
																	brandList={selectedBrandList}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																	onClick={handelyears}
																/>
														}
													</Grid>
												);
											else if (e.type === "dropDown" && e.yearfield === "yearfield")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														{
															form && form.isOther ?
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/> :
																<CustomYearSelect
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled}
																	brandList={selectedYearList}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
														}
													</Grid>
												);
											else if (e.type === "dropDown" && e.variantfield === "variantfield")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														{
															form && form.isOther ?
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/> :
																<CustomVariantSelect
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled}
																	oilVariantList={variantList}
																	variant={variant}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
														}
													</Grid>
												);
											else if (e.type === "dropDown") {
												return e.field === "assignedTo" ? (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														<AssignToSelect
															data={e}
															key={e.field}
															menu={assignTo}
															disabled={location.state.disabled}
															defaultValue={form[e.field]}
															onChange={handleChange}
														/>
													</Grid>
												) :
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														<CustomSelect
															data={e}
															key={e.field}
															ref={ref}
															disabled={location.state.disabled}
															defaultValue={form[e.field]}
															onChange={handleChange}
														/>
													</Grid>
											}
											else if (e.fieldValue === "appDate") {
												if (e.type === "date") {
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<AppointmentDate
																key={e.field}
																data={e}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												}
											}
											else if (e.fieldValue === "leadDate") {
												if (e.type === "date") {
													return (
														<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
															<LeadDate
																createdAt={form["createdAt"]}
																key={e.field}
																data={e}
																disabled={location.state.disabled}
																defaultValue={form[e.field]}
																onChange={handleChange}
															/>
														</Grid>
													);
												}
											}
											else if (e.type === "date")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														<CustomDatePicker
															data={e}
															key={e.field}
															disabled={location.state.disabled}
															defaultValue={form[e.field]}
															onChange={handleChange}
														/>
													</Grid>
												);
											else if (e.type === "time")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														<CustomTimePickers
															data={e}
															key={e.field}
															appointmentDate={form.appointmentDate}
															disabled={location.state.disabled}
															defaultValue={form[e.field]}
															onChange={handleChange}
														/>
													</Grid>
												);
											else if (e.type === "boolean")
												return (
													<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
														<CustomSelect
															data={e}
															key={e.field}
															disabled={location.state.disabled}
															defaultValue={
																form[e.field] === e.secondOption ? true : false
															}
															onChange={handleChange}
														/>
													</Grid>
												);
											else if (e.type === "comment") {
												return (
													<Grid container spacing={3} className={classes.commentBox}>
														<Grid item xs={8} sm={6} lg={6}>
															<TextField
																flexdirection="row"
																disabled={location.state.disabled}
																id="standard-full-width"
																name="comment"
																value={comments.comment}
																label="Comment"
																onChange={handleCommentChange}
																className={classes.commentField}
																margin="normal"
																helperText={commentErrorMsg}
																inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
																InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
																endAdornment={<InputAdornment position="end"></InputAdornment>}
															// InputProps={{
															// 	endAdornment: (
															// 		<InputAdornment
															// 			className={classes.addIcon}
															// 		>
															// 			<AddIcon
															// 				onClick={handleAddComment}
															// 			/>
															// 		</InputAdornment>
															// 	)
															// }}
															/>
														</Grid>
														<Grid item xs={1} sm={1} lg={1}
															className={classes.addIcon}
														>
															<AddIcon
																onClick={handleAddComment}
															/>
														</Grid>
														<Grid item xs={3} sm={5} lg={5} className={classes.CarDetails}>
															{!location.state.disabled && (
																<Button className={classes.addMoreCommentButton} onClick={() => handleSave()}
																	style={{ textTransform: 'none' }}
																>
																	Save
																</Button>
															)}
														</Grid>
													</Grid>

												);
											}
											else return <h1>{e.field}</h1>;
										}
									}
									return null;
								})}
								{records.length > 0 ?
									<Box width="100%" p={2} mb={2} className={classes.commentTabel}
										disabled={location.state.disabled}
									>
										<CommentTable
											records={records}
											handleEdit={handleEdit}
											handleRemoveComment={handleRemoveComment}
											disable={!location.state.disabled}
										/>
									</Box>
									: null}
								<Box display="flex" mt={2} mb={2}>
									<Box className={classes.checkBox}>
										<Checkbox
											label=""
											onChange={(e) => {
												setWhatsChecked(e.target.checked);
											}}
											checked={whatsChecked}
										/>
									</Box>
									<Box>
										<Typography className={classes.bottomHeading}
											style={{ marginTop: 3 }}
										>
											Send updates on WhatsApp
                    						</Typography>
									</Box>
								</Box>
							</Grid>

						</Accordion>
						<>
							<Accordion defaultExpanded={location.state.mode === "edit" ? true : false}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
									style={{ padding: '5px 16px' }}
								>
									<Typography className={classes.headTitle}>Challans</Typography>
								</AccordionSummary>
								<Grid className={classes.CarDetails}
									style={{ marginBottom: 20 }}
								>
									{!location.state.disabled && (
										<Button className={classes.addMoreButton} onClick={() => handleSave()}
											disabled={!status}
											style={{ textTransform: 'none' }}
										>
											Save
										</Button>
									)}
								</Grid>
								<Grid className={classes.CarDetails}>
									{!location.state.disabled && (
										<Button className={classes.addMoreButton}
											disabled={!status}
											onClick={handleAddClick}
											style={{ textTransform: 'none' }}
										>
											{editChallan ? 'Update' : 'Add More'}
										</Button>
									)}
								</Grid>
								<Grid container spacing={3} className={classes.challanDetail}>
									{auth && auth.role === "lex" ?
										<>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													flexdirection="row"
													disabled={location.state.disabled || !status}
													id="standard-full-width"
													// helperText={challanErrorMsg}
													name="delhiTrafficChallan"
													value={trafficChallans.delhiTrafficChallan || ""}
													label="Delhi Traffic Challan"
													onChange={handleInputChange}
													className={classes.textField}
													margin="normal"
													inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
													InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
													endAdornment={<InputAdornment position="end"></InputAdornment>}
													type="number"
													onKeyDown={(e) => checkSinner(e)}
												/>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													name="vahanParivahanChallan"
													label="Vahan & Parivahan Challan"
													disabled={location.state.disabled || !status}
													value={trafficChallans.vahanParivahanChallan || ""}
													onChange={handleInputChange}
													inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
													InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
													endAdornment={<InputAdornment position="end"></InputAdornment>}
													display="flex"
													flexdirection="row"
													id="standard-full-width"
													className={classes.textField}
												/>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													select
													name="challanHoldDeduction"
													value={trafficChallans.challanHoldDeduction || ""}
													disabled={location.state.disabled || !status}
													label="Challan Hold/Deduction"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														deductions?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={2}>
												<TextField
													select
													name="hypothecation"
													value={trafficChallans.hypothecation || ""}
													disabled={location.state.disabled || !status}
													label="Hypothecation"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														deduction?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											{trafficChallans?.hypothecation === "yes" && (
												<>
													<Grid item xs={12} sm={4} lg={3}>
														<TextField
															name="bankName"
															label="Bank Name"
															disabled={location.state.disabled || !status}
															value={trafficChallans.bankName || ""}
															onChange={handleInputChange}
															inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
															InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
															endAdornment={<InputAdornment position="end"></InputAdornment>}
															display="flex"
															flexdirection="row"
															id="standard-full-width"
															className={classes.textField}
														/>
													</Grid>
													<Grid item xs={12} sm={4} lg={3}>
														<TextField
															name="loanAmount"
															label="Loan Amount"
															disabled={location.state.disabled || !status}
															value={trafficChallans.loanAmount || ""}
															onChange={handleInputChange}
															inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
															InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
															endAdornment={<InputAdornment position="end"></InputAdornment>}
															display="flex"
															flexdirection="row"
															id="standard-full-width"
															type="number"
															onKeyDown={(e) => checkSinner(e)}
															className={classes.textField}
														/>
													</Grid>
												</>

											)}
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													select
													name="blackListedDetails"
													value={trafficChallans.blackListedDetails || ""}
													disabled={location.state.disabled || !status}
													label="Black Listed Details"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														deduction?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													select
													name="nocHoldDeduction"
													value={trafficChallans.nocHoldDeduction || ""}
													disabled={location.state.disabled || !status}
													label="NOC"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														nocdeductions?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													flexdirection="row"
													disabled={location.state.disabled || !status}
													id="standard-full-width"
													name="nocHoldAmt"
													type="number"
													onKeyDown={(e) => checkSinner(e)}
													value={trafficChallans.nocHoldAmt || ""}
													label="NOC Hold Amt"
													onChange={e => e.target.value.length <= 8 && handleInputChange(e)}
													className={classes.textField}
													margin="normal"
													inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
													InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
													endAdornment={<InputAdornment position="end"></InputAdornment>}
												/>
											</Grid>
										</> : <>
											<Grid item xs={12} sm={4} lg={2}>
												<TextField
													flexdirection="row"
													disabled={location.state.disabled || !status}
													id="standard-full-width"
													name="delhiTrafficChallan"
													type="number"
													onKeyDown={(e) => checkSinner(e)}
													// helperText={challanErrorMsg}
													value={trafficChallans.delhiTrafficChallan || ""}
													label="Delhi Traffic Challan"
													onChange={handleInputChange}
													className={classes.textField}
													margin="normal"
													inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
													InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
													endAdornment={<InputAdornment position="end"></InputAdornment>}
												/>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													name="vahanParivahanChallan"
													label="Vahan & Parivahan Challan"
													disabled={location.state.disabled || !status}
													value={trafficChallans.vahanParivahanChallan || ""}
													onChange={handleInputChange}
													inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
													InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
													endAdornment={<InputAdornment position="end"></InputAdornment>}
													display="flex"
													flexdirection="row"
													id="standard-full-width"
													className={classes.textField}
												/>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													select
													name="challanHoldDeduction"
													value={trafficChallans.challanHoldDeduction || ""}
													disabled={location.state.disabled || !status}
													label="Challan"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														deductions?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<DatePicker
														autoOk
														label="Date"
														format="dd/MM/yyyy"
														disabled={location.state.disabled || !status}
														name="challanHoldReleaseDate"
														value={trafficChallans.challanHoldReleaseDate || null}
														onChange={date => handleHoldReleaseDate(date)}
														className={classes.textField}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment>
																	<Event />
																</InputAdornment>
															)
														}}
														inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
														InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
														endAdornment={<InputAdornment position="end"></InputAdornment>}
														keyboardIcon={<TodayIcon style={{ color: 'black', marginRight: -12 }} />}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid item xs={12} sm={4} lg={2}>
												<TextField
													select
													name="hypothecation"
													value={trafficChallans.hypothecation || ""}
													disabled={location.state.disabled || !status}
													label="Hypothecation"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														deduction?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											{trafficChallans?.hypothecation === "yes" && (
												<>
													<Grid item xs={12} sm={4} lg={3}>
														<TextField
															name="bankName"
															label="Bank Name"
															disabled={location.state.disabled || !status}
															value={trafficChallans.bankName || ""}
															onChange={handleInputChange}
															inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
															InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
															endAdornment={<InputAdornment position="end"></InputAdornment>}
															display="flex"
															flexdirection="row"
															id="standard-full-width"
															className={classes.textField}
														/>
													</Grid>
													<Grid item xs={12} sm={4} lg={3}>
														<TextField
															name="loanAmount"
															label="Loan Amount"
															disabled={location.state.disabled || !status}
															value={trafficChallans.loanAmount || ""}
															onChange={handleInputChange}
															inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
															InputLabelProps={{ style: { color: '#1B154E', fontFamily: 'Montserrat, SemiBold', fontSize: 14 } }} // font size of input label
															endAdornment={<InputAdornment position="end"></InputAdornment>}
															display="flex"
															flexdirection="row"
															id="standard-full-width"
															type="number"
															onKeyDown={(e) => checkSinner(e)}
															className={classes.textField}
														/>
													</Grid>
												</>
											)}

											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													select
													name="blackListedDetails"
													value={trafficChallans.blackListedDetails || ""}
													disabled={location.state.disabled || !status}
													label="Black Listed Details"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														deduction?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={trafficChallans.hypothecation === "yes" ? 2 : 3}>
												<NumberFormat
													customInput={TextField}
													name="nocHoldAmt"
													display="flex"
													flexdirection="row"
													id="standard-full-width"
													fullWidth
													value={trafficChallans.nocHoldAmt || ""}
													label="NOC Hold Amt"
													className={classes.textField}
													onKeyDown={(e) => checkSinner(e)}
													onWheel={() => document.activeElement.blur()}
													disabled={location.state.disabled || !status}
													onChange={handleInputChange}
													margin="normal"
													inputProps={{ style: { fontSize: 14, color: "#000000" } }} // font size of input text
													thousandSeparator={true}
													thousandsGroupStyle="lakh"
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
													endAdornment={<InputAdornment position="end"></InputAdornment>}
												/>

											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<TextField
													select
													name="nocHoldDeduction"
													value={trafficChallans.nocHoldDeduction || ""}
													disabled={location.state.disabled || !status}
													label="NOC"
													onChange={e => handleInputChange(e)}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													style={{ marginTop: '-2px' }}
													className={classes.textField}
													inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														nocdeductions?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={3}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<DatePicker
														autoOk
														label="NOC Hold / Release date"
														format="dd/MM/yyyy"
														disabled={location.state.disabled || !status}
														name="nocHoldReleaseDate"
														value={trafficChallans.nocHoldReleaseDate || null}
														onChange={date => nocHoldReleaseDate(date)}
														className={classes.textField}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment>
																	<Event />
																</InputAdornment>
															)
														}}
														inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
														InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
														endAdornment={<InputAdornment position="end"></InputAdornment>}
														keyboardIcon={<TodayIcon style={{ color: 'black', marginRight: -12 }} />}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid item xs={12} sm={6} lg={6}>
												<FormControl className={classes.formControl}>
													<div className={classes.container}>
														<TextField
															id="name"
															value={trafficChallans?.challanFileName || ''}
															className={classes.fileLabel}
															disabled
														/>
														<Button
															variant="contained"
															component="label"
															className={classes.uploadButton}
															disabled={location.state.disabled || !status}
														>
															Browse
          <input
																type="file"
																hidden
																onChange={(e) => {
																	onFileUpload(trafficChallans.challanFile, e.target.files[0]);
																}}
															/>
														</Button>
													</div>
												</FormControl>
											</Grid>
										</>
									}
									{challanRecords.length > 0 ?
										<Box width="100%" p={2} mb={2} className={classes.commentTabel}>
											<ChallanTabel
												challanRecords={challanRecords}
												handleChallanEdit={handleChallanEdit}
												handleRemoveChallan={handleRemoveChallan}
												disable={!location.state.disabled}
											/>
										</Box>
										: null}

								</Grid>



							</Accordion>
							<Accordion defaultExpanded={location.state.mode === "edit" ? true : false}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
									style={{ padding: '5px 16px' }}
								>
									<Typography className={classes.headTitle}>Car Purchase Details</Typography>
								</AccordionSummary>
								<Grid className={classes.CarDetails}
									style={{ marginBottom: 20 }}
								>
									{!location.state.disabled && (
										<Button className={classes.addMoreButton} onClick={() => handleSave()}
											disabled={!status}
											style={{ textTransform: 'none' }}
										>
											Save
										</Button>
									)}
								</Grid>
								<Grid className={classes.CarDetails}>
									{!location.state.disabled && (
										<Button className={classes.addMoreButton}
											disabled={!status}
											onClick={handelAddPurchaseDetails}
											style={{ textTransform: 'none' }}
										>
											{editPurchasePrice ? 'Update' : 'Add More'}
										</Button>
									)}
								</Grid>
								<Grid container spacing={3} className={classes.challanDetail}>
									{auth && auth.role === "lex" ?
										<>
											<Grid item xs={12} sm={4} lg={3}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<DatePicker
														autoOk
														label="Payment Date"
														format="dd/MM/yyyy"
														disabled={location.state.disabled || !status}
														name="purchaseDate"
														value={purchaseDetail.purchaseDate || null}
														onChange={date => handlePuechasePriceDate(date)}
														className={classes.textField}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment>
																	<Event />
																</InputAdornment>
															)
														}}
														inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
														InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
														endAdornment={<InputAdornment position="end"></InputAdornment>}
														keyboardIcon={<TodayIcon style={{ color: 'black', marginRight: -12 }} />}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</> : <>
											<Grid item xs={12} sm={4} lg={2}>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<DatePicker
														autoOk
														label="Payment Date"
														format="dd/MM/yyyy"
														disabled={location.state.disabled || !status}
														name="purchaseDate"
														value={purchaseDetail.purchaseDate || null}
														onChange={date => handlePuechasePriceDate(date)}
														className={classes.textField}
														KeyboardButtonProps={{
															'aria-label': 'change date',
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment>
																	<Event />
																</InputAdornment>
															)
														}}
														inputProps={{ style: { fontSize: 14, color: '#000000', marginTop: 2 } }} // font size of input text
														InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
														endAdornment={<InputAdornment position="end"></InputAdornment>}
														keyboardIcon={<TodayIcon style={{ color: 'black', marginRight: -12 }} />}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
											<Grid item xs={12} sm={4} lg={2}>
												<TextField
													select
													name="modeOfPayment"
													value={purchaseDetail.modeOfPayment || ""}
													disabled={location.state.disabled || !status}
													label="Mode Of Payment"
													onChange={handlePurchasePriceChange}
													labelid="demo-simple-select-label"
													id="demo-simple-select"
													displayEmpty
													className={classes.textField}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
												>
													{
														MODE_OF_PAYMENT?.map((e) => {
															return <MenuItem value={e.value}>{e.label}</MenuItem>;
														})
													}
												</TextField>
											</Grid>
											<Grid item xs={12} sm={4} lg={2}>
												<NumberFormat
													customInput={TextField}
													display="flex"
													name="purchasePrice"
													flexdirection="row"
													onKeyDown={(e) => checkSinner(e)}
													id="standard-full-width"
													fullWidth
													label="Amount"
													className={classes.textField}
													onWheel={() => document.activeElement.blur()}
													disabled={location.state.disabled || !status}
													onChange={handlePurchasePriceChange}
													margin="normal"
													inputProps={{ style: { fontSize: 14, color: "#000000" } }} // font size of input text
													thousandSeparator={true}
													thousandsGroupStyle="lakh"
													value={purchaseDetail.purchasePrice || ""}
													helperText={priceError}
													InputLabelProps={{
														style: {
															fontSize: 14,
															color: "#1B154E",
															fontFamily: "Montserrat, SemiBold",
														},
													}}
													endAdornment={<InputAdornment position="end"></InputAdornment>}
												/>

											</Grid>
										</>
									}
									{purchaseRecords.length > 0 ?
										<Box width="100%" p={2} mb={2} className={classes.commentTabel}>
											<PurchaseTabel
												purchaseRecords={purchaseRecords}
												handlePurchaseEdit={handlePurchaseDetailEdit}
												handleRemovePurchase={handleRemoveDetail}
												disable={!location.state.disabled}
												totlePrice={getPurchasePrice()}
											/>
										</Box>
										: null}

								</Grid>

							</Accordion>
							{auth?.role === "lex" ? null :
								<Accordion defaultExpanded={location.state.mode === "edit" ? true : false} >
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										style={{ padding: '5px 16px' }}
									>
										<Typography className={classes.headTitle}>Car Sell Details</Typography>
									</AccordionSummary>
									<Grid className={classes.CarDetails}
									>
										{!location.state.disabled && (
											<Button className={classes.addMoreButton} onClick={() => handleSave()}
												disabled={!status}
												style={{ textTransform: 'none' }}
											>
												Save
											</Button>
										)}
									</Grid>
									<Grid container className={classes.CarDetails}>
										{fields?.map((e) => {
											if (e.fieldType === "carSellDetails") {
												if (auth?.role === "lex") {
													if (e.role === "lex") {
														if (e.type === "text") {
															return e.field === 'buyerAddress' ? (
																<Grid item xs={12} sm={12} lg={4} className={classes.fields}>
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															) : e.field === 'buyerBusinessName' ? (
																<Grid item xs={12} sm={6} lg={4} className={classes.fields}>
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															) : e.field === 'buyerBusinessAddress' ? (
																<Grid item xs={12} sm={6} lg={4} className={classes.fields}>
																	<CustomInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>) : (
																			<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																				<CustomInput
																					key={e.field}
																					data={e}
																					disabled={location.state.disabled || !status}
																					defaultValue={form[e.field]}
																					onChange={handleChange}
																				/>
																			</Grid>
																		)
														}
														else if (e.holding === "holdingPeriod" && e.type === "text") {
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>

																	<HoldingPeriod
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled || !status}
																		defaultValue={diffDays}
																		onChange={handleChange}
																	/>
																</Grid>
															);
														}

														else if (e.fieldValue === "phoneNumber") {
															if (e.type === "number") {
																return (
																	<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																		<CustomPhoneNumberInput
																			key={e.field}
																			data={e}
																			disabled={location.state.disabled || !status}
																			defaultValue={form[e.field]}
																			onChange={handleChange}
																			onKeyDown={(e) => checkSinner(e)}
																		/>
																	</Grid>
																);
															}
														}
														else if (e.type === "number") {
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomNumberInput
																		key={e.field}
																		data={e}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																		onKeyDown={(e) => checkSinner(e)}
																	/>
																</Grid>
															);
														} else if (e.type === "dropDown")
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomSelect
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															);
														else if (e.type === "date")
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomDatePicker
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															);
														else if (e.type === "time")
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomTimePickers
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															);
														else if (e.type === "boolean")
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomSelectSwitch
																		data={e}
																		key={e.field}
																		disabled={location.state.disabled || !status}
																		defaultValue={
																			form[e.field] === e.secondOption ? true : false
																		}
																		onChange={handleChange}
																	/>
																</Grid>
															);
														else return <h1>{e.field}</h1>;
													}
												} else {
													if (e.type === "text") {
														return e.field === 'buyerAddress' ? (
															<Grid item xs={12} sm={12} lg={4} className={classes.fields}>
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														) : e.field === 'buyerBusinessName' ? (
															<Grid item xs={12} sm={6} lg={4} className={classes.fields}>
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														) : e.field === 'buyerBusinessAddress' ? (
															<Grid item xs={12} sm={6} lg={4} className={classes.fields}>
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>) : (
																		<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																			<CustomInput
																				key={e.field}
																				data={e}
																				disabled={location.state.disabled || !status}
																				defaultValue={form[e.field]}
																				onChange={handleChange}
																			/>
																		</Grid>
																	)
													}
													if (e.type === "text") {
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<CustomInput
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													}
													else if (e.fieldValue === "phoneNumber") {
														if (e.type === "number") {
															return (
																<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomPhoneNumberInput
																		key={e.field}
																		data={e}
																		onKeyDown={(e) => checkSinner(e)}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
															);
														}
													}
													else if (e.type === "number") {
														return e.field === 'soldPrice' ? (
															<Grid item xs={12} sm={12} lg={4} className={classes.fields}>
																<CustomNumberInput
																	key={e.field}
																	data={e}
																	onKeyDown={(e) => checkSinner(e)}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														) : e.field === 'profit' ? (
															<Grid item xs={12} sm={6} lg={2} className={classes.fields}>
																<NumberFormat
																	customInput={TextField}
																	display="flex"
																	label="Profit"
																	name={e.field}
																	onKeyDown={(e) => checkSinner(e)}
																	value={profit || ""}
																	flexdirection="row"
																	id="standard-full-width"
																	fullWidth
																	disabled
																	className={classes.textField}
																	onWheel={() => document.activeElement.blur()}
																	margin="normal"
																	inputProps={{ style: { fontSize: 14, color: "#000000" } }} // font size of input text
																	thousandSeparator={true}
																	thousandsGroupStyle="lakh"
																	InputLabelProps={{
																		style: {
																			fontSize: 14,
																			color: "#1B154E",
																			fontFamily: "Montserrat, SemiBold",
																		},
																	}}
																	endAdornment={<InputAdornment position="end"></InputAdornment>}
																/>

																{/* <TextField
															display="flex"
															flexdirection="row"
															id="standard-full-width"
															label="Profit"
															fullWidth
															className={classes.textField}
															type="number"
															name={e.field}
															disabled
															value={profit || ""}
															margin="normal"
															inputProps={{ style: { fontSize: 14, color: '#000000' } }} // font size of input text
															InputLabelProps={{ style: { fontSize: 14, color: '#1B154E', fontFamily: 'Montserrat, SemiBold' } }} // font size of input label
															endAdornment={<InputAdornment position="end"></InputAdornment>}
														/> */}

															</Grid>
														) :
																(<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																	<CustomNumberInput
																		key={e.field}
																		data={e}
																		onKeyDown={(e) => checkSinner(e)}
																		disabled={location.state.disabled || !status}
																		defaultValue={form[e.field]}
																		onChange={handleChange}
																	/>
																</Grid>
																)
													} else if (e.type === "dropDown")
														return (
															<Grid item xs={12} sm={4} lg={4} className={classes.fields}>
																<CustomSelect
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													else if (e.holding === "holdingPeriod" && e.type === "hold") {
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<HoldingPeriod
																	key={e.field}
																	data={e}
																	disabled={location.state.disabled || !status}
																	defaultValue={diffDays}
																	onChange={handleChange}
																/>
															</Grid>
														);
													}
													else if (e.type === "date")
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<CustomDatePicker
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													else if (e.type === "time")
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<CustomTimePickers
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled || !status}
																	defaultValue={form[e.field]}
																	onChange={handleChange}
																/>
															</Grid>
														);
													else if (e.type === "boolean")
														return (
															<Grid item xs={12} sm={4} lg={2} className={classes.fields}>
																<CustomSelectSwitch
																	data={e}
																	key={e.field}
																	disabled={location.state.disabled || !status}
																	defaultValue={
																		form[e.field] === e.secondOption ? true : false
																	}
																	onChange={handleChange}
																/>
															</Grid>
														);
													else return <h1>{e.field}</h1>;
												}
											}
											return null;
										})}
									</Grid>
								</Accordion>
							}
						</>
					</div>
					<div>
						<Toaster
							snackbarData={snackbarData}
							revertSnackBarData={() => {
								setSnackbarData(handleSnackBar("hide"));
							}}
						/>
					</div>

					<Grid container justify="center" alignItems="center" className={classes.saveButton}>

					</Grid>
				</>}
		</>
	);
};

export default ManageAppointment;

