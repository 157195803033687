import React from 'react'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { colors } from "../../theme";

const StyledTableCell = withStyles((theme) => ({
	head: {
		background: "#1B154E 0% 0% no-repeat padding-box",
		color: colors.white,
		fontSize: 12,
		whiteSpace: "normal",
		wordWrap: "break-word",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		padding: 7,
		fontFamily: "Montserrat, SemiBold",
		textAlign: "left",
		font: "normal normal 600 13px/17px Montserrat",
		letterSpacing: 0.26,
		opacity: 1,
	},
	body: {
		padding: "5px 7px",
		fontFamily: "Montserrat, Medium",
		textAlign: "left",
		font: "normal normal medium 12px/16px Montserrat",
		//minWidth: (props) => props.minWidth ? 'auto' : 103,
		letterSpacing: "0.12px",
		color: "#000000",
		opacity: 1,
	},
}))(TableCell);

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		overflowX: "auto",
		background: "#FFFFFF 0% 0% no-repeat padding-box",
		boxShadow: "0px 25px 30px #0000000A",
		opacity: 1,
		padding: 10,
		boxSizing: ' border-box',
	},
	table: {
		minWidth: 700,
	},
	row: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.background.default,
		},
	},
	tableCell: {
		width: 130,
		height: 40,
	},
	caption: {
		textAlign: "left !important",
		captionSide: "top !important",
		fontSize: "18px !important",
		fontFamily: "Montserrat, Semibold !important",
		color: "#1B154E !important",
	},
	expand: {
		background: "#FFD13B",
		padding: '6px 24px 6px 9px',
		float: ' right',
		borderRadius: 2,
		cursor: 'pointer',
	},
	input: {
		width: 130,
		height: 40,
	},
	searchIcon: {
		bottom: 1,
		color: "#1B154E",
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		cursor: 'pointer',
		marginLeft: 5,
	},
	editIcon: {
		bottom: 1,
		color: "#fff",
		background: '#1B154E',
		width: 14,
		height: 14,
		borderRadius: 'inherit',
		marginRight: 5,
		padding: 3,
		cursor: 'pointer',
	},
	noResult: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: 25,
		marginBottom: 25,
		fontSize: 16,
		//color: colors.primary,
		fontFamily: "Montserrat, Regular",
	},
	expandList: {
		fontSize: 12,
		color: "#1B154E",
		fontFamily: "Montserrat, Regular",
	},
	tableTitle: {
		display: 'flex',
		alignItems: ' center',
		justifyContent: ' space-between',
		marginBottom: 10,
		marginTop: 10,

	},
	tableContainer: {
	}
}));


const CommentTabel = ({ records, handleEdit, handleRemoveComment, disable }) => {
	const classes = useStyles();
	return (
		<Table className={classes.table} aria-label="caption table">
			<TableHead>
				<TableRow>
					<StyledTableCell><strong>S.No</strong></StyledTableCell>
					<StyledTableCell><strong>Comment</strong></StyledTableCell>
					<StyledTableCell><strong>Name</strong></StyledTableCell>
					<StyledTableCell><strong>Role</strong></StyledTableCell>

					<StyledTableCell><strong>Time</strong></StyledTableCell>
					<StyledTableCell><strong>Status</strong></StyledTableCell>

					{disable ? <StyledTableCell><strong>Action</strong></StyledTableCell> : null}
				</TableRow>
			</TableHead>
			<TableBody>
				{records.map((record, index) => {
					return (
						<TableRow key={record.id}>
							<StyledTableCell>{index + 1}</StyledTableCell>
							<StyledTableCell>{record.comment}</StyledTableCell>
							<StyledTableCell>{record?.name?.charAt(0).toUpperCase() +
								record?.name?.slice(1)
							}</StyledTableCell>
							<StyledTableCell>{record?.role?.charAt(0).toUpperCase() +
								record?.role?.slice(1)}</StyledTableCell>
							<StyledTableCell>{record.time}</StyledTableCell>
							<StyledTableCell>
								{record.status==="new_lead" && "New Lead"}
								{record.status==="inspection_scheduled" && "Inspection Scheduled"}
								{record.status==="cancel" && "Cancel"}
								{record.status==="inspection_rescheduled" && "Inspection Rescheduled"}
								{record.status==="inspected" && "Inspected"}
								{record.status==="price_quoted" && "Price Quoted"}
								{record.status==="under_negotiation" && "Under Negotiation"}
								{record.status==="success" && "Success"}
								{record.status==="document_collected" && "Document Collected"}
								{record.status==="token_done" && "Token Done"}
								{record.status==="full_payment_done" && "Full Payment Done"}
								{record.status==="holdback_confirmation" && "Holdback Confirmation"}
								{record.status==="car_picked_up" && "Car Picked Up"}
								{record.status==="transaction_complete" && "Transaction Complete"}
								{record.status==="holdback_release" && "Holdback Release"}
								{record.status==="lost" && "Lost"}
							
							
							</StyledTableCell>
							{disable ? <StyledTableCell>
								<EditIcon
									onClick={() => handleEdit(record.id)}
									className={classes.editIcon}
								/>
								<DeleteOutlineIcon
									disabled={disable}
									onClick={() => handleRemoveComment(record.id)}
									className={classes.searchIcon}
								/>
							</StyledTableCell> : null}
						</TableRow>
					)
				})}
			</TableBody>
		</Table>
	)
}

export default CommentTabel
